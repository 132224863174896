import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { Link } from 'react-router-dom'

export default class BuyerTile extends Component {
    constructor(props) {
        super(props)

        this.state = {image: '/images/face-placeholder.gif'}
    }


    renderExactLocationMatchIcon = () => {

        const {doc,filters} = this.props
        const buyer = doc.data();
        let exactLocationMatch = false;
        const {location} = buyer;
        if(location && filters){
            const {location:filteredLocation} = filters;
            const {lat,lng} = location;
            if(filteredLocation){
                const {lat:fLat,lng:fLng} = filteredLocation;
                exactLocationMatch = (fLat === lat && fLng === lng);
            }
        }

        if(exactLocationMatch){
            return <p><span style={{color:'#ff214f',fontSize:25,padding:'7px'}} className="fa fa-heart"/></p>
        }
    }

    componentWillMount() {
        const {doc} = this.props
        const storage = firebase.storage()
        const storageRef = storage.ref()
        const buyerImageRef = storageRef.child(`images/users/${doc.id}.jpg`)

        this.mounted = true

        buyerImageRef.getDownloadURL()
            .then(url => {
                if (this.mounted) {
                    this.setState({image: url})
                }
            }).catch(error => {})
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
        const {doc} = this.props
        const buyer = doc.data()
        if (!buyer.cities) {
            buyer.cities = []
        }
        return <div className="col-lg-3" key={doc.id}>
                <Link to={`/buyers/${doc.id}`}>
                <div className="property-box-2">
                <div className="property-thumbnail" style={{height: 160, overflow: 'hidden'}}>
                    {this.renderExactLocationMatchIcon()}
                    <div style={{backgroundImage: `url('${this.state.image}')`, height: 200, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                </div>
                <div className="detail">
                    <div className="hdg">
                            <p style={{marginBottom: 0, lineHeight: '20px', fontSize: 13}}>
                                <i className="flaticon-pin"></i>&nbsp;{buyer.cities.join(', ') || '-'}
                            </p>
                            <p style={{marginBottom: 0, lineHeight: '20px', fontSize: 13}}><i className="flaticon-financial" />&nbsp;{currencyFormatter.format(buyer.min_price)}-{currencyFormatter.format(buyer.max_price)}</p>
                            <p style={{marginBottom: 0, fontSize: 13}}>
                                <i className="flaticon-calendar"></i>&nbsp;{buyer.purchase_month}, {buyer.purchase_year}
                            </p>
                            <p style={{fontSize: 12, marginBottom: 0, lineHeight: '20px'}}>
                                 <i className="fa fa-bed"></i>&nbsp;{buyer.bedrooms || '-'}  &nbsp;
                                 <i className="fa fa-bath"></i>&nbsp;{buyer.bathrooms || '-'}
                                 &nbsp;•&nbsp;{buyer.sqft_min}-{buyer.sqft_max} sqft 
                                
                                </p>
                    </div>
                    </div>
                </div>
                </Link>
            </div>
    }
}