import * as actions from "../Actions/type";

const initSate = {
  selectedConverstation: null,
  unreadMessages:0,
  conversations: [],
  contactsViewVisibility:false
};

const MessengerReducer = (state = initSate, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: [...payload]
      };
    }

    case actions.TOGGLE_CONTACTS_VIEW_VISIBILITY: {
      const {contactsViewVisibility} = state;
      return {
        ...state,
        contactsViewVisibility: !contactsViewVisibility
      };
    }

    case actions.SELECT_CONVERSATION: {
      return {
        ...state,
        selectedConverstation: payload
      };
    }

    case actions.UPDATE_USER_PROFILE: {
      const { user_id, profile } = payload;
      const { conversations } = state;
      const newCon = conversations.map(c => {
        if (c.id === user_id) {
          return { ...c, profile };
        }
        return { ...c };
      });
      return {
        ...state,
        conversations: newCon
      };
    }

    case actions.SET_CONVERSATION_MESSAGES: {
      let {
        senderID,
        receiverID,
        messages,
        senderLastSeen,
        receiverLastSeen,
        user
      } = payload;
      const lastSeen = {senderLastSeen,receiverLastSeen};
      
      if(!senderLastSeen) delete lastSeen.senderLastSeen;
      if(!receiverLastSeen) delete lastSeen.receiverLastSeen;

      const { conversations } = state;
      const newCon = conversations.map(c => {
        if (
          (c.senderID === senderID && c.receiverID === receiverID) ||
          (c.senderID === receiverID && c.receiverID === senderID)
        ) {
          const filteredMessages = c.messages.filter(
            m => m.receiverID !== receiverID
          );

          messages = [...filteredMessages, ...messages];

          messages = messages.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });

          return { ...c, messages, ...lastSeen };
        }
        return { ...c };
      });

      // lets calculate total unread messages
      let count = 0;

      newCon.forEach( c => {
        c.messages.forEach(m => {
          const ls = c.receiverLastSeen || 0;
          if(m.receiverID === user.uid && m.timestamp > ls){
            count++;
          }
        });
      });

      return {
        ...state,
        unreadMessages:count,
        conversations: newCon
      };
    }

    case actions.UPDATE_USER_STATUS: {
      const { user_id, snapshot } = payload;
      const { conversations } = state;
      const newCon = conversations.map(c => {
        if (c.id === user_id) {
          return { ...c, ...snapshot };
        }
        return { ...c };
      });
      return {
        ...state,
        conversations: newCon
      };
    }

    default: {
      return state;
    }
  }
};

export default MessengerReducer;
