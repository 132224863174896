import React, { useState } from "react";
import { InlineShareButtons } from 'sharethis-reactjs';
import { Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';

const DashboardAllOffers = ({ hideModal, showModel, sellerId }) => {

  return <Modal isOpen={showModel} size="md" fade={false} toggle={hideModal}>
    <ModalHeader className="modal-header" toggle={hideModal}></ModalHeader>
    <ModalBody className="modal-body">
      <Container>
        <Row>
          <Col>
            <div className="row-space-between">
              <h5 className="modal-title">Share Buttons</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-30">
          <Col className="share-modal">
            <InlineShareButtons
              config={{
                  alignment: 'center',  // alignment of buttons (left, center, right)
                  color: 'social',      // set the color of buttons (social, white)
                  enabled: true,        // show/hide buttons (true, false)
                  font_size: 16,        // font size for the buttons
                  labels: 'cta',        // button labels (cta, counts, null)
                  language: 'en',       // which language to use (see LANGUAGES)
                  networks: [           // which networks to include (see SHARING NETWORKS)
                  'whatsapp',
                  'facebook',
                  'twitter',
                  'sms',
                  'email',
                  ],
                  padding: 5,          // padding within buttons (INTEGER)
                  radius: 8,           // the corner radius on each button (INTEGER)
                  show_total: true,
                  size: 30,            // the size of each button (INTEGER)
      
                  // OPTIONAL PARAMETERS
                  url: `https://homematchx.com/sellers/${sellerId}?trends=1`, // (defaults to current url)
                  image: 'https://homematchx.com/img/logos/black-logo.png',  // (defaults to og:image or twitter:image)
                  description: `To check offer Trends for the property please visit https://homematchx.com/sellers/${sellerId}?trends=1`,       // (defaults to og:description or twitter:description)
                  title: `To check offer Trends for the property please visit https://homematchx.com/sellers/${sellerId}?trends=1`,            // (defaults to og:title or twitter:title)
                  message: `To check offer Trends for the property please visit https://homematchx.com/sellers/${sellerId}?trends=1`,     // (only for email sharing)
                  subject: `OfferTrends - Homematchx`,  // (only for email sharing)
                  username: `To check offer Trends for the property please visit https://homematchx.com/sellers/${sellerId}?trends=1` // (only for twitter sharing)
              }}
              />
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>

}

export default DashboardAllOffers;
