import React from 'react'
import 'firebase/auth'
import AuthenticatedRoute from './AuthenticatedRoute'
import Account from '../templates/Account'

export default function AccountRoute({ component: Component, ...rest }) {
  return (
    <AuthenticatedRoute
      {...rest}
      component={props => {
        return <Account {...props}>
            <Component {...props} />
        </Account>
        }
      }
    />
  );
}
