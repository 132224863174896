import React, { Component } from "react";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";


let isWelcomeModalDisplayed = localStorage.getItem('isWelcomeModalDisplayed');

class WelcomeModal extends Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state
    });
  };


  componentDidMount() {
    const { email } = this.props;
    if(isWelcomeModalDisplayed !== '' &&  typeof isWelcomeModalDisplayed === 'string') {
      isWelcomeModalDisplayed = JSON.parse(isWelcomeModalDisplayed);
      if(!isWelcomeModalDisplayed || isWelcomeModalDisplayed.email !== email)
        this.setState({
          isOpen: true
        });
    }

    localStorage.setItem('isWelcomeModalDisplayed', JSON.stringify({
      isOpen: true,
      email
    }));
  }

  render() {
    const { isOpen, } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}>Get Started</ModalHeader>
          <ModalBody>
            <h5 style={{textAlign:'center'}}>Learn how to create your buyer and/or sellers profile.</h5>
            <hr/>
            <div className="embed-container">
                <iframe title="LearnHow" src={`https://youtube.com/embed/ZnBpsJimJIY?autoplay=1&color=white&egm=1&iv_load_policy=3&rel=0&showinfo=0&showsearch=0`} frameBorder="0" allowFullScreen="1"></iframe>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default WelcomeModal;
