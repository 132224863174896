import firebase from "firebase/app";
import "firebase/firestore";

// return all associated buyers to user passed in param.
export const getUsersBuyers = (user,search_id) => {
  return new Promise((resolve, reject) => {
    const db = firebase.firestore();
    const { uid, email, show_on_share } = user;
    let query = db.collection("buyers").where("removed", "==", false);
    if(show_on_share) query = query.where("show_on_share", "==", true);

    let queries = [
      query.where("buyer_email", "==", email),
      query.where("lender_email", "==", email),
      query.where("represendtedBy", "==", email),
      query.where("owner", "==", uid),
      query.where("seller_id", "==", uid)
    ];

    if(search_id){
      queries = [
        query.where("owner", "==", search_id),
        query.where("seller_id", "==", search_id)
      ];
    }

    const promises = queries.map(q => q.get());

    Promise.all(promises)
      .then(snapshots => {
        const docSets = snapshots.map(s => s.docs);
        let docs = [];
        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some(d => d.id === doc.id)) {
              docs.push(doc);
            }
          }
        }

        resolve(docs);
      })
      .catch(error => {
        reject(error);
      });
  });
};
