import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

class BookingSubmitComment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            dateTime: '',
            commentText: '',
            savingComment: false
        }
    }

    id = () => {
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        return id;
    }

    saveComment = async () => {
        if (!this.state.commentText || this.state.savingComment) return;
        let bookingId = this.props.bookingId || this.id();
        let name = this.props.name;
        let comment = {
            text: this.state.commentText,
            name,
            commentedAt: new Date().getTime()
        }

        this.setState({
            savingComment: true
        });

        firebase.database().ref(`/BookingComments/${bookingId}`)
            .push(comment, err => {
                if (!err) this.setState({ commentText: '', savingComment: false });
                else alert('Unexpected error occurred while saving comment. Please try again later.');
            })
    }

    render() {
        const { savingComment } = this.state;

        return (
            <>
                <input className="form-control flex-fill" type="text" name="" placeholder="Comment" onChange={e => {
                    const { target } = e;
                    this.setState({
                        commentText: target.value
                    })
                }} onKeyUp={e => {
                    if (e.key === 'Enter') this.saveComment()
                }} value={this.state.commentText} />
                <ul>
                    <li>
                        <btn onClick={this.saveComment} disabled={savingComment ? true : false}>
                            <i className="fa fa-paper-plane"></i>
                        </btn>
                    </li>
                </ul>
            </>
        )
    }
}

export default BookingSubmitComment;