import React, { Component } from "react";
import SubBanner from "components/SubBanner";

import "firebase/firestore";
import { Row, Col } from "reactstrap";
import RealEstateGetStartedModal from "components/RealEstate/RealEstateGetStartedModal";
import BePartnerModal from "components/RealEstate/BePartnerModal";
import ReactPlayer from "react-player";

class RealEstate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  componentDidMount() {}

  componentWillUnmount() {}

  
  render() {
    return (
      <React.Fragment>
        <SubBanner
          heading="Real Estate Your Way"
          description="You bring the buyer or property. We'll make closing easy and affordable."
          image="/img/realestate-banner.png"
          hideNav
          {...this.props}
        />
        <div className="container"></div>
        <div className="">
          <div className="container">
            <div className="agent-comunity-section">
              <h2>Let Our Agent Community Protect Your Investment</h2>
              <Row>
                <Col md="3">
                  <div className="agent-card">
                    <div className="agent-card-img">
                      <img src="/img/card-img.png" alt="" />
                    </div>
                    <div className="agent-card-text">
                      <p>A Realtor with experience</p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="agent-card">
                    <div className="agent-card-img">
                      <img src="/img/card-img-2.png" alt="" />
                    </div>
                    <div className="agent-card-text">
                      <p>A professional you can trust</p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="agent-card">
                    <div className="agent-card-img">
                      <img src="/img/card-img-3.png" alt="" />
                    </div>
                    <div className="agent-card-text">
                      <p>Your asset my real estate knowledge</p>
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="agent-card agent-card-gray">
                    <img src="/img/share-icon.png" alt="" />
                    <p>Share Your Service</p>
                    <BePartnerModal user={this.props.user} />
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="work-section">
            <div className="container">
              <div className="work-inner">
                <h2>How it Works</h2>
                <Row>
                  <Col md="4">
                    <div className="work-card">
                      <div className="work-card-icon">
                        <img src="/img/documnet-icon.png" alt="" />
                      </div>
                      <h3>1. Request a Purchase Agreement</h3>
                      <p>
                        Sign up for free, set up your profile, request the real
                        estate service you need.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="work-card">
                      <div className="work-card-icon">
                        <img src="/img/plane-icon.png" alt="" />
                      </div>
                      <h3>2. Receive Contract Package</h3>
                      <p>
                        Completed offer with agreed terms, include required
                        addendum(s), and prepare signatures.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="work-card">
                      <div className="work-card-icon">
                        <img src="/img/checklist-icon.png" alt="" />
                      </div>
                      <h3>3. Transaction Management</h3>
                      <p>
                        Manage process from contract to close. Work with all
                        parties to protect critical dates and deadlines.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="video-section">
            <div className="container">
              <div className="video-div-main">
                <Row>
                  <Col md="6">
                    <div className="video-text" style={{ paddingTop: "0px" }}>
                      <img
                        style={{ maxWidth: "70%", marginBottom: "30px" }}
                        src="/img/realtyCarteLogo.png"
                        alt=""
                      />
                      <h3>On-demand real estate agents when you need it.</h3>
                      <p>
                        Discover what it means to hire a real estate agent for a
                        specific service or task. Get more for   less.
                      </p>
                    </div>
                  </Col>
                  <Col md="6">    
                    <div className="player-wrapper">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        playing={true}
                        className="react-player"
                        url="https://firebasestorage.googleapis.com/v0/b/homematch-231bb.appspot.com/o/cdn%2Fvideos%2FHomematchX%20is%20a%20real%20estate%20connection.mp4?alt=media&token=442ab5b6-aab4-48b4-91d1-b6db4ea87f00"
                        light="/img/pyare-larki.png"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className="question-section">
            <div className="container">
              <h2>Q & A</h2>
              <Row>
                <Col md="6">
                  <div className="question-card">
                    <h3>Can I request other services?</h3>
                    <p>
                      When it comes to real estate there is so much to offer.
                      Purchase Agreement, Market Analysis, and Property
                      Evaluation to name a few. You can request any service you
                      need from a real estate agent.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="question-card">
                    <h3>What is the turnaround time?</h3>
                    <p>
                      Real estate agents are flexible with their schedule. Upon
                      receiving the request, the response time is within 1-hour.
                      Your agent is ready to go!
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="question-card">
                    <h3>How much does it cost and when do I pay ?</h3>
                    <p>
                      It's free to join Homematchx. When you submit a request
                      for a real estate agent service they will provide the
                      negotied fee for the request. You will pay after the price
                      has been agreed up. The funds are held in an escrow
                      account until the request is completed.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="question-card">
                    <h3>How long does the service last?</h3>
                    <p>
                      The service last when the request has been fully
                      completed.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="question-card">
                    <h3>Do I have to sign an agreement?</h3>
                    <p>
                      Seller may be required to sign a limited representation
                      agreement needed to fulfill a specific request. The
                      agreement is offered on a short-term representation
                      period.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="question-card">
                    <h3>What can I expect?</h3>
                    <p>
                      A real estate agent will guide you through the entire
                      process of your request. Timely updates, follow ups, and
                      great customer service. We have experienced professionals
                      for limited service needs.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="signup-request-section">
            <div className="container">
              <span className="heart-icon">
                <i className="fa fa-heart" aria-hidden="true"></i>
              </span>
              <p>Sign Up and make your first Request today</p>
              <RealEstateGetStartedModal />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RealEstate;
