import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { toast } from "react-toastify";
import ReactDOMServer from "react-dom/server";

import { ModalBody, ModalHeader, Modal, Row, Col } from "reactstrap";

import FormField from "components/Common/FormField";
import PartnerAndSellerRequestEmail from "components/emailTemplates/PartnerAndSellerRequestEmail";
import Select from 'react-select';

let { logEvent } = firebase.analytics();

class RealEstateGetStartedModal extends Component {
  constructor() {
    super();

    this.initialValues = {
      services: "",
      deliveryTime: "",
      deliveryDays: "",
      description: "",
      name: "",
      userType: "",
      phoneNumber: "",
      email: "",
      preferedContactMethod: "",
      propertyAddress: "",
      budget: "",
    };

    this.validation = Yup.object().shape({
      services: Yup.string().required("Services are required"),
      deliveryTime: Yup.string().required("Delivery Time is required"),
      deliveryDays: Yup.string().optional(),
      description: Yup.string().required("Description is required"),
      name: Yup.string().required("Name is required"),
      userType: Yup.string().required("User is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      preferedContactMethod: Yup.string().required(
        "Preferred Method is required"
      ),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      propertyAddress: Yup.string().required("Property Address is required"),
      budget: Yup.string().required("Budget is required"),
    });

    this.state = {
      isOpen: false,
      processing: false,
      successMessage: "",
      allServices: [
        {
          label: "Home Marketing",
          options: [
            { label: "Multiple Listing Service (MLS) access and Monitor Showing Requests", value: "Multiple Listing Service (MLS) access and Monitor Showing Requests" },
            { label: "Real Estate Marketing Flyer", value: "Real Estate Marketing Flyer" },
            { label: "Real Estate Flyer E-Blast 10K+ Agents", value: "Real Estate Flyer E-Blast 10K+ Agents" },
            { label: "Photographers/Videographer", value: "Photographers/Videographer" },
            { label: "Realtor Hosted Open House Day/Weekend", value: "Realtor Hosted Open House Day/Weekend" },
            { label: "Home Staging Service", value: "Home Staging Service" },
            { label: "", value: "" },
          ]
        },
        {
          label: "Comparative Market Analysis",
          options: [
            { label: "CMA Full Comprehensive Report", value: "CMA Full Comprehensive Report" },
            { label: "Seller Net Sheet Before Home Listing", value: "Seller Net Sheet Before Home Listing" },
            { label: "Seller Net Sheet After Contract", value: "Seller Net Sheet After Contract" },
            { label: "Real Estate Market Research Report", value: "Real Estate Market Research Report" },
          ]
        },
        {
          label: "Property Evaluation",
          options: [
            { label: "Virtual Home Evaluation Report", value: "Virtual Home Evaluation Report" },
            { label: "Virtual Home Pricing Report", value: "Virtual Home Pricing Report" },
            { label: "In-Person Evaluation Report", value: "In-Person Evaluation Report" },
            { label: "In-Person Home Pricing Report", value: "In-Person Home Pricing Report" }
          ]
        },
        {
          label: "Purchase Agreement and Transaction Management ",
          options: [
            { label: "Written Purchase Agreement and Addendum between parties", value: "Written Purchase Agreement and Addendum between parties" },
            { label: "Realtor Contract-to-Close management process", value: "Realtor Contract-to-Close management process" },
            { label: "Full Service Residential Listing (includes all Home Marketing, CMA, and Property Evaluation)", value: "Full Service Residential Listing (includes all Home Marketing, CMA, and Property Evaluation)" }
          ]
        },
      ],
    };
  }

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state,
    });
  };

  sendEmailToAdmin = (data) => {
    logEvent("send_admin_buyer_request_email");

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    if(data.deliveryTime === 'others'){
      data.deliveryTime = `${data.deliveryDays} days`;
    }

    delete data.deliveryDays;

    const body = ReactDOMServer.renderToStaticMarkup(
      PartnerAndSellerRequestEmail({
        heading: `You got new Service request.`,
        request: data,
      })
    );

    sendEmail({
      subject: "Got Service Request",
      email: "info@homematchx.com",
      body,
    });
  };

  render() {
    const { isOpen, processing } = this.state;
    return (
      <React.Fragment>
        <button onClick={this.toggle} className="search-button" type="button">
          Get Started
        </button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h2>Limited Real Estate Services</h2>
          </ModalHeader>
          <ModalBody>
            <div className="realtor-partner-heading">
              <h2>Real Estate your Way</h2>
              <p style={{ marginTop: "10px" }}>
                Select your need and get started
              </p>
            </div>
            <div className="realtor-partner-form">
              <Formik
                initialValues={this.initialValues}
                validationSchema={this.validation}
                onSubmit={async (data) => {
                  this.setState({ processing: true });
                  try {
                    // return console.log('data', data.services);
                    const db = firebase.firestore();
                    await db.collection("buyerRequests").add(data);
                    this.setState({ processing: false });
                    toast.success(
                      "Your Request has heen received. An agent will contact you shortly."
                    );
                    this.toggle();
                    this.sendEmailToAdmin(data);
                  } catch (error) {
                    toast.error(error.message);
                    this.setState({ processing: false });
                  }
                }}
                render={({ errors, touched, values, setFieldTouched, setFieldValue }) => {
                  return (
                    <Form className="w-100 mt-3">
                      <Row>
                        <Col md="6">
                          <label
                            // style={{ marginTop: "20px" }}
                            htmlFor="preferedContactMethod"
                          >
                            Select Service
                          </label>
                          <Select
                            className={`${
                              errors.services && touched.services && "is-invalid"
                            }`}
                            isMulti={true}
                            name="services"
                            id="services"
                            onBlur={() => setFieldTouched("services", true)}
                            value={values.services}
                            onChange={(opt, e) => {
                              // this.handleMyCity(opt);
                              setFieldValue("services", opt);
                            }}
                            options={this.state.allServices}
                          />
                          {errors.services && touched.services && (
                            <FormFeedback>{errors.services}</FormFeedback>
                          )}
                        </Col>

                        <Col md="6">
                          <label htmlFor="deliveryTime">
                            Select delivery time
                          </label>

                          <Field
                            className={`form-control ${
                              errors.deliveryTime &&
                              touched.deliveryTime &&
                              "is-invalid"
                            }`}
                            as="select"
                            name="deliveryTime"
                          >
                            <option value="">Select delivery time</option>
                            <option value="1 - 10 hours">1 - 10 hours</option>
                            <option value="Less than 24 hours">
                              Less than 24 hours
                            </option>
                            <option value="1 Day">1 Day</option>
                            <option value="3 Day">3 Day</option>
                            <option value="7 Day">7 Day</option>
                            <option value="others">Other (# of days)</option>
                          </Field>

                          {errors.deliveryTime && touched.deliveryTime && (
                            <FormFeedback>{errors.deliveryTime}</FormFeedback>
                          )}
                        </Col>

                        {values.deliveryTime === "others" && (
                          <Col md="12">
                            <FormField
                              style={{ marginTop: "20px" }}
                              showLabel
                              type="text"
                              name="deliveryDays"
                              label="Delivery days"
                              errors={errors}
                              touched={touched}
                            />
                          </Col>
                        )}

                        <Col md="12">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            component="textarea"
                            name="description"
                            label="Describe the service you're looking for - please be as detailed as possbile"
                            placeholder="I'm looking for..."
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="12">
                          <label
                            style={{ marginTop: "20px" }}
                            htmlFor="userType"
                          >
                            Select User
                          </label>

                          <Field
                            className={`form-control ${
                              errors.userType &&
                              touched.userType &&
                              "is-invalid"
                            }`}
                            as="select"
                            name="userType"
                          >
                            <option value="">Select user</option>
                            <option value="Seller">Seller</option>
                            <option value="Buyer">Buyer</option>
                          </Field>

                          {errors.userType && touched.userType && (
                            <FormFeedback>{errors.userType}</FormFeedback>
                          )}
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="name"
                            label="Name (First and Last)"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="phoneNumber"
                            label="Phone Number"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="email"
                            label="Email Address"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <label
                            style={{ marginTop: "20px" }}
                            htmlFor="preferedContactMethod"
                          >
                            Prefered Method of Contact
                          </label>

                          <Field
                            className={`form-control ${
                              errors.preferedContactMethod &&
                              touched.preferedContactMethod &&
                              "is-invalid"
                            }`}
                            as="select"
                            name="preferedContactMethod"
                          >
                            <option value="">Select</option>
                            <option value="Email">Email</option>
                            <option value="Phone">Phone</option>
                          </Field>

                          {errors.preferedContactMethod &&
                            touched.preferedContactMethod && (
                              <FormFeedback>
                                {errors.preferedContactMethod}
                              </FormFeedback>
                            )}
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            component="textarea"
                            name="propertyAddress"
                            label="Property Address"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="budget"
                            label="what is your budget?"
                            placeholder="$"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md={{ size: 4, offset: 3 }} className="text-right">
                          <input
                            style={{ marginTop: "20px" }}
                            className="search-button"
                            type="submit"
                            disabled={processing}
                          />
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RealEstateGetStartedModal;
