import React, { Component } from 'react'
import SubBanner from './../SubBanner'

class PageNotFound extends Component {

    render() {
        
        return (
            <React.Fragment>
            <SubBanner
               title="404 - Page Not Found"
               image="/images/mx4mSkK9zeo.jpg"
               {...this.props}
               />
            <div className="container">
               <div className="row">
                   {/* TODO */}
               </div>
            </div>
         </React.Fragment>
        )
    }
}

export default PageNotFound
