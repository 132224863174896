import React, { Component } from "react";
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import firebase from "firebase/app";
import "firebase/firestore";

class TagSelect extends Component {
  state = {
    value: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue } = nextProps;
    const { value } = prevState;
    if (defaultValue && defaultValue.length > 0) {
      if (value.length !== defaultValue.length) {
        return {
          value: defaultValue
        }
      }
    }
    return null;
  }

  onChange = (value) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  }

  onCreateOption = async (text) => {
    const { value } = this.state;
    const values = [...value, { label: text, value: text }];
    this.onChange(values);
    const chars = text.toLowerCase().replace(' ', '').split('');
    const search = [];
    let string = '';
    chars.forEach(c => {
      string += c;
      search.push(string);
    });
    const db = firebase.firestore();
    await db.collection("tags").add({
      search, text
    });
  }

  get = async (value, callback) => {
    try {
      const db = firebase.firestore();
      const snap = await db.collection("tags").where("search", "array-contains", value.toLowerCase().replace(' ', '')).get();
      const results = snap.docs.map(d => {
        const data = d.data();
        return {
          label: data.text,
          value: data.text,
        };
      });
      callback(results);
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    const { value } = this.state;
    return (
      <AsyncCreatableSelect
        value={value}
        placeholder="Select Tag"
        cacheOptions
        loadOptions={this.get}
        defaultOptions
        onChange={this.onChange}
        onCreateOption={this.onCreateOption}
        isMulti
      />
    );
  }
}

export default TagSelect;
