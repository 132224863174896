import React, { Component } from 'react'
import SoldHomesList from 'components/SoldHomesList'
import firebase from 'firebase/app'
import 'firebase/firestore'

class SoldHomes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            sellers: [],
            loading: true
        }
    }

    componentWillMount() {
        const db = firebase.firestore();
        
        const query = db.collection('sellers').where('removed','==',true);

        const promises = [query].map(q => q.get());
        Promise.all(promises).then(snapshots => {

            if(this.mounted){
                const docSets = snapshots.map(s => s.docs);
                let docs = [];
                for (const docSet of docSets) {
                    for (const doc of docSet) {
                    if (!docs.some(d => d.id === doc.id)) {
                        docs.push(doc);
                    }
                    }
                }

                const storage = firebase.storage()
                const storageRef = storage.ref()

                const sellers = docs;
        
                for (const seller of sellers) {
                    const sellerImageRef = storageRef.child(`images/properties/${seller.id}.jpg`)
                    sellerImageRef.getDownloadURL()
                        .then(url => {
                            seller.photo = url

                            if (this.mounted) {
                                this.setState({sellers})
                            }
                        }).catch(error => {})
                }
                
                if (this.mounted) {
                    this.setState({sellers, loading: false});
                }
            }
            


        }).catch(error => {
            if (this.mounted) {
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        const {loading, error} = this.state
        let sellerList
        
        if (loading) {
            sellerList = 
                <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>Loading</p></td></tr>
                    </tbody>
                </table>
        }
        else {
            if (error) {
                sellerList = <table className="manage-table">
                        <tbody>
                        <tr className="responsive-table"><td><p>{error}</p></td></tr>
                        </tbody>
                    </table>
            }
            else {
                sellerList = <SoldHomesList
                    remove={this.removeSeller}
                    sellers={this.state.sellers}
                    user={this.props.user}
                />
            }
        }
        
        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-9">
                                <h4>Sold Homes</h4>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list">
                        <h3>Homes</h3>
                        {sellerList}
                    </div>
                </div>
            </div>
        )
    }
}

export default SoldHomes
