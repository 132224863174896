import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import * as actions from "../../Store/Actions/type";
import { markConversationAsRead } from "./../../utils/Functions";

class UserContact extends Component {
  selectConversation = () => {
    const { conversation, dispatch, onConversationSelect } = this.props;
    const { senderID, receiverID } = conversation;
    dispatch({
      type: actions.SELECT_CONVERSATION,
      payload: conversation.id
    });
    markConversationAsRead(receiverID, senderID);
    onConversationSelect();
  };

  getunreadMessagesCount = () => {
    const { selectedConverstation, conversation, user } = this.props;
    const { messages, receiverLastSeen, id } = conversation;

    const isSelected = id === selectedConverstation;

    return (messages || []).filter(m => {
      const forReceiver = user.uid === m.receiverID;
      return (
        (forReceiver && m.timestamp > receiverLastSeen && !isSelected) ||
        (forReceiver && !receiverLastSeen && !isSelected)
      );
    }).length;
  };

  renderUnreadMessagesCount = () => {
    const unreadMessages = this.getunreadMessagesCount();
    if (unreadMessages > 0) {
      return (
        <Badge
          color="danger"
          style={{
            position: "inherit",
            backgroundColor: "#dc3545",
            height: "auto",
            width: "auto",
            minWidth:'20px',
            minHeight:'20px'
          }}
        >
          {unreadMessages}
        </Badge>
      );
    }
  };

  render() {
    const { conversation, selectedConverstation } = this.props;
    const { name, profile, status, id, role } = conversation;

    const activeClass = selectedConverstation
      ? id === selectedConverstation
        ? "active"
        : ""
      : "";
    return (
      <li
        className={`contact ${activeClass}`}
        onClick={this.selectConversation}
      >
        <div className="wrap">
          <span className={`contact-status ${status}`}></span>
          <img src={profile} alt="" />
          <div className="meta">
            <p className="name">
              {name} - {role} {this.renderUnreadMessagesCount()}
            </p>
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {
    ...MessengerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContact);
