import React, { Component } from "react";
import AsyncSelect from 'react-select/lib/Async';
import firebase from "firebase/app";
import "firebase/firestore";

class SelectFollowers extends Component {
  state = {
    value: [],
  };

  onChange = (value) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  }

  get = async (value, callback) => {
    try {
      const db = firebase.firestore();

      const {user} = this.props;
      
      db.collection("network").doc(user.uid).get().then(doc => {
          const data = doc.data();
          if(data){
              const {followers, followings} = data;
              const dataList = [
                ...(followers || []),
                ...(followings || []),
              ];
              const results = dataList.filter(f => {
                  if(!value){
                      return true;
                  }
                  let flag = false;
                  (f.search || []).forEach(v => {
                      if(v.includes(value.toLowerCase())){
                          flag = true;
                      }
                  })
                  return flag;
              }).map( f => {
                  return {
                    label: f.name,
                    value: f,
                  }
              });
              callback(results);
          }
      });

      
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    const { value } = this.state;
    return (
      <AsyncSelect
		    isMulti
        value={value}
        placeholder="Name, Email, Brokerage, Company"
        cacheOptions
        loadOptions={this.get}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}

export default SelectFollowers;
