import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import firebase from "firebase/app";
import "firebase/database";
import ReactDOMServer from 'react-dom/server';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import buyerInviteEmail from 'components/emailTemplates/BuyerInviteEmailFromLender';
import {firebaseConfig} from 'components/Firebase/firebase';

class RequestReview extends Component {
    state = {
      modal: false,
      email: "",
      isLoading: false,
    }

    toggle = () => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        });
    }

    onChange = async ({ target }) => {
        const { name: key, value } = target;
        this.setState({
          [key]: value
        });
    };

    sendReviewRequest = () => {
        const {email} = this.state;
        const {resourceId, type, name} = this.props;

        const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
        const body = ReactDOMServer.renderToStaticMarkup(buyerInviteEmail({
            heading:'Review Request',
            profileDescriptionTwo: `${type} ${name} request you to write a review on his profile.`,
            button: {
            text: 'Write a review',
            link: `${firebaseConfig.host}/write-review?resourceId=${resourceId}&type=${type}&name=${name}`,
            },  
        }));
        
        sendEmail({subject :`${type} ${name} requested a review.`, email ,body});

        toast.success('Email was send successfully')
    }

  
    render () {
        const { modal, isLoading, email } = this.state;
        const {children, style, color, className} = this.props;
        return (
            <Fragment>
                
                <Button
                    className={className}
                    color={color}
                    style={style}
                    onClick={this.toggle}
                    >
                      {children}
                 </Button>
                
                <Modal isOpen={modal} toggle={ this.toggle} className="modal-danger modal-lg">
                        

                    <ModalHeader toggle={ this.toggle }>Request Review</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        onChange={this.onChange}
                                        value={email}
                                    />
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={ this.sendReviewRequest } disabled = { isLoading }> { isLoading ? 'Sending...' : 'Send Email' } </Button>{' '}
                    <Button color="secondary" onClick={ this.toggle }>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
            
        )
    }
}


export default RequestReview;