import React, {Component} from "react";
import firebase from "components/Firebase/firebase";
import "firebase/firestore";

import {
  Col,Row,
  ListGroup, ListGroupItem, Button,
  FormGroup
} from 'reactstrap';

import ClipboardJS from "clipboard";
import copy from 'copy-to-clipboard';

const clip = new ClipboardJS('.list-group-item');
    clip.on('success', function(e) {
        console.info('Text:', e.text);
        copy(e.text);
    });
    
    clip.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });


class MediaList extends Component {

  state = {
    activeTab: this.props.media_id,
    actionText: '',
    medias: [],
  };

  // componentWillReceiveProps(nextProps) {
  //   const {nook_id} = nextProps;
  //   if(this.props.nook_id !== nextProps.nook_id){
  //     this.get(nook_id);
  //   }
  // }

  componentDidMount(){
    
  }

  toggle(media) {
    const {onChange} = this.props;
    if (this.state.activeTab !== media.id) {
      this.setState({
        activeTab: media.id,
        actionText: 'Copied !'
      });
      onChange(media);
    }
  }

  onInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount(){
    this.get();
  }

  // search = () => {
  //   const {search} = this.state;
  //   this.get(nook_id,`?name=${search}&`);
  // };

  get = async () => {

    this.setState({
      medias:[],
      actionText:'',
    });

    const {uid} = this.props.user;

    const storageRef = firebase.storage().ref();
      const res = await storageRef.child(`postsMedia/${uid}`).listAll();
      const promises = res.items.map((itemRef, index) => {
          return new Promise(async (resolve, reject) => {
              try {
                  const url = await itemRef.getDownloadURL();
                  resolve({
                      id: index + 1,
                      path: url,
                  });
              } catch (error) {
                  reject(error);
              }
          })
      });

      let medias = await Promise.all(promises);
      medias = medias.filter(m => !m.path.includes('thumb%40'));

      this.setState({ medias: medias.reverse() });
  };

  render(){

    const {activeTab, medias, actionText } = this.state;
    const {imgColSize} = this.props;

    const style = {
      margin:'2px',padding:'5px'
    };

    return (

      <React.Fragment>

        <FormGroup style={{display:'flex'}}>
          <Col xs="6" sm="6">{actionText}</Col>
          <Col xs="6" sm="6">
            <Button style={{float: 'right'}} color="success" onClick={this.get}><i className="fa fa-search"/> Refresh</Button>
          </Col>
        </FormGroup>

        <ListGroup >
          <Row style={{height: '350px',overflowY:'scroll',overflowX:'hidden',display:'flex',...this.props.style}} >
            {medias.map((v)=>(
              <Col xs={imgColSize} key={v.id}>
                <ListGroupItem className="copy" style={style} onClick={() => this.toggle(v)} action active={activeTab === v.id} data-clipboard-text={v.path}>
                  <img className="img img-responsive" style={{width:'100%'}} src={v.path} alt="Item" />
                </ListGroupItem>
              </Col>
            ))}
          </Row>
        </ListGroup>
      </React.Fragment>
    );
  }
}

export default MediaList;

