import React, { Component } from 'react'
import moment from 'moment'

const visibleItemCount = 5

class TableDef extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            expanded: false
        }
    }

    render() {
        return (
            <React.Fragment>
                <style>{`.def-table { border-spacing: 0.2em; border-collapse: separate; }
            td.def { font-weight: 600; padding-right: 10px; }`}</style>
                <table style={{ fontSize: ' 13px' }} className="def-table">
                    <tbody>
                        {this.rows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    needsExpansion() {
        const {expanded} = this.state
        const {items} = this.props
        return !expanded && items.length > visibleItemCount
    }

    rows() {
        const {items} = this.props;
        const {expanded} = this.state

        let visibleItems
        if (this.needsExpansion()) {
            visibleItems = items.slice(0, visibleItemCount)
        }
        else {
            visibleItems = items
        }

        const rows = visibleItems.map(item => <tr key={item.key}>
            <td className="def capitalize">{item.key}</td>
            <td className="details-col">{item.key === 'Seller Email' ? <a href={`mailto:${item.value}`}>{item.value}</a> : item.key === 'Seller Phone' ? <a href={`tel:${item.value}`}>{item.value}</a> : this.value(item.value)}</td>
        </tr>)

        rows.push(<tr className="centerly" key="_expand_"><td colSpan="2">
        <button className="see-more" onClick={this.expand}>
            {(expanded) ? <i className="fa fa-arrow-up custom-arrow"/> : <i className="fa fa-arrow-down custom-arrow"/>}
            {expanded ? 'See less ': 'See more '}
        </button>
        </td></tr>)

        return rows
    }

    expand = () =>  {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    }

    value(value) {
        if (value && value.seconds) {
            return moment(value.toDate()).format('MMMM Do YYYY')
        }
        else {
            return value
        }
    }
}

export default TableDef
