import React, { Component } from "react";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

import parse from 'html-react-parser';

class PostDetailModal extends Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state
    });
  };

  render() {
    const { isOpen} = this.state;
    const {post} = this.props;
    var div = window.document.createElement("div");
    div.innerHTML = post.body;
    var plainBody = div.textContent || div.innerText || "";



    return (
      <React.Fragment>

        <div className="d-flex border">
            <div className="d-flex single-post-wrapper">
                <div className="p-5 single-post-container">
                    <div className="d-flex flex-column">
                        <btn color="link" onClick={this.toggle}><h2 className="mb-4">{post.title}</h2></btn>
                        <p className="mb-4">{plainBody.substr(0,200)}...</p>
                    </div>
                </div>
            </div>
        </div>

        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
                    
          <ModalHeader toggle={this.toggle}>{post.title}</ModalHeader>
          <ModalBody>
            {/* <div className="d-flex border">
                      <div className="d-flex single-post-wrapper">
                          <div className="p-5 single-post-container">
                              
                          </div>
                      </div>
            </div> */}

            <div className="d-flex flex-column">
                <p className="mb-4 post-content">{parse(post.body)}</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PostDetailModal;
