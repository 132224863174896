import React, { Component } from "react";
import AsyncSelect from 'react-select/lib/Async';
import data from './states.json';

class StateSelect extends Component {

    onChange = (m) => {
        let models = m;
        if (!Array.isArray(m)) {
            models = [m];
        }
        let { onChange } = this.props;
        onChange(models);
    };

    get = (value, callback) => {
        let states = data.body.states;
        if (value) {
            states = states.filter(s => s.name.toLowerCase().includes(value.toLowerCase()) || s.code.toLowerCase().includes(value.toLowerCase()))
        }
        states = states.map(s => ({ value: s, label: `${s.name} - ${s.code}` }));
        callback(states);
    };

    render() {
        const { states } = this.props;
        let values = data.body.states.filter(s => (states || []).indexOf(s.code) !== -1);
        values = values.map(s => ({ value: s, label: `${s.name} - ${s.code}` }));
        return (
            <AsyncSelect
                cacheOptions
                defaultOptions
                defaultValue={values}
                loadOptions={this.get}
                {...this.props}
                onChange={this.onChange}
            />
        );
    }
}

export default StateSelect;
