import React from "react";
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/firestore";
import moment from 'moment';
import AddLiveStreamAddon from './../components/LiveStream/AddLiveStreamAddon';


export default class Streamings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readyToLoad: false,
      user: this.props.user,
      bookings: []
    };
  }

  getSecondsFromSessions(sessions) {
    let netDuration = 0;
    (sessions || []).forEach(st => {
      netDuration += (st.endedAt - st.createdAt);
    });
    return netDuration;
  }

  getMinutesfromSessions = (sessions) => {
    const seconds = this.getSecondsFromSessions(sessions);
    return this.getMinutesfromSeocnds(seconds);
  }

  getMinutesfromSeocnds = (seconds) => {
    if (seconds === 0) {
      return 0;
    }
    return Math.round(seconds / 60);
  }

  calculateTime = (sessions) => {
    if (!sessions || sessions.length === 0) return (<div>No Time Logged Yet</div>);
    const netDuration = this.getSecondsFromSessions(sessions);
    let retTime = moment('00:00:00', 'HH:mm:ss').add(netDuration, 'seconds').format('HH:mm:ss')
    return (<div>{retTime}</div>);
  }

  componentDidMount = async () => {
    const { user } = this.state;
    let bookings = [];
    const db = firebase.firestore();
    let docs = await db.collection('liveVisitBookings').where('ownerId', '==', user.uid).get();
    docs.forEach(doc => {
      bookings.push({
        ...doc.data()
      });
    });

    this.setState({
      bookings
    });
  }

  render() {
    const { bookings } = this.state;
    const { streamingCredit, streamingTimeUsed } = this.props.user;
    const totalMinutes = this.getMinutesfromSeocnds(streamingCredit || 0);
    const usedMinutes = this.getMinutesfromSeocnds(streamingTimeUsed || 0);
    let bookingsTable = <table style={{ fontSize: ' 13px', width: '100%' }} className="match-details live-bookings">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone No</th>
          <th>Status</th>
          <th>Stream Started At - Ended At</th>
          <th>Minutes</th>
          <th>Total Duration</th>
        </tr>
      </thead>
      <tbody>
        {
          bookings.map((b, i) => (
            <tr key={i}>
              <td className="ucWords">{b.name}</td>
              <td>{b.email}</td>
              <td>{b.phoneNo}</td>
              <td className="ucWords">{b.status}</td>
              <td>
                {
                  b.sessionTimings ? b.sessionTimings.map((st, i) => (
                    <span key={i}>{moment.unix(st.createdAt).format('MM/DD/Y HH:mm:ss')} - {moment.unix(st.endedAt).format('MM/DD/Y HH:mm:ss')}<br /></span>
                  )) : 'No Time Logged Yet'
                }
              </td>
              <td>{this.getMinutesfromSessions(b.sessionTimings)} minutes</td>
              <td>{this.calculateTime(b.sessionTimings)}</td>
            </tr>
          ))
        }
      </tbody>
    </table>

    return (
      <>
        <div className="content-area5">
          <div className="dashboard-content">
            <div className="dashboard-header clearfix">
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <h4>Live Stream Analytics</h4>
                </div>

                <div style={{ textAlign: 'right', marginTop: "13px" }} className="col-sm-12 col-md-4">
                  <p>Used {usedMinutes} minutes out of {totalMinutes} minutes</p>
                </div>

                <div style={{ textAlign: 'right' }} className="col-sm-12 col-md-2">
                  <Link className="btn btn-theme btn-md" to="/liveStreamPackages">
                    Renew
                  </Link>
                </div>

                <div style={{ textAlign: 'left' }} className="col-sm-12 col-md-2">
                  <AddLiveStreamAddon {...this.props} />
                </div>

              </div>
            </div>

            <div className="dashboard-list">
              {bookingsTable}
            </div>
          </div>
        </div>
      </>
    )
  }
}