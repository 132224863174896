import React, { Component } from "react";

import {
  Table,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";
import moment from "moment";

class EditHistoryModal extends Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    const state = !this.state.isOpen;

    this.setState({
      isOpen: state
    });

    this.props.onModalStateChange(state);
  };

  render() {
    const { history } = this.props;

    return (
      <React.Fragment>
        <Button
          color="link"
          onClick={this.toggle}
          className="mr-1"
          style={{ margin: "0px", padding: "0px" }}
          {...this.props}
        >
          <span style={{ color: "black" }}>Edited</span>
        </Button>

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="modal-default"
          size="lg"
        >
          <ModalHeader toggle={this.toggle}> Edit History</ModalHeader>
          <ModalBody>
            <Table striped bordered responsive >
              <thead>
                <tr>
                  <th>Message</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {history.map(h => (
                  <tr>
                    <td>{h.text}</td>
                    <td>{moment.unix(h.updatedAt).format("Do MMM [at] h:mm a")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EditHistoryModal;
