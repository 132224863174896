import * as actions from "../Actions/type";

const initSate = {
  sources: [],
  packages: undefined, // if undefined:packages is still laoding,
  liveStreamPackages: undefined, // if undefined:liveStreamPackages is still laoding,
  expiry: undefined,
};

const PaymentReducer = (state = initSate, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_SOURCES: {
      return {
        ...state,
        sources: [...payload]
      };
    }

    case actions.SET_PACKAGES: {
      return {
        ...state,
        packages: [...payload]
      };
    }

    case actions.SET_LIVE_STREAM_PACKAGES: {
      return {
        ...state,
        liveStreamPackages: [...payload]
      };
    }

    case actions.SET_EXPIRY: {
      return {
        ...state,
        expiry: payload
      };
    }

    default: {
      return state;
    }
  }
};

export default PaymentReducer;
