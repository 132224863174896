import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import gravatar from "gravatar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { set } from "lodash";
export default class RealtorInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "/images/face-placeholder.gif",
      logo: "/images/house-placeholder.jpg",
      inProgress: false,
      followed: false,
      avgTime: "",
      avgViewedOffers: 0,
      totalOffers: 0,
    };
  }

  setImageById = (id) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const realtorImageRef = storageRef.child(`images/users/${id}.jpg`);
    const builderLogoRef = storageRef.child(`images/logos/${id}.jpg`);
    builderLogoRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ logo: url });
      })
      .catch((error) => {
        console.log(error);
      });
    realtorImageRef
      .getDownloadURL()
      .then((url) => {
        this.setState({ image: url });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setFollowingFlag = (followingId) => {
    const db = firebase.firestore();
    const { user } = this.props;

    if (!user) {
      return;
    }

    db.collection("network")
      .doc(followingId)
      .get()
      .then((doc) => {
        const data = doc.data();
        let followed = false;
        if (data) {
          const { followers } = data;
          (followers || []).forEach((d) => {
            if (d.id === user.uid) {
              followed = true;
            }
          });
        }
        this.setState({
          followed,
        });
      });
  };

  componentWillMount() {
    const { id, realtorEmail, onBuilderUpdate, user } = this.props;
    const db = firebase.firestore();
    if (realtorEmail !== undefined && realtorEmail !== "Self Represented") {
      // realtorEmail is set at same email address, so get realtor info from realtors collection
      return db
        .collection("realtors")
        .where("realtor_email", "==", realtorEmail)
        .get()
        .then((snap) => {
          if (snap.docs.length > 0) {
            const doc = snap.docs[0];
            this.setImageById(doc.id);
            const data = doc.data();
            data.id = doc.id;
            this.setFollowingFlag(data.id);
            if (this.mounted) {
              this.setState({ role: data.role, data: data });
            }
            if (data.role === "builder" && onBuilderUpdate) {
              onBuilderUpdate(data);
            }
          }
        });
    } else {
      this.setImageById(id);
      db.collection("public_profiles")
        .doc(id)
        .get()
        .then((doc) => {
          const data = doc.data();
          if (!data) return null;

          data.id = id;
          this.setFollowingFlag(data.id);
          if (this.mounted) this.setState({ role: data.role, data: data });
          if (data.role === "builder" && onBuilderUpdate) onBuilderUpdate(data);
        });
    }
  }

  componentDidMount() {
    let totalViewTime = 0;
    let totalViewedOffers = 0;
    const db = firebase.firestore();
    let ListingKey = this.props.seller?.ListingKey;
    if (!ListingKey) {
      const urlParts = window.location.pathname.split('/');
      ListingKey = urlParts[urlParts.length - 1];
    }
    if (ListingKey) {
      db.collection("offers")
        .where("seller", "==", ListingKey)
        .get()
        .then((snap) => {
          if (snap.docs.length > 0) {
            this.setState({
              totalOffers: snap.docs.length,
            });
            snap.docs.forEach((doc) => {
              let data = doc.data();
              const { viewed } = data;
              if (viewed) {
                totalViewedOffers += 1;
                const createdAt = moment(data.createdAt * 1000);
                const viewedAt = moment(data.viewedAt * 1000);

                if (createdAt.isValid() && viewedAt.isValid()) {
                  const viewingTimeInSeconds = viewedAt.diff(
                    createdAt,
                    "seconds"
                  );
                  totalViewTime += viewingTimeInSeconds;
                }
              }
            });
          } else if (snap.docs.length === 0) {
            this.setState({
              avgTime: `No offers yet`,
              avgViewedOffers: 0,
            });
            return;
          }
          this.setState({
            avgViewedOffers: totalViewedOffers,
          });
          let avgTimeInSecs = totalViewTime / snap.docs.length;
          if (avgTimeInSecs < 60) {
            this.setState({
              avgTime: `${Math.round(avgTimeInSecs)} sec`,
            });
          } else if (avgTimeInSecs < 3600) {
            this.setState({
              avgTime: `${Math.round(avgTimeInSecs / 60)} min`,
            });
          } else {
            this.setState({
              avgTime: `${Math.round(avgTimeInSecs / 3600)} hr`,
            });
          }
        });
    }
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderRealtorPhoneAndEmail = (realtor) => {
    if (realtor && realtor.displayContactInfo) {
      return (
        <>
          <p style={{ fontSize: 14, marginBottom: 3 }}>
            {realtor.realtor_phone && realtor.realtor_phone !== "" && (
              <>
                <i className="fa fa-phone"></i>{" "}
                <a href={`tel:${realtor.realtor_phone}`}>
                  {realtor.realtor_phone}
                </a>
              </>
            )}
          </p>

          <p style={{ fontSize: 14, marginBottom: 3 }}>
            {realtor.realtor_email && realtor.realtor_email !== "" && (
              <>
                <i className="fa fa-envelope"></i>{" "}
                <a href={`mailto:${realtor.realtor_email}`}>
                  {realtor.realtor_email}
                </a>
              </>
            )}
          </p>
        </>
      );
    }
  };

  renderServices = (services) => {
    if (services && services.length) {
      return (
        <>
          <small style={{ fontWeight: "bold" }}>Services</small>
          <p>{services.join(", ")}</p>
        </>
      );
    }
  };

  renderBuyerType = (user) => {
    const { purpose, buyerType } = user;
    if (purpose === "Buy a house" || purpose === "Both") {
      return (
        <>
          <small style={{ fontWeight: "bold" }}>Buyer Type</small>
          <p>{buyerType}</p>
        </>
      );
    }
  };

  renderSellerType = (user) => {
    const { purpose, sellerType } = user;
    if (purpose === "Sell a house" || purpose === "Both") {
      return (
        <>
          <small style={{ fontWeight: "bold" }}>Seller Type</small>
          <p>{sellerType}</p>
        </>
      );
    }
  };

  renderUserDetails = (user) => {
    const { purpose, buyerType, sellerType, company, website, about } = user;
    let showCompanyProfile = false;
    if (purpose === "Both") {
      showCompanyProfile = buyerType === "iBuyer" || sellerType === "Investor";
    }
    if (purpose === "Buy a house") {
      showCompanyProfile = buyerType === "iBuyer";
    }
    if (purpose === "Sell a house") {
      showCompanyProfile = sellerType === "Investor";
    }

    if (showCompanyProfile) {
      return (
        <>
          {website && website !== "" && (
            <>
              <p style={{ fontSize: 14 }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${website}`}
                >
                  <i className="fa fa-globe" /> {website}
                </a>
              </p>
            </>
          )}
          {this.renderBuyerType(user)}
          {this.renderSellerType(user)}
          <small style={{ fontWeight: "bold" }}>Company</small>
          <h6 style={{ marginBottom: "13px" }}>{company}</h6>
          <small style={{ fontWeight: "bold" }}>About</small>
          <p>{about}</p>
        </>
      );
    }
  };

  redirectUser = async () => {
    const { id, user } = this.props;
    const { data, role } = this.state;
    const db = firebase.firestore();

    await db
      .collection("whitelistConversations")
      .doc(user.uid)
      .set(
        {
          users: firebase.firestore.FieldValue.arrayUnion({
            id,
            role: role || "user",
            name: data.full_name || data.realtor_name || data.name,
          }),
        },
        { merge: true }
      );

    await db
      .collection("whitelistConversations")
      .doc(id)
      .set(
        {
          users: firebase.firestore.FieldValue.arrayUnion({
            id: user.uid,
            role: user.role,
            name:
              user.full_name ||
              user.realtor_name ||
              user.lender_name ||
              user.builder_name,
          }),
        },
        { merge: true }
      );

    window.location.href = `/messenger/${id}`;
  };

  renderMessageLink = () => {
    return (
      <button className="btn btn-primary" onClick={this.redirectUser}>
        Send a Message
      </button>
    );
  };

  followUser = (following, userName) => {
    const {
      id: followingId,
      full_name: f_full_name,
      realtor_name: f_realtor_name,
      lender_name: f_lender_name,
      builder_name: f_builder_name,
      user_email: f_user_email,
      realtor_email: f_realtor_email,
      lender_email: f_lender_email,
      builder_email: f_builder_email,
      company: f_user_company,
      lender_company: f_lender_company,
      brokerage_name: f_brokerage_name,
    } = following;

    const { image: f_profile } = this.state;

    this.setState({ inProgress: true });

    const { followed } = this.state;
    const { user } = this.props;

    const db = firebase.firestore();

    if (followed) {
      db.collection("network")
        .doc(followingId)
        .get()
        .then((doc) => {
          const { followers } = doc.data();

          db.collection("network")
            .doc(followingId)
            .set(
              {
                followers: followers.filter((f) => f.id !== user.uid),
              },
              { merge: true }
            )
            .then(() => {
              this.setState({ inProgress: false, followed: false });
              toast.success("User Un-followed successfully.");
            })
            .catch(() => {
              this.setState({ inProgress: false });
              toast.error("Unable to Un-follow, please try again!");
            });
        });

      const groupRef = db
        .collection("network")
        .doc(followingId)
        .collection("groups");

      groupRef.onSnapshot((snaps) => {
        snaps.docs.forEach((doc) => {
          const localData = doc.data();
          groupRef.doc(doc.id).set({
            ...localData,
            followers: (localData.followers || []).filter(
              (f) => f.id !== user.uid
            ),
          });
        });
      });

      return;
    }

    try {
      const {
        full_name,
        realtor_name,
        lender_name,
        builder_name,
        user_email,
        realtor_email,
        lender_email,
        builder_email,
        company: user_company,
        lender_company,
        brokerage_name,
        profilePhoto,
      } = user;

      const profile =
        profilePhoto["64"] ||
        profilePhoto["128"] ||
        profilePhoto["256"] ||
        profilePhoto["placeholder"];
      const name =
        full_name || realtor_name || lender_name || builder_name || "No Name";
      const email =
        user_email ||
        realtor_email ||
        lender_email ||
        builder_email ||
        "no@email.com";
      const company = user_company || lender_company || "";
      const brokerage = brokerage_name || "";

      const f_name =
        f_full_name ||
        f_realtor_name ||
        f_lender_name ||
        f_builder_name ||
        "No Name";
      const f_email =
        f_user_email ||
        f_realtor_email ||
        f_lender_email ||
        f_builder_email ||
        "no@email.com";
      const f_company = f_user_company || f_lender_company || "";
      const f_brokerage = f_brokerage_name || "";

      const search = [
        user.uid,
        name.toLowerCase(),
        email.toLowerCase(),
        company.toLowerCase(),
        brokerage.toLowerCase(),
      ];

      const f_search = [
        followingId,
        f_name.toLowerCase(),
        f_email.toLowerCase(),
        f_company.toLowerCase(),
        f_brokerage.toLowerCase(),
      ];

      const followerData = {
        id: user.uid,
        name,
        email,
        profile,
        brokerage,
        company,
        search,
        createdAt: new Date().getTime() / 1000,
      };

      const followingData = {
        id: followingId,
        name: f_name,
        email: f_email,
        profile: f_profile,
        brokerage: f_brokerage,
        company: f_company,
        search: f_search,
        createdAt: new Date().getTime() / 1000,
      };

      db.collection("network")
        .doc(user.uid)
        .set(
          {
            followings: firebase.firestore.FieldValue.arrayUnion(followingData),
          },
          { merge: true }
        )
        .then(() => {
          db.collection("network")
            .doc(followingId)
            .set(
              {
                followers:
                  firebase.firestore.FieldValue.arrayUnion(followerData),
              },
              { merge: true }
            )
            .then(() => {
              this.setState({ inProgress: false, followed: true });
              toast.success("You are following " + userName);
            })
            .catch(() => {
              this.setState({ inProgress: false });
              toast.error("Unable to follow, please try again!");
            });
        })
        .catch(() => {
          this.setState({ inProgress: false });
          toast.error("Unable to follow, please try again!");
        });
    } catch (error) {
      this.setState({ inProgress: false });
      console.log(error);
      toast.error(JSON.stringify(error));
    }
  };

  render() {
    const { role, data, avgTime, totalOffers, avgViewedOffers } = this.state;
    const { realtorEmail, type, seller } = this.props;
    let userInfo = null;
    const realtorImageStyle = {
      borderRadius: "50%",
      width: "150px",
      height: "150px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    };

    if (
      role === "realtor" ||
      (realtorEmail !== undefined &&
        realtorEmail !== "Self Represented" &&
        data)
    ) {
      const realtor = data;
      userInfo = (
        <>
          <div className="retailor-container represented-by-realor">
            <div>
              <a href="#/" data-toggle="modal" data-target="#realtorModal">
                <div>
                  <img
                    style={realtorImageStyle}
                    src={this.state.image}
                    alt="realtor"
                    className="img-fluid"
                  />
                </div>
              </a>
              <div
                style={{ marginLeft: 10, marginTop: 20, textAlign: "center" }}
              >
                <small
                  style={{ fontSize: 14, marginBottom: 15, display: "block" }}
                >
                  Represented by{" "}
                </small>
                <h5
                  style={{ marginBottom: 0, fontWeight: "bold", fontSize: 24 }}
                >
                  {realtor.realtor_name}
                </h5>
                <p style={{ fontSize: 15, marginBottom: 0 }}>
                  Agent, {realtor.brokerage_name}
                </p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offer Viewed Time:
                  </span>{" "}
                  {avgTime}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The average time it takes to view the offer once it has been
                  sent.
                </p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offers Viewed:
                  </span>{" "}
                  {avgViewedOffers == 0
                    ? "No offers yet"
                    : (avgViewedOffers / totalOffers) * 100 + "%"}
                  {/* {(avgViewedOffers / totalOffers) * 100}% */}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The percentage of offers that have been viewed by listing
                  agent.
                </p>
                {/* {
                                    userAuth && userAuth.uid !== realtor.id && (
                                        <button style={{width: 'auto'}} className="search-button" onClick={() => this.followUser(realtor, realtor.realtor_name)}>{followed ? inProgress? 'Un-following...': 'Un-Follow' : inProgress? 'Following...': 'Follow'} <i className={followed ? 'fa fa-user-times': 'fa fa-user-plus'}/></button>
                                    )
                                } */}
                {/* <div className="realtor-rating">
                                    <UserReviewsCount resourceId={realtor.id} complete/>
                                </div> */}
              </div>
            </div>
          </div>
          <div id="realtorModal" className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body realtorInfo-modal-body">
                  <div className="row">
                    <div className="col-md-5">
                      <div>
                        <img
                          style={realtorImageStyle}
                          src={this.state.image}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <img
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "10%",
                            marginTop: "10px",
                          }}
                          src={this.state.logo}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h5 style={{ marginTop: 10, marginBottom: 0 }}>
                        {realtor.realtor_name}
                      </h5>

                      {this.renderRealtorPhoneAndEmail(realtor)}

                      {realtor.realtor_website &&
                        realtor.realtor_website !== "" && (
                          <>
                            <p style={{ fontSize: 14 }}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${realtor.realtor_website}`}
                              >
                                <i className="fa fa-globe" />{" "}
                                {realtor.realtor_website}
                              </a>
                            </p>
                          </>
                        )}

                      <small style={{ fontWeight: "bold" }}>Brokerage</small>
                      <h6 style={{ marginBottom: 0 }}>
                        {realtor.brokerage_name}
                      </h6>
                      <p style={{ fontSize: 14, marginTop: "3px" }}>
                        <i className="flaticon-pin" /> {realtor.street_number}{" "}
                        {realtor.street_name}, {realtor.city} {realtor.state}{" "}
                        {realtor.zip_code}
                      </p>
                      <small style={{ fontWeight: "bold" }}>License #</small>
                      <p style={{ fontSize: 14 }}>{realtor.realtor_license}</p>
                      {/* <small style={{fontWeight: 'bold'}}>About</small>
                                    <p>{realtor.realtor_description}</p>
                                    {this.renderServices(realtor.services)}
                                    {this.renderMessageLink()} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (!role && type === "MLS" && seller) {
      const {
        realtor_name,
        ListOfficeName,
        ListAgentMlsId,
        represendtedBy,
        ListAgentDirectPhone,
        seller_name,
        seller_phone,
        ListAgentEmail,
      } = seller;
      const url = gravatar.url(represendtedBy, {
        protocol: "https",
        s: "100",
        d: "mp",
      });

      userInfo = (
        <>
          <a href="#/" data-toggle="modal" data-target="#realtorModal">
            <div className="represented-by-realor">
              <div>
                <div>
                  <img
                    style={realtorImageStyle}
                    src={url || this.state.image}
                    alt="user"
                    className="img-fluid"
                  />
                </div>
                <div
                  style={{ marginLeft: 10, marginTop: 20, textAlign: "center" }}
                >
                  <small
                    style={{ fontSize: 14, marginBottom: 15, display: "block" }}
                  >
                    Represented by{" "}
                  </small>
                  <h5
                    style={{
                      marginBottom: 0,
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                  >
                    {realtor_name || seller_name}
                  </h5>
                  <p style={{ fontSize: 15, margin: 0 }}>{ListOfficeName}</p>
                  <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Offer Viewed Time:
                    </span>{" "}
                    {avgTime}
                  </p>
                  <p style={{ fontSize: 8, margin: 0 }}>
                    *The average time it takes to view the offer once it has
                    been sent.
                  </p>
                  <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Offers Viewed:
                    </span>{" "}
                    {avgViewedOffers == 0
                      ? "No offers yet"
                      : (avgViewedOffers / totalOffers) * 100 + "%"}
                    {/* {(avgViewedOffers / totalOffers) * 100}% */}
                  </p>
                  <p style={{ fontSize: 8, margin: 0 }}>
                    *The percentage of offers that have been viewed by listing
                    agent.
                  </p>
                </div>
              </div>
            </div>
          </a>
          <div id="realtorModal" className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body realtorInfo-modal-body">
                  <div className="row">
                    <div className="col-md-5">
                      <div>
                        <img
                          style={realtorImageStyle}
                          src={this.state.image}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h5 style={{ marginTop: 10, marginBottom: 0 }}>
                        {realtor_name || seller_name}
                      </h5>

                      <p style={{ fontSize: 14, marginBottom: 3 }}>
                        {ListAgentDirectPhone &&
                          ListAgentDirectPhone !== "" && (
                            <>
                              <i className="fa fa-phone"></i>{" "}
                              <a href={`tel:${ListAgentDirectPhone}`}>
                                {ListAgentDirectPhone}
                              </a>
                            </>
                          )}
                      </p>

                      <p style={{ fontSize: 14, marginBottom: 10 }}>
                        {represendtedBy ||
                          (ListAgentEmail && represendtedBy) ||
                          (ListAgentEmail !== "" && (
                            <>
                              <i className="fa fa-envelope"></i>{" "}
                              <a
                                href={`mailto:${
                                  represendtedBy || ListAgentEmail
                                }`}
                              >
                                {represendtedBy || ListAgentEmail}
                              </a>
                            </>
                          ))}
                      </p>

                      <small style={{ fontWeight: "bold" }}>Brokerage</small>
                      <h6 style={{ marginBottom: 10 }}>{ListOfficeName}</h6>

                      <small style={{ fontWeight: "bold" }}>License #</small>
                      <p style={{ fontSize: 14 }}>{ListAgentMlsId}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (role === "user") {
      const user = data;
      const { is_wholesale } = seller || {};

      userInfo = (
        <>
          <div className="represented-by-realor">
            <div>
              <a href="#/" data-toggle="modal" data-target="#realtorModal">
                <div>
                  <img
                    style={realtorImageStyle}
                    src={this.state.image}
                    alt="user"
                    className="img-fluid"
                  />
                </div>
              </a>
              <div
                style={{ marginLeft: 10, marginTop: 20, textAlign: "center" }}
              >
                {is_wholesale !== "Yes" && (
                  <>
                    <small
                      style={{
                        fontSize: 14,
                        color: "#000000",
                        marginBottom: 15,
                        display: "block",
                      }}
                    >
                      Represented by{" "}
                    </small>
                    <h5
                      style={{
                        marginBottom: 0,
                        fontWeight: "bold",
                        fontSize: 24,
                      }}
                    >
                      {user.full_name}
                    </h5>
                  </>
                )}
                <p style={{ fontSize: 15, margin: 0 }}>
                  (no agent, self-represented)
                </p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offer Viewed Time:
                  </span>{" "}
                  {avgTime}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The average time it takes to view the offer once it has been
                  sent.
                </p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offers Viewed:
                  </span>{" "}
                  {avgViewedOffers == 0
                    ? "No offers yet"
                    : (avgViewedOffers / totalOffers) * 100 + "%"}
                  {/* {(avgViewedOffers / totalOffers) * 100}% */}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The percentage of offers that have been viewed by listing
                  agent.
                </p>
                {
                  // userAuth && userAuth.uid !== user.id && (
                  //     <button style={{width: 'auto'}} className="search-button" onClick={() => this.followUser(user, user.full_name)}>{followed ? inProgress? 'Un-following...': 'Un-Follow' : inProgress? 'Following...': 'Follow'} <i className={followed ? 'fa fa-user-times': 'fa fa-user-plus'}/></button>
                  // )
                }
              </div>
            </div>
          </div>
          <div id="realtorModal" className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body realtorInfo-modal-body">
                  <div className="row">
                    <div className="col-md-5">
                      <div>
                        <img
                          style={realtorImageStyle}
                          src={this.state.image}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <img
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "10%",
                            marginTop: "10px",
                          }}
                          src={this.state.logo}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      {is_wholesale !== "Yes" && (
                        <>
                          <h5 style={{ marginTop: 10, marginBottom: 0 }}>
                            {user.full_name}
                          </h5>
                          <p style={{ fontSize: 14 }}>
                            {user && user.phone && user.phone !== "" && (
                              <>
                                <i className="fa fa-phone"></i>{" "}
                                <a href={`tel:${user.phone}`}>{user.phone}</a>
                              </>
                            )}
                            <br />
                            <i className="fa fa-envelope"></i>{" "}
                            {user.agent_email && user.agent_email.label
                              ? user.agent_email.label
                              : user.user_email}
                            {this.renderUserDetails(user)}
                            {this.renderMessageLink()}
                          </p>
                        </>
                      )}
                      {is_wholesale === "Yes" && (
                        <>
                          <h5 style={{ marginTop: 10, marginBottom: 0 }}>
                            Private Information is Hidden
                          </h5>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (role === "builder") {
      const user = data;

      userInfo = (
        <>
          <div className="represented-by-realor">
            <div>
              <a href="#/" data-toggle="modal" data-target="#realtorModal">
                <div>
                  <img
                    style={realtorImageStyle}
                    src={this.state.image}
                    alt="user"
                    className="img-fluid"
                  />
                </div>
              </a>
              <div
                style={{ marginLeft: 10, marginTop: 20, textAlign: "center" }}
              >
                <small
                  style={{ fontSize: 14, marginBottom: 15, display: "block" }}
                >
                  Represented by
                </small>
                <h5 style={{ marginBottom: 0, fontWeight: "bold" }}>
                  {user.name}
                </h5>
                <p style={{ fontSize: 15, marginBottom: "0px" }}>
                  Sales Consultant
                </p>
                <p style={{ fontSize: 15, margin: 0 }}>{user.home_builder}</p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offer Viewed Time:
                  </span>{" "}
                  {avgTime}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The average time it takes to view the offer once it has been
                  sent.
                </p>
                <p style={{ fontSize: 15, marginBottom: 0, marginTop: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Offers Viewed:
                  </span>{" "}
                  {avgViewedOffers == 0
                    ? "No offers yet"
                    : (avgViewedOffers / totalOffers) * 100 + "%"}
                  {/* {(avgViewedOffers / totalOffers) * 100}% */}
                </p>
                <p style={{ fontSize: 8, margin: 0 }}>
                  *The percentage of offers that have been viewed by listing
                  agent.
                </p>
                {
                  // userAuth && userAuth.uid !== user.id && (
                  //     <button style={{width: 'auto'}} className="search-button test" onClick={() => this.followUser(user,user.name)}>{followed ? inProgress? 'Un-following...': 'Un-Follow' : inProgress? 'Following...': 'Follow'} <i className={followed ? 'fa fa-user-times': 'fa fa-user-plus'}/></button>
                  // )
                }
              </div>
            </div>
          </div>

          <div id="realtorModal" className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body realtorInfo-modal-body">
                  <div className="row">
                    <div className="col-md-5">
                      <div>
                        <img
                          style={realtorImageStyle}
                          src={this.state.image}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <img
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "10%",
                            marginTop: "10px",
                          }}
                          src={this.state.logo}
                          alt="realtor"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h5 style={{ marginTop: 10, marginBottom: 0 }}>
                        {user.name}
                      </h5>

                      <p style={{ fontSize: 14, marginBottom: 3 }}>
                        {user.phone && user.phone !== "" && (
                          <>
                            <i className="fa fa-phone"></i>{" "}
                            <a href={`tel:${user.phone}`}>{user.phone}</a>
                          </>
                        )}
                      </p>

                      <p style={{ fontSize: 14, marginBottom: 3 }}>
                        {user.email && user.email !== "" && (
                          <>
                            <i className="fa fa-envelope"></i>{" "}
                            <a href={`mailto:${user.email}`}>{user.email}</a>
                          </>
                        )}
                      </p>

                      <p style={{ fontSize: 14, marginBottom: 3 }}>
                        {user.address && user.address !== "" && (
                          <>
                            <i className="flaticon-pin" /> {user.address}
                          </>
                        )}
                      </p>
                      {user.website && user.website !== "" && (
                        <>
                          <p style={{ fontSize: 14 }}>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${user.website}`}
                            >
                              <i className="fa fa-globe" /> {user.website}
                            </a>
                          </p>
                        </>
                      )}

                      <small style={{ fontWeight: "bold" }}>Home Builder</small>
                      <h6 style={{ marginBottom: "13px" }}>
                        {user.home_builder}
                      </h6>

                      <small style={{ fontWeight: "bold" }}>Community</small>
                      <h6 style={{ marginBottom: "13px" }}>{user.community}</h6>

                      <small style={{ fontWeight: "bold" }}>
                        About the Builder
                      </small>
                      <p>{user.about}</p>
                      {this.renderMessageLink()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return userInfo;
  }
}
