import React, { Component } from 'react'
import Loader from "react-loader-spinner";
import parse from 'html-react-parser';

import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { DiscussionEmbed } from 'disqus-react';
import  {Badge} from 'reactstrap';

class ListPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
        }
        const { match } = this.props;
        const { params } = match;
        const { id } = params;
        
        this.getPost(id);
    }

    getPost = async (slug) => {
        const db = firebase.firestore();
        let snap = await db.collection("posts").where("isDraft", "==", false).where('slug','==',slug).get();
        const posts = snap.docs.map(doc => {
            return {
                id: doc.id,
                ...(doc.data()),
            };
        });
        if(posts.length > 0){
            this.setState({
                post: posts[0]
            });
        }
    }

    renderPostTags = (p) => {
        const { tags:postTags } = p || {};
        let tags = "";
        tags = (postTags || []).map((e,i) => {
            const { label } = e || {};
            return <Badge key={i} color="secondary" style={{marginRight:'5px',padding: '7px', marginTop: '5px', width:'max-content'}}>{ label }</Badge>;

        })
        return tags;
    }

    renderPostCategories = (p) => {
        const { category } = p || {};
        let categories = "";
        categories = (category || []).map((e) => {
            const { label } = e || {};
            return <span className="badge badge-success">{ label }</span>
        })

        return categories;
    }

    render() {
        const {post} = this.state;

        if(!post){
            return <Loader type="Rings" color="#ff214f" height="80%" width="100%" />;
        }

        return (
            <React.Fragment>
                <div className="py-4">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center">
                            <div className="col-lg-11 col-md-11 col-sm-12">
                            <div className='p-2'>
                                    <div className="d-flex border">
                                        <div className="d-flex blogPost-wrapper">
                                            <div className="p-5 blogPost-container">
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <div className="d-flex flex-row">
                                                        <img alt="avatar" className="rounded-circle" src={post.author.profileImage} width="32" height="32" />
                                                        <div className='ml-2 d-flex flex-column'>
                                                            <div className='small'>{post.author.name}</div>
                                                            <div className='small text-muted'>{`${ moment.unix(post.createdOn).format("DD MMMM")}  .  ${post.readTime} min`}</div>
                                                        </div>
                                                    </div>
                                                    <i className="fa fa-ellipsis-v" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h3 className='mt-4 mb-3'>{post.title}</h3>
                                                    <div>
                                                        { this.renderPostCategories(post)}
                                                    </div>
                                                    <p className="mb-4 post-content">{parse(post.body)}</p>
                                                    <div className="tags">
                                                        { this.renderPostTags(post)}
                                                    </div>
                                                    
                                                </div>
                                                <hr />
                                                {/* <div className="d-flex flex-row justify-content-between small text-muted">
                                                    <div className="d-flex flex-row">
                                                            <div>{(post.views || 0)} views</div>
                                                        <div className="ml-3">Write a comment</div>
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <span className="mr-1">1</span>
                                                        <i className="fa fa-heart-o text-danger" />
                                                    </div>
                                                </div> */}

                                                <DiscussionEmbed
                                                    shortname='homematchx'
                                                    config={{
                                                        url: window.location.href,
                                                        identifier: post.slug,
                                                        title: post.title,
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default ListPosts