import React, { Component } from "react";
import TableDef from "components/TableDef";
import Image from "components/Image";
import { Link } from "react-router-dom";

class SoldHomesListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: ""
    };
  }

  render() {
    const { seller, data } = this.props;
    const {
      reason,
      details: otherReason,
      agentName,
      agentPhone,
      agentEmail,
      agentLicence,
      contractDate,
      soldDate,
      sqft,
      sqft_source,
      sqft_source_documentation_type,
      price,
      financeType,
      sellerContribution
    } = data.reason;
    let tableData = [];
    if (reason === "Other") {
      tableData = [{ key: "Details", value: otherReason || "-" }];
    } else if (reason === "Property has Sold") {
      tableData = [
        { key: "Agent Name", value: agentName || "-" },
        { key: "Agent Phone", value: agentPhone || "-" },
        { key: "Agent Email", value: agentEmail || "-" },
        { key: "Agent Licence", value: agentLicence || "-" },
        { key: "Contract Date", value: contractDate || "-" },
        { key: "Sold Date", value: soldDate || "-" },
        { key: "Sqft", value: sqft || "-" },
        { key: "Sqft Source", value: sqft_source || "-" },
        {
          key: "Sqft Source Documentation",
          value: sqft_source_documentation_type || "-"
        },
        { key: "Price", value: price || "-" },
        { key: "Finance Type", value: financeType || "-" },
        { key: "Seller Contribution", value: sellerContribution || "-" }
      ];
    }

    let photo = null;
    const featuredImage = seller.data().featured_image;
    if (featuredImage && featuredImage.url) {
      photo = (
        <Image src={featuredImage.url} alt="listing" className="img-fluid" />
      );
    } else {
      photo = (
        <img
          src="/images/house-placeholder.jpg"
          alt="listing"
          className="img-fluid"
        />
      );
    }

    return (
      <Link key={seller.id} to={`/sellers/${seller.id}`}>
        <div style={{ padding: 20 }}>
          <div className="row">
            <div className="col-sm-3" style={{ marginBottom: 10 }}>
              {photo}
            </div>
            <div
              className="col-sm-9 title-container"
              style={{ marginBottom: 10 }}
            >
              <h2>{data.seller_name || "(Seller name)"}</h2>
              <p>
                <span className="badge badge-success">
                  {data.reason.reason}
                </span>
              </p>

              <h5 className="d-none d-xl-block d-lg-block d-md-block">
                <i className="flaticon-pin" />
                &nbsp;
                {data.street_number && data.street_name && !data.hideAddress
                  ? `${data.street_number} ${data.street_name}`
                  : data.address || "No address specified or address hidden"}
              </h5>
              {data.likedByBuyers && data.likedByBuyers.length ? (
                <>
                  <small>
                    <img
                      alt=""
                      style={{ height: 15 }}
                      src={"/images/red_star1.png"}
                    />
                    &nbsp;Liked by {data.likedByBuyers.length} buyers
                  </small>
                  <br />
                  <br />
                </>
              ) : null}

              <TableDef items={tableData} />
            </div>
            <div
              className="col-sm-9 offset-sm-3 action"
              style={{ marginBottom: 10 }}
            ></div>
            <hr></hr>
          </div>
        </div>
      </Link>
    );
  }
}

export default SoldHomesListItem;
