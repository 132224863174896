import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "reactstrap";

class BuyerSellerNotesModal extends Component {
  initState = {
    processing: false,
    successMessage: "",
    note: ""
  };

  state = {
    isOpen: false,
    notes: [],
    ...this.initState
  };

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state
    });
  };

  componentDidMount = async () => {
    const { resourceId, user } = this.props || {}; 
    if(!user) return;
    const db = firebase.firestore();
    const doc = await db.collection('users').doc(user.uid).collection('notes').doc(resourceId).get();
    let notes = [];
    if(doc.exists){
      notes = doc.data().data
    }
    this.setState({
      notes
    });
  }

  addNote = () => {

    const { user, resourceId } = this.props;
    const { note, notes } = this.state;
    if (!note) {
      return alert('Note can not be empty');
    }
    this.setState({
      processing: true
    });
    const newNotes = [...notes, note];

    const db = firebase.firestore();
    db.collection('users').doc(user.uid).collection('notes').doc(resourceId).set({
      data: newNotes
    }).then(() => {
      this.setState({
        notes: newNotes,
        ...this.initState
      });
    })
      .catch(() => {
        alert("Something went wrong, please try again later");
        this.setState({
          ...this.initState
        });
      });
  };

  render() {

    const { isOpen, note, processing, notes } = this.state;
    return (
      <React.Fragment>
        <Button color="link"
        style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
        onClick={this.toggle}
        ><i className="fa fa-eye" /> Notes</Button>

        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}>Notes</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="message">Your Note</Label>
                  <Input
                    rows={5}
                    type="textarea"
                    name="note"
                    value={note}
                    onChange={({ target }) =>
                      this.setState({ note: target.value })
                    }
                    id="note"
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="success" block onClick={this.addNote}>
                    <i className="fa fa-plus" />{" "}
                    {processing ? "Adding..." : "Add"}
                  </Button>
                </FormGroup>
              </Col>
              <Col>
                <h4>History</h4>
                <ListGroup style={{ overflowY: "scroll", height: "400px", padding: '5px' }}>
                  {(notes || []).map((n, i) => (
                    <ListGroupItem key={i} style={{ marginBottom: '5px' }}>{n}</ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BuyerSellerNotesModal;