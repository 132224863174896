import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import LoadingComponent from '../components/Common/Loader';
import DataList from "./../components/DataList";
import firebase from "firebase/app";
import "firebase/firestore";
import FilterBox from "../components/FilterBox";
import BuyerTile from "../components/BuyerTile";
import { Media } from "react-breakpoints";
import { getFilteredBuyers } from "../utils/Functions";

const buyerPageSize = 48;

let lastVisible = null;

class Homes extends DataList {
  constructor(props) {
    super(props, "sellers");

    const filterObj = localStorage.getItem('BuyerFilters') || '{}';

    this.state = {
      filters: JSON.parse(filterObj),
      collapsed: true,
      buyers: [],
      hasMore: true,
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const filters = this.state.filters;
    const prevFilters = prevState.filters;
    const hasChanged = (name) => filters[name] !== prevFilters[name];

    const fields = [
      ...new Set(Object.keys(filters).concat(Object.keys(prevFilters))),
    ];

    if (fields.some((f) => hasChanged(f))) {
      lastVisible = null;
      this.setState({
        buyers: [],
      },this.fetchBuyerResults);

    }
  }

  componentWillMount() {
    lastVisible = null;
    this.setState({
      hasMore: true,
    },this.fetchBuyerResults);
  }

  fetchBuyerResults() {


    // this.setState({
    //   isLoading: true
    // });

    const db = firebase.firestore();
    const { filters, buyers } = this.state;

    const oldSize = buyers.length;

    let query = db
      .collection("buyers")
      .where("draft", "==", false)
      .where("removed", "==", false);
    if (filters && filters.search) {
      query = query.where(
        "search",
        "array-contains",
        filters.search.toLowerCase()
      );
    }

    if (filters && filters.purchase_month) {
      const v = filters.purchase_month;
      query = query.where("purchase_month", "==", v);
    }

    if (filters && filters.purchase_year) {
      const v = parseInt(filters.purchase_year);
      query = query.where("purchase_year", "==", v);
    }

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    query = query.limit(buyerPageSize);



    query
      .get()
      .then(({ docs: results }) => {
        if (results.length > 0) {
          lastVisible = results[results.length - 1];
        }

        const hasMore = results.length !== oldSize;

        // client side filtering
        let docs = getFilteredBuyers([...buyers, ...results], filters);

        if (this.mounted) {
          this.setState({ buyers: docs, hasMore, isLoading: false });
        }
      })
      .catch((error) => {
        console.error("error loading buyers", error);
      });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  commitSearch(inputs) {

    const filters = Object.assign({}, inputs);
    this.setState({ filters, hasMore: true, isLoading: true });
    lastVisible = null;
    localStorage.setItem('BuyerFilters',JSON.stringify(filters));
  }

  filters() {
    const form = (
      <div className="sidebar-left banner">
        <div className="widget advanced-search submit-address dashboard-list">
          <FilterBox
            title="Search Buyers"
            commitSearch={this.commitSearch.bind(this)}
            filters={this.state.filters}
          />
        </div>
      </div>
    );

    return (
      <div className="col-md-3">
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            let content;
            if (breakpoints[currentBreakpoint] > breakpoints.mobileLandscape) {
              content = form;
            } else {
              const buttonContent = this.state.collapsed ? (
                <span>
                  <i className="fa fa-angle-double-down"></i>&nbsp;Show filters
                </span>
              ) : (
                <span>
                  <i className="fa fa-angle-double-up"></i>&nbsp;Hide filters
                </span>
              );

              content = (
                <div>
                  <button
                    style={{
                      marginBottom: 10,
                      border: 0,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    onClick={(e) =>
                      this.setState({ collapsed: !this.state.collapsed })
                    }
                  >
                    {buttonContent}
                  </button>
                  {this.state.collapsed ? null : form}
                </div>
              );
            }

            return content;
          }}
        </Media>
        <a
          href="https://www.dallascowboys.com"
          rel="noopener noreferrer"
          target="_blank"
          style={{ marginBottom: 10 }}
        >
          <img alt="" className="img-fluid" src="/img/ads/a1.png"></img>
        </a>
      </div>
    );
  }

  filterMarkup() {
    const { filters } = this.state;
    const keys = Object.keys(filters).filter((k) => filters[k]);

    if (keys.length > 0) {
      return (
        <p style={{ marginBottom: 0, marginTop: 20 }}>
          <small>Filters: </small>
          {keys.map((k) => {
            let text = "";
            if (filters[k].constructor === Object) {
              text = "location";
            } else {
              text =
                filters[k].constructor === Array
                  ? filters[k].join(", ")
                  : filters[k];
            }
            return (
              <span key={k}>
                <span className="badge badge-secondary">{text}</span>
                <button
                  style={{border: 0, cursor: 'pointer',padding: '0px',backgroundColor: 'transparent', color: 'red',marginLeft: '3px',}}
                  onClick={(e) => this.removeFilter(k)}
                >
                  <i className="fa fa-times"/>
                </button>
                &nbsp;&nbsp;
              </span>
            );
          })}
        </p>
      );
    } else {
      return null;
    }
  }

  removeFilter(filter) {
    const { filters } = this.state;
    const newFilters = Object.assign({}, filters);
    newFilters[filter] = "";
    localStorage.setItem('BuyerFilters',JSON.stringify(newFilters));
    this.setState({ filters: newFilters, hasMore: true, isLoading: true });
  }

  render() {
    return (
      <React.Fragment>
        <div className="properties-section-body" style={{ paddingTop: 10 }}>
          <div className="container-fluid">
            <div className="row">
              {this.filters()}
              <div className="col-md-9">
                <a
                  href="https://www.ticketmaster.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="" className="img-fluid" src="/img/ads/a2.png"></img>
                </a>
                {this.filterMarkup()}
                {this.buyerResults()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  buyerResults() {
    const { buyers, filters, hasMore, isLoading } = this.state;

    const heading = <h4 style={{ paddingTop: 25 }}>Current and Future Buyers to Market</h4>;

    if(isLoading) {
      return (
        <React.Fragment>
          <LoadingComponent/>
        </React.Fragment>
      )
    }

    if (!(buyers || []).length) {
      return (
        <React.Fragment>
          {heading}
          <p>No results found</p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {heading}
        <h6 style={{ paddingBottom: 15 }}>
          These buyers will purchase a home in the near future
        </h6>
        <InfiniteScroll
          className="row"
          pageStart={0}
          loadMore={() => {
            this.fetchBuyerResults();
          }}
          hasMore={hasMore}
          loader={<React.Fragment key="1"/>}
        >
          {buyers.map((d, index) => (
            <BuyerTile filters={filters} key={index + 1} doc={d} />
          ))}
        </InfiniteScroll>
      </React.Fragment>
    );
  }
}

export default Homes;
