import React, { Component } from "react";
import { Link } from "react-router-dom";
import Alert from "components/Alert";
import {Alert as ReactAlert} from "reactstrap";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      email: "",
      success:'',
      processing: false
    };
  }

  renderError = () => {
    const { error } = this.state;
    if (error) {
      const { message } = error;
      return <Alert>{message}</Alert>;
    }
  };
  renderSuccess = () => {
    const { success } = this.state;
    if (success) {
      return (
        <ReactAlert color="success">{success}</ReactAlert>
      );
    }
  };

  sendPasswordReestEmail = () => {
    const { email } = this.state;
    this.setState({
      processing: true,
      error: "",
      success:'',
    });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({
          processing: false,
          success: "Email Sent Successfully"
        });
      })
      .catch(error => {
        this.setState({
          processing: false,
          error: error.message || "Something went wrong, please try again !"
        });
      });
  };

  render() {
    const { email, processing } = this.state;
    return (
      <React.Fragment>
        <div
          style={{ backgroundImage: "url(/images/1ddol8rgUH8.jpg)" }}
          className="contact-section overview-bgi"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-content-box">
                  <div className="details">
                    <Link to="/">
                      <img
                        src="/img/logos/black-logo.png"
                        alt="black-logo"
                        className="cm-logo"
                      />
                    </Link>
                    <h3>Reset your password</h3>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          className="input-text"
                          onChange={e =>
                            this.setState({ email: e.target.value })
                          }
                          value={email}
                        />
                      </div>
                      <div className="form-group mb-0">
                        {this.renderError()}
                        {this.renderSuccess()}
                        <button
                          type="button"
                          className="btn-md button-theme btn-block"
                          onClick={this.sendPasswordReestEmail}
                        >
                          {processing ? "Sending..." : "Reset Password"}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="footer">
                    <span>
                      Don't have an account?&nbsp;
                      <Link to="/signup">Register here</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PasswordReset;
