import React, { Component } from "react";
import PackageSubscriber from "./PackageSubscriber";

export default class extends Component {
  render() {
    const { p, offset } = this.props;
    const { name, benefits, period } = p;
    return (
      <div className={`${offset} col-md-4`} style={{marginTop:'20px'}}>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title text-muted text-uppercase text-center">
              {name}
            </h5>
            <div className="card-price text-center">
              {period.map(({ duration, price }, i) => {
                const jsx = [];
                if (i !== 0) {
                  jsx.push(
                    <div key="1" className="periods">
                      <span className="period">
                        <strong>OR</strong>
                      </span>
                    </div>
                  );
                }
                jsx.push(
                  <div key="2" className="periods">
                    ${price}
                    <span className="period">/{duration}</span>
                  </div>
                );
                return jsx;
              })}
            </div>

            <hr />
            <p><strong>Features</strong></p>
            <ul className="fa-ul">
              {benefits.map((b, i) => (
                <li key={i}>
                  <span className="fa-li">
                    <i className="fa fa-check"/>
                  </span>
                  {b}
                </li>
              ))}
            </ul>
            <PackageSubscriber {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
