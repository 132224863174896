import React, { Component } from "react";

import 'font-awesome/css/font-awesome.css';

import { Editor } from '@tinymce/tinymce-react';

import classnames from 'classnames';


import {
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';

import Medias from "./../Medias/Medias";

class BlogPostForm extends Component {

  config = {
    heightMin: 300,
    toolbarButtons: {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOLSimple', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
        'align': 'right',
        'buttonsVisible': 2
      }
    }
  };
  
  state = {
    ...this.props.blogPost,
    activeTab: '1',
  }

  onChange = ({ target }) => {
    const { value, name, type, checked } = target;
    const { onStateChange } = this.props;
    this.setState({ [name]: type === "checkbox" ? checked : value }, () => {
      if (onStateChange) {
        onStateChange(this.state);
      }
    });
  }

  onEditorChange = (editorState) => {
    const { onStateChange } = this.props;
    this.setState({ body: editorState }, () => {
      if (onStateChange) {
        onStateChange(this.state);
      }
    });
  }

  onChangeImage = (imageList, targetName) => {
    const { onStateChange } = this.props;
    if (imageList?.length)
      this.setState({ [targetName]: imageList[0] }, () => {
        if (onStateChange) {
          onStateChange(this.state);
        }
      });
    else {
      this.setState({ [targetName]: '' }, () => {
        if (onStateChange) {
          onStateChange(this.state);
        }
      });
    }
  }

  onImageChange = (item) => {
    const { path } = item || {};
    const { onStateChange } = this.props;
    this.setState({
      image: path
    }, () => {
      if(onStateChange){
        onStateChange(this.state);
      }
    })
  }

  calculateSlug = ({ target }) => {
    const {value:title} = target;
    const { onStateChange } = this.props;
    this.setState({
      title,
      slug: (title || '').toLowerCase().replace(/ /g, "-"),
    },() => {
      if(onStateChange){
        onStateChange(this.state);
      }
    });
  }

  toggleTab = (activeTab) => {
    this.setState({activeTab});
  }

  onTagsChange = (tagValue) => {
    const { onStateChange } = this.props;

    this.setState({
      tags: tagValue
    }, () => {
      if(onStateChange){
        onStateChange(this.state);
      }
    })
  }


  onCategoryChange = (categoryValue) => {
    const { onStateChange } = this.props;

    this.setState({
      category: categoryValue
    }, () => {
      if(onStateChange){
        onStateChange(this.state);
      }
    })
  }

  render() {
    const { title, body, activeTab } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xl="12" lg="12" md="12">
            <Card className="mb-4 mb-lg-0">
              <CardHeader>Message Content</CardHeader>
              <CardBody>
                <FormGroup>
                  <Input type="text" name="title" placeholder="Message Title" value={title} onChange={this.calculateSlug} required autoComplete="off" />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { this.toggleTab('1') }}
                      >Editor</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { this.toggleTab('2') }}
                      >
                        Medias
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          {/* <FroalaEditor
                            config={this.config}
                            model={body}
                            onModelChange={this.onEditorChange}
                            required
                          /> */}

                          <Editor
                            apiKey="bxpw1whgpvpnbsi17cf5y11ibnm3lfl69fv3xwqrjj6ml5lb"
                            initialValue={body}
                            value={body}
                            init={{
                              height: 500,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount fullscreen'
                              ],
                              toolbar: () => (
                                <span>
                                  fullscreen undo redo | formatselect | bold italic backcolor |
                                  alignleft aligncenter alignright alignjustify |
                                  bullist numlist outdent indent | removeformat | help
                                </span>
                              )
                              }}
                            onEditorChange={this.onEditorChange}
                          />
                                
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <Medias {...this.props} style={{height: '350px'}} upBtnSize='sm' imgColSize="4" onChange={media => this.setState({media})}/>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment >
    );
  }
}

export default BlogPostForm;