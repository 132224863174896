import React, { Component } from 'react'

class Input extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    render() {
        let {type, description, label, placeholder} = this.props;
        
        if (type === 'currency') {
            type = 'number'
        }
        
        return (
            <React.Fragment>
                <div className={`col-lg-${this.props.width || 4} col-md-${this.props.width*2 || 4} col-sm-12`}>
                    <div className="form-group">
                        <label title={this.props.title} className="capitalize">{this.props.label}</label>
                        {description && <p>{description}</p>}
                        <input
                            title={this.props.title}
                            required={this.props.required || false}
                            type={type}
                            name={this.props.name}
                            min={this.props.min || 0}
                            step={this.props.step}
                            max={this.props.max}
                            placeholder={placeholder || label}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            className="input-text"
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Input
