import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import firebase from "firebase/app";
import "firebase/firestore";

class RealtorSelect extends Component {
  state = {
    value: [],
  };

  onChange = (value) => {
    const { onChange } = this.props;
    this.setState({value});
    onChange(value);
  }

  get = async (value, callback) => {
      try {
        const db = firebase.firestore();
        const snap = await db.collection("realtors").where("realtor_license", "==", value).get();
        const results = snap.docs.map(d => {
            const data = d.data();
            return {
                label: data.realtor_name,
                value:data,
            };
        });
        callback(results);
      } catch (error) {
          console.log({error});
      }
  };

  render() {
    const { value } = this.state;
    
    return (
      <AsyncSelect
        value={value}
        placeholder="Select Realtor by Licence"
        cacheOptions
        loadOptions={this.get}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}

export default RealtorSelect;
