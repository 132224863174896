import React, { Component } from "react";
import { connect } from "react-redux";
import MessagesListItem from "./MessagesListItem";

class MessagesList extends Component {
  scrollToBottom = () => {
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const { messages, profile, user } = this.props;

    return (
      <div
        className="messages"
        ref={div => {
          this.messageList = div;
        }}
      >
        <ul>
          {messages.map((m, i) => (
            <MessagesListItem
              key={i}
              profile={profile}
              user={user}
              message={m}
              messages={messages}
            />
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList);
