import React, { Component } from 'react'
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';

class Input extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
        }
    }

    control() {
        let {options, onMenuOpen, onMenuClose, name, value, placeholder, onChange, formatCreateLabel, required, multiselect, creatable, invalid} = this.props

        if (!options) {
            options = []
        }

        let optionsList = options
        if (options.constructor !== Array) {
            optionsList = Object.values(options)
        }
        
        if (multiselect || creatable) {
            let selectedValue = value
            if (value.constructor !== Array) {
                selectedValue = [value]
            }

            selectedValue = selectedValue.map(v => {
                if (typeof(v) === 'string') {
                    return {label: v, value: v}
                }
                else {
                    return v
                }
            })

            if (creatable) {
                const optionsMarkup = optionsList.map(o => { 
                    if (typeof(o) === 'string') {
                        return {value: o, label: o}
                    }
                    else {
                        return o
                    }
                })

                return <div className={`react-select ${invalid ? 'invalid': ''}`}><CreatableSelect
                    menuContainerStyle={{top: 'auto',	bottom: '100%'}}
                    options={optionsMarkup}
                    value={selectedValue}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    onChange={e => {
                        if (e.__isNew__) {
                            delete e.__isNew__
                            e.isNew = true
                        }
                        onChange(e)
                    }}
                    isMulti={multiselect}
                    formatCreateLabel={formatCreateLabel}
                /></div>
            }
            else {
                return <div className={`react-select ${invalid ? 'invalid': ''}`}><Select
                    overrideStrings={{
                        selectSomeItems: "Check all that apply"
                    }}
                    isMulti={multiselect}
                    required={required}
                    options={optionsList.map(o => { return {value: o, label: o}})}
                    value={selectedValue}
                    onChange={onChange}
                /></div>
            }
        }
        else {
            const optionsMarkup = [<option key='_none_' value=''>{placeholder || 'Select one...'}</option>]

            optionsMarkup.push.apply(optionsMarkup, optionsList.map((option,i) => {
                let key, value
                if (typeof(option) === 'object') {
                    key = option.key
                    value = option.value
                }
                else {
                    key = option
                    value = option
                }

                return <option key={key || i} value={key}>{value}</option>
            }))

            return <select
                value={value}
                onChange={e => onChange(e.target.value)}
                name={name}
                required={required}
                className="form-control custom-select">
                {optionsMarkup}
            </select>
        }
    }

    render() {
      const {label} = this.props


      return (
        <React.Fragment>
            <div className={`col-lg-${this.props.width || 4} col-md-${this.props.width * 2 || 4} col-sm-12`}>
                <div className="form-group">
                    <label className="capitalize">{`${label}`}</label>
                    {this.control()}
                </div>
            </div>
        </React.Fragment>
      )
    }
}

export default Input
