import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Review from './Review';

class ReviewsModal extends Component {

    state = {
        isLoading : false,
        modal: false
    }

    toggle = () => {
        const { modal } = this.state;
        this.setState({
            modal: !modal
        })
    }
    render () {
        const { modal } = this.state;
        const { reviews } = this.props;
        return (
            <Fragment>
                <Button
                    color="link"
                    style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
                    onClick={this.toggle}
                >
                    (based on { (reviews || []).length} reviews)
                </Button>
                <Modal isOpen={modal} toggle={ this.toggle} className="modal-danger modal-lg user-review-modal">
                    <ModalHeader toggle={ this.toggle }>Reviews ({(reviews || []).length})</ModalHeader>
                    <ModalBody>
                        <div className="reviews-content-wrapper">
                            { (reviews || []).length > 0 ? <Fragment>
                                { 
                                    (reviews || []).map((e, i) => {
                                        return <Review review={ e } key = { i } ></Review>
                                    })
                                }
                                </Fragment> : <p>No Reviews.</p>
                            }
                            
                        </div>
                    </ModalBody>
                </Modal>  
            </Fragment>

        )
    }
}


export default ReviewsModal;