import React, { Component } from "react";
import { connect } from "react-redux";
import MessagesList from "./MessagesList";
import firebase from "firebase/app";
import "firebase/database";
import { getSelectConversation } from "../../utils/Functions";
import { markConversationAsRead } from "./../../utils/Functions";
import * as actions from "../../Store/Actions/type";
import ReactDOMServer from 'react-dom/server';
import buyerInviteEmail from '../../components/emailTemplates/BuyerInviteEmailFromLender';
import {firebaseConfig} from './../../components/Firebase/firebase';

const { host } = firebaseConfig;

class ChatView extends Component {
  state = {
    message: ""
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  getSelectConversation = () => {
    const { selectedConverstation, conversations } = this.props;
    let conversation = null;
    if (!selectedConverstation) {
      return conversation;
    }
    conversations.forEach(c => {
      if (c.id === selectedConverstation) {
        conversation = c;
      }
    });
    return conversation;
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  sendMessage = (e, conversation, status) => {
    e.preventDefault();

    const { user } = this.props;
    const { full_name, profilePhoto, realtor_name,lender_name,builder_name, role } = user;
    
    const profileImage =
      profilePhoto["64"] ||
      profilePhoto["128"] ||
      profilePhoto["256"];


    const db = firebase.firestore();

    const { senderID, receiverID, email } = conversation;
    const id = `${receiverID}-${senderID}`;
    let { message } = this.state;

    // current timestamp in UTC
    const timestamp = Math.floor(Date.now() / 1000);
    message = {
      id: this.uuidv4(),
      type: "text",
      text: message,
      timestamp: timestamp,
      updatedAt: timestamp,
      deleted: false,
      history: [{ text: message, updatedAt: timestamp }],
    };

    let name = (full_name || realtor_name || lender_name || builder_name);

    if(status === 'offline'){
      const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
      const body = ReactDOMServer.renderToStaticMarkup(buyerInviteEmail({
        profileDescriptionZero:'You have 1 new message',
        profilePicture: `${profileImage}`,
        profileDescription: `${name} - ${this.capitalize(role)}`,
        centerContent: true,
        button: {
          text: 'View Messages',
          link: `${host}/messenger`,
        },  
      }));
      // console.log(body);
      sendEmail({subject :`${name} sent you a new message`, email ,body})
    }

    db.collection("conversations")
      .doc(id)
      .set({
          senderID: receiverID,
          receiverID: senderID,
          lastSeenAt: timestamp,
          messages: firebase.firestore.FieldValue.arrayUnion(message)
        },
        { merge: true }
      )
      .then(() => {
        this.setState({ message: "" });
      });
  };

  uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  onFocus = conversation => {
    const { senderID, receiverID } = conversation;
    markConversationAsRead(receiverID, senderID);
  };

  toggleContactsViewVisibility = () => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.TOGGLE_CONTACTS_VIEW_VISIBILITY
    });
  }

  render() {
    const { selectedConverstation, conversations } = this.props;
    const conversation = getSelectConversation(
      selectedConverstation,
      conversations
    );

    if (!conversation) {
      return (
        <div
          className="content"
          style={{ alignItems: "center", display: "inline-grid" }}
        >
          <h2 style={{ textAlign: "center" }}>No conversation available</h2>
        </div>
      );
    }

    const { name, profile, messages } = conversation;
    const status = conversation.status || "offline";
    const { message } = this.state;

    return (
      <div className="content">
        <div className="contact-profile">
        <button onClick={this.toggleContactsViewVisibility}><i className="fa fa-arrow-right"></i></button>
          <img src={profile} alt="" />
          <p>
            {name} - {status}
          </p>
        </div>
        <MessagesList
          user={this.props.user}
          messages={messages}
          profile={profile}
        />
        <form onSubmit={e => this.sendMessage(e, conversation, status)}>
          <div className="message-input">
            <div className="wrap">
              <input
                name="message"
                type="text"
                value={message}
                onChange={this.onChange}
                placeholder="Write your message..."
                autoComplete="off"
                onFocus={() => this.onFocus(conversation)}
              />
              <button className="submit" type="submit">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {
    ...MessengerReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatView);
