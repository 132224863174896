import React, { Component } from "react";

import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";

class WelcomeModal extends Component {
  state = {
    email: '',
  };

  onChange = async ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value
    });
  };

  send = () => {
      const {email} = this.state;
      const {sendEmailFunction} = this.props;
      if(sendEmailFunction){
        sendEmailFunction(email);
      }
  }

  render() {
    const { email } = this.state;
    const {sendModalState, toggleModelState} = this.props;
    return (
      <React.Fragment>
        <Modal
          isOpen={sendModalState}
          toggle={toggleModelState}
          className="modal-success modal-lg"
        >
          <ModalHeader toggle={toggleModelState}>Send Email</ModalHeader>
          <ModalBody>
          <Form>
          <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  placeholder="User Email"
                  onChange={this.onChange}
                />
          </FormGroup>
          </Form>
          </ModalBody>
          <ModalFooter>

            <Button type="submit" color="success" onClick={this.send}>
              Send
            </Button>

            <Button color="secondary" onClick={toggleModelState}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default WelcomeModal;
