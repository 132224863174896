import React from "react";
import { firebaseConfig } from "../Firebase/firebase";

const LiveBookingSuccess = props => {
  const { name, email, body } = props;
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <title>Document</title>

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
          rel="stylesheet"
        />
      </head>
      <body style={{fontFamily: 'Open Sans, sans-serif', margin: '0 auto'}}>
        <div style={{float: 'left', width: '100%', boxSizing: 'border-box'}}>
          <div style={{maxWidth: '650px', width: '100%', margin: '0 auto', boxSizing: 'border-box'}}>
            <div style={{float: 'left', 'width': '100%', border: '1px solid #C8D0D8', boxSizing: 'border-box'}}>
              <div style={{padding: '60px 15px', textAlign: 'center', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <a href={firebaseConfig.host}>
                  <img
                    style={{maxWidth: '400px'}}
                    src={`${firebaseConfig.host}/img/black-logo.png`}
                    alt=""
                  />
                </a>
              </div>
              <div style={{borderTop: '2px solid #C8D0D8', padding: '15px 15px 50px', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                <h2>Hi {name}</h2>
                <p dangerouslySetInnerHTML={{__html: body}}></p>
              </div>
              <div style={{textAlign: 'center', float: 'left', width: '100%', padding: '30px 15px', boxSizing: 'border-box', backgroundColor: '#C8D0D8'}}>
                <p style={{color: '#000', margin: '0px'}}>
                  This email was sent to {" "}
                  <a
                    style={{textDecoration: 'none', color: '#000'}}
                    href="mailto:inkrealtygroup@gmail.com"
                  >
                    {email}
                  </a>{" "}
                  to confirm your Homematchx registration.
                </p>
              </div>
              <div style={{float: 'left', width: '100%', backgroundColor: '#656b66', padding: '30px 15px', boxSizing: 'border-box'}}>
                <div style={{width: '50%', float: 'left', padding: '20px 0px 0px'}}>
                  <h3 style={{fontWeight: '600', margin: '0', color: '#fff'}}>
                    Homematchx
                  </h3>
                </div>
                <div style={{width: '50%', float: 'left', textAlign: 'right'}}>
                  <p style={{margin: 0, color: '#fff'}}>Homematchx, LLC</p>
                  <p style={{margin: 0, color: '#fff'}}>1881 Sylvan Ave</p>
                  <p style={{margin: 0, color: '#fff'}}>Dallas, Tx 75208 USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

const JoinLiveVisit = props => {
    const { name, email, url } = props;
    return (
      <html lang="en">
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <title>Document</title>
  
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
            rel="stylesheet"
          />
        </head>
        <body style={{fontFamily: 'Open Sans, sans-serif', margin: '0 auto'}}>
          <div style={{float: 'left', width: '100%', boxSizing: 'border-box'}}>
            <div style={{maxWidth: '650px', width: '100%', margin: '0 auto', boxSizing: 'border-box'}}>
              <div style={{float: 'left', 'width': '100%', border: '1px solid #C8D0D8', boxSizing: 'border-box'}}>
                <div style={{padding: '60px 15px', textAlign: 'center', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                  <a href={firebaseConfig.host}>
                    <img
                      style={{maxWidth: '400px'}}
                      src={`${firebaseConfig.host}/img/black-logo.png`}
                      alt=""
                    />
                  </a>
                </div>
                <div style={{borderTop: '2px solid #C8D0D8', padding: '15px 15px 50px', float: 'left', width: '100%', boxSizing: 'border-box'}}>
                  <h2>Hi {name}</h2>
                  <p>
                    The live visit is going to start. Please join ASAP by <a href={url}>Clicking Here</a>
                  </p>
                </div>
                <div style={{textAlign: 'center', float: 'left', width: '100%', padding: '30px 15px', boxSizing: 'border-box', backgroundColor: '#C8D0D8'}}>
                  <p style={{color: '#000', margin: '0px'}}>
                    This email was sent to {" "}
                    <a
                      style={{textDecoration: 'none', color: '#000'}}
                      href="mailto:inkrealtygroup@gmail.com"
                    >
                      {email}
                    </a>{" "}
                    to confirm your Homematchx registration.
                  </p>
                </div>
                <div style={{float: 'left', width: '100%', backgroundColor: '#656b66', padding: '30px 15px', boxSizing: 'border-box'}}>
                  <div style={{width: '50%', float: 'left', padding: '20px 0px 0px'}}>
                    <h3 style={{fontWeight: '600', margin: '0', color: '#fff'}}>
                      Homematchx
                    </h3>
                  </div>
                  <div style={{width: '50%', float: 'left', textAlign: 'right'}}>
                    <p style={{margin: 0, color: '#fff'}}>Homematchx, LLC</p>
                    <p style={{margin: 0, color: '#fff'}}>1881 Sylvan Ave</p>
                    <p style={{margin: 0, color: '#fff'}}>Dallas, Tx 75208 USA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    );
  };

export {
    LiveBookingSuccess,
    JoinLiveVisit
}