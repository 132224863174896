import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics';
import ReactDOMServer from 'react-dom/server'
import 'firebase/functions'
import userEmail from '../components/emailTemplates/userEmail';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {firebaseConfig} from './../components/Firebase/firebase';

let {logEvent} = firebase.analytics();


export const sendBuyerAddedEmailToBuilder = (email,name,id,community) => {
    
    logEvent('buyer_added_email', {
        email,
        buyerName:name,
        buyerId:id,
        community,
    });

    let host = firebaseConfig.host;
    let profile = '/images/face-placeholder.gif';
    const subject = `Great news, ${name} has been added to your community!`;
    const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');

    const body = ReactDOMServer.renderToStaticMarkup(userEmail({
        heading:`Great news! ${name} has been added as a buyer in the ${community}.`,
        profilePicture: profile,
        profileDescription:'To check your matches and see the compatible homes',
        details:[
            `Having ${name} as a buyer means you are one step closer to making their dreams a reality.`,
            `Follow their planning process and get started.`
        ],
        headingLink:{
            label:"See their Profile",
            href:`${host}/buyers/${id}`
        },
        profileLink:{
            label:"Click here",
            href:`${host}`
        }
    }));

    sendEmail({subject,email:email,body});
};

export const sendBuyerSellerEmail = (user,role,newUser) => {

    const {full_name,realtor_name} = user;
    const name = full_name || realtor_name;

    logEvent('user_added_email', {
        name,role,email:newUser.email
    });

    let host = firebaseConfig.host;

    let profile = user.profilePhoto[256] ||  user.profilePhoto[128] || user.profilePhoto[64] || '/images/face-placeholder.gif';
    
    const subject = `See why ${name} added you on Homematchx`;
    
    const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');

    const body = ReactDOMServer.renderToStaticMarkup(userEmail({
        heading:`Great news! ${name} has added you as a ${role}`,
        profilePicture: profile,
        profileDescription:'Want to browse homes that will be available for sale in the future.',
        details:[
            `Having ${name} represent you means someone you trust has your best interest.`,
            `Plan your move and know the timing was perfect.`
        ],
        headingLink:{
            label:"See their Profile",
            href:`${host}/${role}s/${newUser.id}`
        },
        profileLink:{
            label:"Click here",
            href:`${host}`
        },
        detailsLink: {
            label:"Create an account",
            href:`${host}/signup`
        }
    }));

    sendEmail({subject,email:newUser.email,body});

    toast.success("Email Sent Successfully", {
        position: toast.POSITION.TOP_RIGHT
    });

};