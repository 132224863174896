import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Button } from "reactstrap";
import {firebaseConfig} from '../components/Firebase/firebase';

export default class Starred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staredProps: []
    };
  }

  async componentWillMount() {
    const { user } = this.props;
    let { uid, lender_email, realtor_email, email } = user;
    email = lender_email || realtor_email || email || "";
    const {cloudFunctionHost} = firebaseConfig;
    const response = await fetch(
      `${cloudFunctionHost}/getUsersStaredProperties?email=${email}&userID=${uid}`
    );

    let staredProps = await response.json();

    this.setState({
      staredProps
    });
  }

  removeElement = async index => {
    let { staredProps } = this.state;
    const { user } = this.props;
    staredProps.splice(index, 1);
    const db = firebase.firestore();
    try {
      const userDoc = await db.collection("users").doc(user.uid);
      await userDoc.update({ staredProps });
      this.setState({
        staredProps
      });
    } catch (error) {
      console.log({ error });
    }
  };

  renderRemoveButton = (value, index) => {
    const { likeBy } = value;
    if (likeBy === "Liked by me")
      return (
        <div
          className="col-sm-2 offset-sm-10"
          style={{ paddingBottom: "10px" }}
        >
          <Button color="danger" onClick={() => this.removeElement(index)}>
            Remove <i className="fa fa-times" />
          </Button>
        </div>
      );
  };

  createStarredTile = () => {
    const { staredProps } = this.state;

    if (staredProps && staredProps.length > 0) {
      return staredProps.map((value, index) => (
        <React.Fragment key={index}>
          <div
            className="row"
            onClick={() => {
              this.props.history.push(`/sellers/${value.property_id}`);
            }}
            style={{
              backgroundColor: "#dcdcdc",
              marginTop: "15px",
              cursor: "pointer"
            }}
          >
            <img
              alt=""
              src={value.imageURL || "/images/house-placeholder.jpg"}
              height={"120px"}
              width={"150px"}
              style={{ padding: "15px", borderRadius: "25px" }}
            />
            <div style={{ padding: "15px" }}>
              <h5>
                {value.street_number} {value.street_name}
              </h5>
              <div>
                {value.city} {value.state}, {value.zipCode}
              </div>
              <div>{value.HMX_Id}</div>
              <div>{value.likeBy}</div>
            </div>
          </div>
          <div
            className="row"
            style={{
              backgroundColor: "#dcdcdc",
              cursor: "pointer"
            }}
          >
            {this.renderRemoveButton(value, index)}
          </div>
        </React.Fragment>
      ));
    }

    return (
      <div>
        Only the liked listings appear here, remember to like listings to
        shortlist them.
      </div>
    );
  };

  render() {
    return (
      <div className="content-area5 dashboard-content">
        <div className="dashboard-header clearfix">
          <div>
            <div className="col-sm-12 col-md-6">
              <h4>{"Liked properties"}</h4>
            </div>
            <br />
            <div className="container">{this.createStarredTile()}</div>
          </div>
        </div>
      </div>
    );
  }
}
