import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "reactstrap";

class BuyerNotesModal extends Component {
  initState = {
    processing: false,
    successMessage: "",
    note: ""
  };

  state = {
    isOpen: false,
    likedBuyers: this.props.user.likedBuyers || [],
    buyer: this.props.buyer,
    ...this.initState
  };

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state
    });
  };

  addNote = () => {

    const {user} = this.props;
    const { buyer, note, likedBuyers } = this.state;
    if(!note){
        return alert('Note can not be empty');
    }
    this.setState({
        processing: true
    });
    const { notes } = buyer;
    const newBuyer = {
      ...buyer,
      notes: [note,...(notes || [])]
    };
    const newLikedBuyers = likedBuyers.map((b, i) => {
      if (b.id === newBuyer.id) {
        return newBuyer;
      }
      return b;
    });

    const db = firebase.firestore();
    const userDoc = db.collection("builders").doc(user.uid);
    userDoc
      .set({ likedBuyers: newLikedBuyers }, { merge: true })
      .then(() => {
        this.setState({
          likedBuyers: newLikedBuyers,
          buyer: newBuyer,
          ...this.initState
        });
      })
      .catch(() => {
        alert("Something went wrong, please try again later");
        this.setState({
            ...this.initState
        });
      });
  };

  render() {
    const { isOpen, note, buyer, processing } = this.state;
    const { notes } = buyer;
    return (
      <React.Fragment>
        <Button color="warning" onClick={this.toggle} style={{marginRight:'5px'}}>
          Notes <i className="fa fa-eye" />
        </Button>

        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}> Buyer Notes</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="message">Your Note</Label>
                  <Input
                    rows={5}
                    type="textarea"
                    name="note"
                    value={note}
                    onChange={({ target }) =>
                      this.setState({ note: target.value })
                    }
                    id="note"
                  />
                </FormGroup>
                <FormGroup>
                  <Button color="success" block onClick={this.addNote}>
                    <i className="fa fa-plus" />{" "}
                    {processing ? "Adding..." : "Add"}
                  </Button>
                </FormGroup>
              </Col>
              <Col>
                <h4>History</h4>
                <ListGroup style={{ overflowY: "scroll", height: "400px",padding:'5px' }}>
                  {(notes || []).map((n, i) => (
                    <ListGroupItem key={i} style={{marginBottom: '5px'}}>{n}</ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BuyerNotesModal;
