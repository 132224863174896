import React, { Component, Fragment } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import firebase from "firebase/app";
import "firebase/firestore";
import SellerTile from "components/SellerTile";
import BuyerTile from "components/BuyerTile";
import RealtorInfo from "components/RealtorInfo";
import stringcase from "stringcase";
import { Tooltip, Button,Badge } from "reactstrap";
import Loader from "react-loader-spinner";
import PageNotFound from "components/Common/PageNotFound";
import { firebaseConfig } from "./../components/Firebase/firebase";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import UserReviewsCount  from '../components/Review/UserReviewsCount';
const { apiKey } = firebaseConfig;

const { sentencecase } = stringcase;

class BuyerDetail extends Component {
  state = {
    tooltipOpen: false,
    isLoading: true,
    lenderDetails: {},
    sellingProfiles: [],
    likedBuyers:
      this.props.user && this.props.user.likedBuyers
        ? this.props.user.likedBuyers
        : [],
  };

  toggle = (id) => {
    this.setState({
      [id]: !this.state[id],
    });
  };

  id() {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    return id;
  }

  loadLinkedSellingProfile = async (data) => {
    if (data) {
      const { home_hmxid,home_mlsid, selling_current_home } = data;
      if (selling_current_home &&selling_current_home === "Yes") {

        if(home_mlsid || home_hmxid){
          let key = 'HMX_Id';
          let value = '';

          if(home_hmxid){
            key = 'HMX_Id';
            value = home_hmxid;
          }
          
          if(home_mlsid){
            key = 'ListingId';
            value = home_mlsid;
          }

          const db = firebase.firestore();
          const { docs } = await db.collection("sellers").where(key, "==", value).get();
          this.setState({
            sellingProfiles: docs,
          });
        }
      }
    }
  };

  componentWillMount() {
    const id = this.id();

    if (!id) {
      this.props.history.goBack();
      return;
    }

    const db = firebase.firestore();
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const buyerRef = db.collection("buyers").doc(id);
    const buyerImageRef = storageRef.child(`images/users/${id}.jpg`);

    buyerRef
      .get()
      .then((doc) => {
        const data = doc.data();
        this.loadLinkedSellingProfile(data);
        buyerImageRef
          .getDownloadURL()
          .then((url) => {
            if (this.mounted) {
              data.photo = url;
              this.setState({
                buyer: data,
                realtorEmail: data.represendtedBy,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
        if (this.mounted) {
          this.setState({
            buyer: data,
            realtorEmail: data.represendtedBy,
            isLoading: false,
          });
          if (data.lender_nmls_id || data.lender_email) {
            const promises = [
              db
                .collection("lenders")
                .where("nmls_id", "==", data.lender_nmls_id || "")
                .get(),
              db
                .collection("lenders")
                .where("lender_email", "==", data.lender_email || "")
                .get(),
            ];
            Promise.all(promises).then(
              (snapshots) => {
                if (this.mounted) {
                  let docs = [];
                  snapshots.forEach((snapshot) => {
                    snapshot.docs.forEach((d) => {
                      docs.push(d);
                    });
                  });
                  if(docs.length > 0){
                    this.setState({ lenderDetails: docs[0].data() });
                    const lenderImageRef = storageRef.child(
                      `images/users/${docs[0].id}.jpg`
                    );
                    lenderImageRef
                      .getDownloadURL()
                      .then((url) => {
                        if (this.mounted) {
                          this.setState({ lenderImage: url, ...data });
                        }
                      })
                      .catch((error) => {});
                    const lenderLogoRef = storageRef.child(
                      `images/logos/${docs[0].id}.jpg`
                    );
                    lenderLogoRef
                      .getDownloadURL()
                      .then((url) => {
                        if (this.mounted) {
                          this.setState({ lenderLogoImage: url, ...data });
                        }
                      })
                      .catch((error) => {});
                  }
                }
              },
              (error) => {
                console.error(error);
              }
            );
          }

          if((data.purpose || []).includes('Partner with other buyers')){
            db.collection("buyerMatches")
            .where("buyerId", "==", id)
            .orderBy("score", "desc")
            .limit(50)
            .get()
            .then(
              (snapshot) => {
                if (this.mounted) {
                  let { docs } = snapshot;
                  const sellerPromises = docs.map((d) =>
                    db.doc(`buyers/${d.data().buyerIdTwo}`).get()
                  );
                  Promise.all(sellerPromises).then((sellerDocs) => {
                    this.setState({ buyersMatches: sellerDocs.filter((doc) => doc.exists) });
                  });
                }
              },
              (error) => {
                console.error(error);
              }
            );
          }

          const representedBy = data.secondOwner || data.owner;
          this.setState({ representedBy });
        }
      })
      .catch((error) => console.error(error));

      db.collection("matches")
        .where("buyerId", "==", id)
        .orderBy("score", "desc")
        .limit(50)
        .get()
        .then(
          (snapshot) => {
            if (this.mounted) {
              let { docs } = snapshot;
              const sellerPromises = docs.map((d) =>
                db.doc(`sellers/${d.data().sellerId}`).get()
              );
              Promise.all(sellerPromises).then((sellerDocs) => {
                this.setState({ sellers: sellerDocs.filter((doc) => doc.exists) });
              });
            }
          },
          (error) => {
            console.error(error);
          }
        );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  matches(key) {
    const { sellingProfiles } = this.state;
    const sellers = this.state[key]
    const perPage = 3;

    if (!sellers) {
      return null;
    }

    const sellingProfilesIds = (sellingProfiles || []).map(({ id }) => id);

    const pageCount = sellers.length / perPage;
    const pagers = [];
    const pages = [];
    const matchTiles = sellers
      .filter(({ id }) => !sellingProfilesIds.includes(id))
      .map((doc) => {
        if(key === 'sellers'){
          return <SellerTile key={doc.id} doc={doc} />
        }
        return <BuyerTile key={doc.id} doc={doc}/>
      });

    for (let i = 0; i < pageCount; i++) {
      let active = "";
      if (i === 0) {
        active = "active";
      }

      pagers.push(
        <li
          key={i}
          data-target="#matches"
          data-slide-to={i}
          className={active}
        ></li>
      );

      const slice = matchTiles.slice(
        i * 3,
        Math.min(i * 3 + 4, matchTiles.length)
      );
      pages.push(
        <>
          <div key={i} className={`carousel-item ${active}`}>
            <div className="row">{slice}</div>
          </div>
        </>
      );
    }

    return (
      <div id="matches" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">{pagers}</ol>

        <h4>{key !== 'sellers' ? 'Potential Buyers': 'Future homes to buy'}</h4>

        <div className="carousel-inner">{pages}</div>

        <a
          className="carousel-control-prev"
          href="#matches"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#matches"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }

  renderDreamBoard = () => {
    const { buyer } = this.state;

    if (!buyer) {
      return null;
    }

    const images = [];

    let galleryKeys = [
      "homeGallery",
      "bedroomsGallery",
      "kitchenGallery",
      "bathroomGallery",
      "backyardGallery",
      "familyRoomGallery",
      "diningRoomGallery",
      "laundryRoomGallery",
      "mediaRoomGallery",
      "gameRoomGallery",
      "garageGallery",
      "gallery",
    ];

    if (buyer.galleryCheckbox) {
      galleryKeys = ["gallery"];
    }

    galleryKeys.forEach((key) => {
      if (buyer[key]) {
        buyer[key].forEach((img) => {
          images.push({
            original: img.url,
            thumbnail: img.url,
          });
        });
      }
    });

    if (!images.length) {
      return;
    }

    return (
      <>
        <h4>Dream Board</h4>
        <ImageGallery showNav={false} showBullets={true} items={images} />
        <hr></hr>
      </>
    );
  };

  renderTooltip = (id,text) => {
    return (
      <Tooltip
        hideArrow={true}
        style={{ backgroundColor: "#ffffff", color: "black" }}
        placement="auto"
        isOpen={this.state[id]}
        target={id}
        toggle={() => this.toggle(id)}
    >{text}</Tooltip>
    );
  };

  onReady = (mapProps, map) => {
    const google = mapProps.google;

    this.addMarker(mapProps, map);
    google.maps.event.addListener(map, "center_changed", () =>
      this.centerChanged(mapProps, map)
    );
  };

  addMarker = (mapProps, map) => {
    const { buyer } = this.state;
    const location = buyer.location || {};
    const otherLocations = [
      buyer.location_2 || {},
      buyer.location_3 || {},
      buyer.location_4 || {},
      buyer.location_5 || {},
    ];
    //const { radius } = location;
    const maps = mapProps.google.maps;
    /* if (!radius) {
      return (this.marker = new maps.Marker({
        position: location,
        map
      }));
    } */
    this.marker = new maps.Marker({
      position: location,
      map,
      title: location.name,
      icon: `${firebaseConfig.host}/img/map-marker.png`,
    });

    for (let loc of otherLocations) {
      this.marker = new maps.Marker({
        position: loc,
        map,
        title: loc.name,
        icon: `${firebaseConfig.host}/img/map-marker.png`,
      });
    }

    /* this.circle = new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center: location,
      radius: radius * 1609
    });

    this.circle = new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center: buyer.location_2,
      radius: radius * 1609
    }); */
  };

  renderLocationMap = () => {
    const { buyer } = this.state;

    const { location } = buyer;

    let locations_added = 0;
    if (location.lat) locations_added++;
    if (buyer.location_2 && buyer.location_2.lat) locations_added++;
    if (buyer.location_3 && buyer.location_3.lat) locations_added++;
    if (buyer.location_4 && buyer.location_4.lat) locations_added++;
    if (buyer.location_5 && buyer.location_5.lat) locations_added++;

    if (location) {
      return (
        <React.Fragment>
          <h4>Primary Search Location</h4>
          <p>
            <b>{locations_added}</b> Locations are added.
          </p>
          <Map
            className="profile-google-map"
            style={{ width: "100%", height: "300px" }}
            google={this.props.google}
            zoom={3}
            initialCenter={location}
            onReady={this.onReady}
          />
          <hr></hr>
        </React.Fragment>
      );
    }
  };

  youtube_parser = (url) => {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return false;
    }
  };

  renderBuyerVideo = () => {
    const {
      buyer: { buyer_video },
    } = this.state;
    const videoID = this.youtube_parser(buyer_video || "");
    if (videoID) {
      return (
        <React.Fragment>
          <h4>Home Buyer Video</h4>
          <div className="embed-container">
            <iframe
              title={videoID}
              src={`https://youtube.com/embed/${videoID}?autoplay=0&color=white&egm=1&iv_load_policy=3&rel=0&showinfo=0&showsearch=0`}
              frameborder="0"
              allowfullscreen=""
            ></iframe>
          </div>
          <hr></hr>
        </React.Fragment>
      );
    }
  };

  renderSellingProfiles = () => {
    const { sellingProfiles } = this.state;
    if (sellingProfiles.length > 0) {
      return (
        <React.Fragment>
          <h4>My Selling Profile</h4>
          <div className="row">
            {sellingProfiles.map((p) => (
              <SellerTile key={p.id} doc={p}></SellerTile>
            ))}
          </div>
          <hr></hr>
        </React.Fragment>
      );
    }
  };

  toggleBuyerLike = async () => {
    let { likedBuyers, buyer } = this.state;
    const { user } = this.props;

    const buyerID = this.id();
    if (this.isBuyerLiked()) {
      likedBuyers = likedBuyers.filter((b) => b.id !== buyerID);
    } else {
      const {
        min_price,
        max_price,
        cities,
        purchase_month,
        purchase_year,
        bedrooms,
        bathrooms,
        sqft_min,
        sqft_max,
      } = buyer;
      likedBuyers = [
        ...likedBuyers,
        {
          id: buyerID,
          min_price,
          max_price,
          cities,
          purchase_month,
          purchase_year,
          bedrooms,
          bathrooms,
          sqft_max,
          sqft_min,
        },
      ];
    }
    const db = firebase.firestore();
    const userDoc = db.collection("builders").doc(user.uid);
    userDoc
      .set({ likedBuyers }, { merge: true })
      .then(() => {
        this.setState({ likedBuyers });
      })
      .catch(() => {
        alert("Something went wrong, please try again later");
      });
  };

  isBuyerLiked = () => {
    const buyerID = this.id();
    const { likedBuyers } = this.state;
    return likedBuyers.filter((b) => b.id === buyerID).length > 0;
  };

  renderLikeButton = () => {
    const { user } = this.props;
    if (user && user.role === "builder") {
      const starImage = this.isBuyerLiked()
        ? "/images/red_star1.png"
        : "/images/white_star1.png";
      return (
        <img
          onClick={this.toggleBuyerLike}
          alt=""
          className="likeViewIcon"
          src={starImage}
          height="32"
          width="32"
        />
      );
    }
  };

  render() {
    const {
      buyer,
      representedBy,
      lenderDetails,
      realtorEmail,
      isLoading,
    } = this.state;
    let state = "-";

    if (buyer) {
      let { states } = buyer;
      if (states) {
        states.forEach((s, i) => {
          state += s;
          if (i < states.length - 1) {
            state += ", ";
          }
        });
      }
    }
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    let realtorInfo = null;

    if (representedBy) {
      realtorInfo = (
        <RealtorInfo
          id={representedBy}
          realtorEmail={realtorEmail}
          user={this.props.user}
        />
      );
    }

    if (buyer) {
      return (
        <React.Fragment>
          <div className="properties-section-body content-area">
            <div className="container-fluid">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-1">
                  <Button
                    color="link"
                    style={{ color: "black" }}
                    onClick={this.props.history.goBack}
                  >
                    <i
                      style={{ fontSize: "35px" }}
                      className="fa fa-long-arrow-left"
                      aria-hidden="true"
                    />
                  </Button>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-lg-7 col-md-6 sellerPropImg">
                      <img
                        src={buyer.photo || "/images/face-placeholder.gif"}
                        style={{ width: "100%" }}
                        alt="buyer"
                      />
                      {this.renderLikeButton()}
                      <h4 style={{ marginLeft: 10, marginTop: -50 }}>
                        <span
                          style={{
                            backgroundColor: "#333",
                            padding: 10,
                            borderRadius: 0,
                            opacity: 0.8,
                            color: "#fff",
                            textShadow: 2,
                          }}
                        >
                          {buyer.buyer_name}
                          
                        </span>

                        { (buyer.isVerified === "true") && <span className="verified-img" title = "Verified Funds" >
                            <img
                              src="/images/verified.png"
                              alt="verified"
                              className="img-fluid"
                            />
                          </span>}
                          
                      </h4>
                      <br></br>
                      <div>
                        <h4>About the buyer</h4>
                        <p>{buyer.about_buyer || "No information"}</p>
                        <UserReviewsCount resourceId = { this.id() }/>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div style={{ padding: 15, backgroundColor: "#eee" }}>
                        <dl className="row">
                          <dt className="col-md-5" style={{fontSize: '14px'}}>Purpose</dt>
                          <dd className="col-md-7" style={{ fontSize: '14px' }}>
                            {buyer.purpose
                              ? buyer.purpose.constructor === Array
                                ? buyer.purpose
                                    .map((p) => sentencecase(p))
                                    .join(", ")
                                : sentencecase(buyer.purpose)
                              : "-"}
                          </dd>
                          <dt className="col-md-5" style={{fontSize: '14px'}}>Purchase Type</dt>
                          <dd className="col-md-7" style={{ fontSize: '14px' }}>
                            {buyer.purchase_type
                              ? buyer.purchase_type.constructor === Array
                                ? buyer.purchase_type
                                    .map((p) => sentencecase(p))
                                    .join(", ")
                                : sentencecase(buyer.purchase_type)
                              : "-"}
                          </dd>
                          <dt className="col-md-5" style={{fontSize: '14px'}}>Purchase Date</dt>
                          <dd className="col-md-7" style={{ fontSize: '14px' }}>
                            {
                              buyer.purchase_month === "Now" ? <span className="badge badge-success">
                                { buyer.purchase_month }
                              </span> : <Fragment>
                              {buyer.purchase_month}, {buyer.purchase_year} 
                              </Fragment>
                            }
                            {" "}
                            {buyer.is_date_flexible &&
                              buyer.is_date_flexible === "Yes" && (
                                <sup
                                  id="dateFlexible"
                                  className="flexible-date btn-pink-color"
                                >
                                  Flexible {this.renderTooltip('dateFlexible','Date is flexible and subject to change.')}
                                </sup>
                              )}
                          </dd>
                          <dt className="col-md-5" style={{fontSize: '14px'}}>Price Range</dt>
                          <dd className="col-md-7" style={{ fontSize: '14px' }}>
                            {currencyFormatter.format(buyer.min_price)}-
                            {currencyFormatter.format(buyer.max_price)}
                            {buyer.is_price_flexible &&
                              buyer.is_price_flexible === "Yes" && (
                                <sup
                                  id="priceFlexible"
                                  className="flexible-date btn-pink-color"
                                >
                                  Flexible {this.renderTooltip('priceFlexible','Price is flexible.')}
                                </sup>
                            )}
                          </dd>
                          <dt className="col-md-5" style={{fontSize: '14px'}}>Financing Type</dt>
                          <dd className="col-md-7" style={{ fontSize: '14px' }}>
                            {buyer.financing_type || "-"}
                          </dd>
                          {(buyer.financing_type === 'Home Loan' && buyer.loan_type !== 'Unknown') && (
                            <>
                              <dt className="col-md-5" style={{fontSize: '14px'}}>Loan Type</dt>
                                <dd className="col-md-7" style={{ fontSize: '14px' }}>
                                  {buyer.loan_type || "-"}
                              </dd>
                            </>
                          )}
                          
                        </dl>
                        <div className="row">
                          <div className="col-md-3">
                            <i className="fa fa-bed" /> {buyer.bedrooms || "-"}
                          </div>
                          <div className="col-md-3">
                            <i className="fa fa-bath" />{" "}
                            {buyer.bathrooms || "-"}
                          </div>
                          <div className="col-md-6">
                            <strong>Sq ft</strong>&nbsp;{buyer.sqft_min}-
                            {buyer.sqft_max}
                          </div>
                        </div>
                      </div>
                      {realtorInfo}
                    </div>
                  </div>
                  
                  <div className="container-fluid" style={{ fontSize: 14 }}>
                    <hr />
                    <h4 style={{ color: "rgb(255, 33, 79)", fontSize: "1rem" }}>
                      Preferences
                    </h4>
                    <div className="row">
                      <div className="col-md-4">
                        <dl className="row">
                          <dt className="col-md-5">Property Type</dt>
                          <dd className="col-md-7" >
                            {sentencecase(buyer.property_type) || "-"}
                          </dd>
                          <dt className="col-md-5">City</dt>
                          <dd className="col-md-7">
                            {buyer.cities
                              ? buyer.cities.join(", ") || "-"
                              : "-"}
                          </dd>
                          <dt className="col-md-5">Bedrooms</dt>
                          <dd className="col-md-7">{buyer.bedrooms || "-"}</dd>
                          <dt className="col-md-5">Year Built</dt>
                          <dd className="col-md-7">
                            {buyer.year_built_min}-{buyer.year_built_max}
                          </dd>
                          <dt className="col-md-5">School District</dt>
                          <dd className="col-md-7">
                            {buyer.school_district || "-"}
                          </dd>
                          <dt className="col-md-5">High School</dt>
                          <dd className="col-md-7">
                            {buyer.high_school || "-"}
                          </dd>
                          <dt className="col-md-5">Game Room</dt>
                          <dd className="col-md-7">{buyer.game_room || "-"}</dd>
                          <dt className="col-md-5">HOA</dt>
                          <dd className="col-md-7">{buyer.hoa || "-"}</dd>
                          <dt className="col-md-5">
                            Interest
                          </dt>
                          <dd className="col-md-7">
                            {
                              (buyer.interested_in_flip === "Yes") ? 'Fix & Flip, ' : '-'
                            }
                            {
                              (buyer.interested_in_buy_and_whole === "Yes") ? 'Buyer & Hold' : '-'
                            }
                          </dd>
                        </dl>
                      </div>
                      <div className="col-md-4">
                        <dl className="row">
                          <dt className="col-md-5">Style of House</dt>
                          <dd className="col-md-7">
                            {buyer.style_of_house || "-"}
                          </dd>
                          <dt className="col-md-5">States</dt>
                          <dd className="col-md-7">{state || "-"}</dd>
                          <dt className="col-md-5">Zip Codes</dt>
                          <dd className="col-md-7">
                            {buyer.zipcodes
                              ? buyer.zipcodes.join(", ") || "-"
                              : "-"}
                          </dd>
                          <dt className="col-md-5">Baths</dt>
                          <dd className="col-md-7">{buyer.bathrooms || "-"}</dd>
                          <dt className="col-md-5">Patio</dt>
                          <dd className="col-md-7">{buyer.patio || "-"}</dd>
                          <dt className="col-md-5">Elementary School</dt>
                          <dd className="col-md-7">
                            {buyer.elementary_school || "-"}
                          </dd>
                          <dt className="col-md-5">Front Door Faces</dt>
                          <dd className="col-md-7">
                            {buyer.front_door_faces || "-"}
                          </dd>
                          <dt className="col-md-5">Media Room</dt>
                          <dd className="col-md-7">
                            {buyer.media_room || "-"}
                          </dd>
                        </dl>
                      </div>
                      <div className="col-md-4">
                        <dl className="row">
                          <dt className="col-md-5">Subdivision</dt>
                          <dd className="col-md-7">
                            {buyer.subdivision || "-"}
                          </dd>
                          <dt className="col-md-5">Square Feet</dt>
                          <dd className="col-md-7">
                            {buyer.sqft_min}-{buyer.sqft_max}
                          </dd>
                          <dt className="col-md-5">Fireplace</dt>
                          <dd className="col-md-7">{buyer.fireplace || "-"}</dd>
                          <dt className="col-md-5">Study</dt>
                          <dd className="col-md-7">{buyer.study || "-"}</dd>
                          <dt className="col-md-5">Pool</dt>
                          <dd className="col-md-7">{buyer.pool || "-"}</dd>
                          <dt className="col-md-5">Middle School</dt>
                          <dd className="col-md-7">
                            {buyer.middle_school || "-"}
                          </dd>
                          <dt className="col-md-5">Garage Location</dt>
                          <dd className="col-md-7">
                            {buyer.garage_location || "-"}
                          </dd>
                          <dt className="col-md-5">Story</dt>
                          <dd className="col-md-7">{buyer.story || "-"}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <dl className="row">
                          <dt
                            className="col-md-7"
                            style={{
                              color: "rgb(255, 33, 79)",
                              fontWeight: 500,
                            }}
                          >
                            Selling Current Home
                          </dt>
                          <dd className="col-md-5">
                            {buyer.selling_current_home || "-"}
                          </dd>
                        </dl>
                      </div>
                      <div className="col-md-4">
                        <dl className="row">
                          <dt
                            className="col-md-7"
                            style={{
                              color: "rgb(255, 33, 79)",
                              fontWeight: 500,
                            }}
                          >
                            Leaseback Allowed
                          </dt>
                          <dd className="col-md-5">
                            {buyer.leaseback_allowed || "-"}
                          </dd>
                        </dl>
                      </div>
                      <div className="col-md-4">
                        <dl className="row">
                          <dt
                            className="col-md-7"
                            style={{
                              color: "rgb(255, 33, 79)",
                              fontWeight: 500,
                            }}
                          >
                            Leaseback Length
                          </dt>
                          <dd className="col-md-5">
                            {buyer.leaseback_length
                              ? buyer.leaseback_length + "weeks"
                              : "-"}
                          </dd>
                        </dl>
                      </div>
                    </div>
                    {(buyer.lender_nmls_id || buyer.lender_email) &&
                      buyer.lender_feedback && (
                        <div className="row xyp-100">
                          <a
                            href="#/"
                            data-toggle="modal"
                            data-target="#lenderModal"
                          >
                            <div className="row" style={{ padding: "20px" }}>
                              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 qualified_buyerdetail">
                                <h4>Qualified by</h4>
                                <div className="row">
                                  <div className="col-xs-6 col-sm-6">
                                    <img
                                      src={this.state.lenderLogoImage}
                                      alt={lenderDetails.lender_company}
                                      style={{
                                        width: "185px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                      }}
                                    />
                                  </div>
                                  <div className="col-xs-6 col-sm-6">
                                    <img
                                      src={
                                        this.state.lenderImage
                                          ? this.state.lenderImage
                                          : "/images/face-placeholder.gif"
                                      }
                                      alt={lenderDetails.lender_company}
                                      style={{
                                        borderRadius: "50%",
                                        width: "100px",
                                        height: "100px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                      }}
                                    />
                                  </div>
                                </div>
                                <p style={{ marginTop: "15px" }}>
                                  It is determined that the Prospective
                                  Applicant is eligible and qualified to meet
                                  the financial requirements of the loan.
                                </p>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <p>
                                  The prospective applicant has provided the
                                  following information{" "}
                                  {buyer.lender_feedback.application_format}{" "}
                                </p>
                                <p>Reviewed the following </p>
                                <div className="row">
                                  {buyer.lender_feedback.assets_reviewed && (
                                    <div className="col-sm-2">Assets</div>
                                  )}
                                  {buyer.lender_feedback.credit_reviwed && (
                                    <div className="col-sm-2">Credit</div>
                                  )}
                                  {buyer.lender_feedback.debts_reviewed && (
                                    <div className="col-sm-2">Debts</div>
                                  )}
                                  {buyer.lender_feedback.docs_reviewed && (
                                    <div className="col-sm-4">
                                      Financial Documents/Cash to Close
                                    </div>
                                  )}
                                  {buyer.lender_feedback.income_reviwed && (
                                    <div className="col-sm-2">Income</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </a>
                          <div
                            id="lenderModal"
                            className="modal"
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-body">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <div className="circular-image">
                                        <img
                                          src={
                                            this.state.lenderImage
                                              ? this.state.lenderImage
                                              : "/images/face-placeholder.gif"
                                          }
                                          alt={buyer.lender_company}
                                          className="img-fluid"
                                          style={{
                                            height: "250px",
                                            width: "250px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-7">
                                      <h5
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 0,
                                        }}
                                      >
                                        {lenderDetails.lender_name}
                                      </h5>
                                      <p style={{ fontSize: 14 }}>
                                        {lenderDetails.lender_company}
                                      </p>
                                      <p style={{ fontSize: 14 }}>
                                        NMLS: {lenderDetails.nmls_id}
                                      </p>
                                      <p style={{ fontSize: 14 }}>
                                        Email:{" "}
                                        {lenderDetails.lender_email || "-"}
                                      </p>
                                      <p style={{ fontSize: 14 }}>
                                        Phone:{" "}
                                        {lenderDetails.lender_phone || "-"}
                                      </p>
                                      <p style={{ fontSize: 14 }}>
                                        Work Phone:{" "}
                                        {lenderDetails.lender_work_phone || "-"}
                                      </p>
                                      {(() => {
                                        if (lenderDetails.lender_website_link) {
                                          return (
                                            <p style={{ fontSize: 14 }}>
                                              Website:{" "}
                                              <a
                                                style={{
                                                  wordWrap: "break-word",
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={
                                                  lenderDetails.lender_website_link
                                                }
                                              >
                                                {
                                                  lenderDetails.lender_website_link
                                                }
                                              </a>
                                            </p>
                                          );
                                        }
                                      })()}
                                      <p style={{ fontSize: 14 }}>
                                        Address: {lenderDetails.street_number},{" "}
                                        {lenderDetails.street_name},{" "}
                                        {lenderDetails.city},{" "}
                                        {lenderDetails.state},{" "}
                                        {lenderDetails.zip_code}
                                      </p>
                                      <p style={{ fontSize: 14 }}>
                                        About:{" "}
                                        {lenderDetails.lender_description ||
                                          "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    <hr/>
                    <h4>Tags</h4>

                    {((buyer ? buyer.tags: [])).map(tag => {
                        return (
                            <Badge key={tag.value} color="secondary" style={{marginRight:'5px',padding: '7px'}}>{tag.label}</Badge>
                        );
                    })}

                    <hr/>

                    {this.renderLocationMap()}
                    {this.renderBuyerVideo()}
                    {this.renderSellingProfiles()}
                    {this.renderDreamBoard()}
                    {/* {(buyer.purpose || []).map((p) => {
                      console.log(p);
                      return this.matches(p);
                    })} */}
                    {(buyer.purpose || []).includes('Purchase a home') && this.matches('sellers')}
                    <br/>
                    {(buyer.purpose || []).includes('Partner with other buyers') && this.matches('buyersMatches')}
                  </div>
                </div>
                <div className="col-md-2">
                  <a
                    href="https://agents.allstate.com/vincent-greene-mesquite-tx.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="" className="img-fluid" src="/img/ads/a7.png" />
                  </a>
                  <br />
                  <br />
                  <a
                    href="https://rodsee.wvmbarlington.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="" className="img-fluid" src="/img/ads/a4_2.png" />
                  </a>
                  <a
                    href="https://www.campbells.com/campbell-soup/chunky"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      style={{ marginTop: "10px" }}
                      alt=""
                      className="img-fluid"
                      src="/img/ads/a5.png"
                    />
                  </a>
                  <a
                    href="https://www.texaco.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      style={{ marginTop: "10px" }}
                      alt=""
                      className="img-fluid"
                      src="/img/ads/a6.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (isLoading) {
      return <Loader type="Rings" color="#ff214f" height="80%" width="100%" />;
    }

    return <PageNotFound />;
  }
}

export default GoogleApiWrapper({
  apiKey,
})(BuyerDetail);
