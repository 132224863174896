import React, { Component } from 'react'
import firebase from 'firebase/app'
import { Link } from 'react-router-dom'
import 'firebase/firestore'
import BuyerList from 'components/BuyerList'
import { getUsersBuyers } from './../Services/BuyerService';
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'reactstrap';
import qs from 'query-string';
import { firebaseConfig } from 'components/Firebase/firebase'

class Buyers extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            error: '',
            buyers: [],
            loading: true,
            showDetails: true
        }
    }

    componentWillMount() {

        const {user} = this.props;

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const { searchId } = query;

        let showDetails = true
        
        if(searchId) {
            showDetails = false;
        }

        getUsersBuyers(user, searchId).then(buyers => {
            if(this.mounted){
                const storage = firebase.storage();
                const storageRef = storage.ref();
                for (const buyer of buyers) {
                    const buyerImageRef = storageRef.child(`images/users/${buyer.id}.jpg`)
                    buyerImageRef.getDownloadURL()
                        .then(url => {
                            buyer.photo = url
                            if (this.mounted) {
                                this.setState({buyers})
                            }
                        }).catch(error => {})
                }

                if (this.mounted) {
                    this.setState({buyers, loading: false, showDetails})
                }
            }
        }).catch(error => {
            if (this.mounted) {
                this.setState({loading: false, error: error})
                console.error(error)                    
            }
        });
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    copyToClipboard = () => {
        let { user } = this.props;
        let url = firebaseConfig.host+'/share/buyers/'+user.uid;
        copy(url, {
            debug: true
          });
        toast.success('URL has been copied to your clipboard successfully.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
  
    showOnSharePage = async (buyer_id, value) => {

        const db = firebase.firestore();

        if(value === undefined) value = false;
        value = !value;
        db.collection('buyers').doc(buyer_id).update({
            show_on_share: value,
        }).then(() => {
            this.props.history.push('/buyers');
        });

        console.log('Update Buyer');
    }

    removeBuyer = (id, data) => {

        if (window.confirm('Are you sure?')) {
            const db = firebase.firestore()
            const buyerRef = db.collection('buyers').doc(id);
            buyerRef.update({
                reason:{...data},
                removed:true,
            }).then(() => {
                const {buyers} = this.state
                const newBuyers = []
                for (const buyer of buyers) {
                    if (buyer.id !== id) {
                        newBuyers.push(buyer)
                    }
                }
                if (this.mounted) {
                    this.setState({buyers: newBuyers})
                }
            }).catch(error => console.error(error))
        }
    }

    render() {
        const {loading, error, showDetails} = this.state
        const {user} = this.props
        let buyerList
        
        if (loading) {
            buyerList = 
                <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>Loading</p></td></tr>
                    </tbody>
                </table>
        }
        else {
            if (error) {
                buyerList = <table className="manage-table">
                        <tbody>
                        <tr className="responsive-table"><td><p>{error}</p></td></tr>
                        </tbody>
                    </table>
            } else {
                buyerList = <BuyerList showDetails = { showDetails } remove={this.removeBuyer} showOnSharePage={this.showOnSharePage} buyers={this.state.buyers} user={this.props.user} />
            }
        }

        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <h4>Buyers</h4>
                            </div>
                            {user.role !== 'lender' && <div style={{textAlign: 'right'}} className="col-sm-12 col-md-8">
                                    <Button
                                        style={{marginRight: '10px'}}
                                        className="btn btn-theme btn-md"
                                        onClick={this.copyToClipboard}
                                        >
                                        Share Tab
                                    </Button>
                                    
                                    <Link
                                        className="btn btn-theme btn-md"
                                        to="/profiles/buyers/new">
                                        Create Buyer
                                    </Link>
                            </div>}
                        </div>
                    </div>
                    <div className="dashboard-list">
                        <h3>Buyers</h3>
                        {buyerList}
                    </div>
                </div>
            </div>
        )
    }
}

export default Buyers
