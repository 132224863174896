import React, { Component, Fragment } from 'react';
import { Alert, Button } from 'reactstrap';
import firebase from 'firebase/app'

class UserVerificationAlert extends Component {

    state = {
        visible: true,
        isVerified: false,
        isLoading: false
    }

    onDismiss = () => {
        this.setState({
            visible: false
        });
    }

    sendVerificationEmail = () => {

        this.setState({
            isLoading: true
        }, () => {
            const user = firebase.auth().currentUser;
            const _this = this;
            user.sendEmailVerification().then(function() {
                _this.setState({
                    isVerified: true,
                    isLoading: false
                })
            }).catch(function(error) {
                // An error happened.
                console.log({
                    error
                })
            });
        })
        


    } 


    render () {
        const { visible, isVerified, isLoading } = this.state; 
        return (
            <Fragment>
                    { !isVerified ? <Fragment>
                        <Alert  color="danger" isOpen={visible} toggle={this.onDismiss}>
                            <div className="user-verify-wrapper">
                                <p>You haven't verified your email yet. Please verify your email. </p>
                                <Button onClick={ this.sendVerificationEmail }>{ isLoading ? 'Sending....' : 'Send Verifcation Link' }</Button>
                            </div>
                        </Alert>
                </Fragment> : 
                <Fragment>
                        <Alert  color="success" isOpen={visible} toggle={this.onDismiss}>
                            <div className="user-verify-wrapper">
                                <p>Verifcation email has been sent. Please your check your inbox. </p>
                                {/* <Button onClick={ this.sendVerificationEmail }>Send Verifcation Link</Button> */}
                            </div>
                        </Alert>
                </Fragment>}
            </Fragment>            
        )
    }
}


export default UserVerificationAlert;

