import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";

const blogPagesize = 10;

let lastVisible = null;

class ListPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            hasMore: true,
        }
        this.getPosts();
    }

    getPosts = async () => {
        const { posts:existingPosts } = this.state;
        const { posts } = this.state;
        const oldSize = posts.length;

        const db = firebase.firestore();

        let query = db
          .collection("posts")
          .where("isDraft", "==", false)
          .orderBy("createdOn", "desc")

        if (lastVisible) {
            query = query.startAfter(lastVisible);
        }
      
        query = query.limit(blogPagesize);

        query.get().then(({ docs: results }) => {

            if (results.length > 0) {
                lastVisible = results[results.length - 1];
            }
      
            const hasMore = results.length !== oldSize;
      
            const posts = (results || []).map(doc => {
                return {
                    id: doc.id,
                    ...(doc.data()),
                };
            });

            this.setState({posts: [...existingPosts, ...posts] , hasMore, isLoading: false });

        }).catch((error) => {
            console.error("error loading blog posts", error);
        });
    }

    renderPostCategories = (post) => {
        const { category } = post || {};
        let categories = "";
        categories = (category || []).map((e) => {
            const { label } = e || {};
            return <span className="badge badge-success">{ label }</span>

        })

        return categories;
    }

    render() {
        const {posts, hasMore} = this.state;

        if(!posts.length){
            return <Loader type="Rings" color="#ff214f" height="80%" width="100%" />;
        }

        var div = window.document.createElement("div");
        return (
            <React.Fragment>
                
                <div className="py-4">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center">
                            <div className="col-lg-11 col-md-11 col-sm-12">
                                <InfiniteScroll
                                    // className="row"
                                    pageStart={0}
                                    loadMore={() => {
                                    this.getPosts();
                                    }}
                                    hasMore={hasMore}
                                    loader={<React.Fragment key="1"/>}
                                
                                >
                                    {
                                        posts.map((m, i) => {
                                            div.innerHTML = m.body;
                                            var plainBody = div.textContent || div.innerText || "";
                                            
                                            return (
                                                    <div key={i} className='p-2'>
                                                    <div className="d-flex border">
                                                        <div style={{ flex: "0 0 50%" }}>
                                                            <div style={{ width: "100%", paddingTop: "75%", position: "relative" }}>
                                                                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                                                                    <Link to={`/blog/${m.slug}`} style={{ textDecoration: "none" }}>
                                                                        <div style={{ height: "100%", position: "relative" }}>
                                                                            <div
                                                                                style={{
                                                                                    backgroundImage: `url(${m.image})`,
                                                                                    backgroundSize: "cover",
                                                                                    backgroundPosition: "50%",
                                                                                    backgroundRepeat: "no-repeat",
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    verticalAlign: "middle",
                                                                                    cursor: "pointer"
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex single-post-wrapper">
                                                            <div className="p-5 single-post-container">
                                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                                    <div className="d-flex flex-row">
                                                                        <img alt="avatar" className="rounded-circle" src={m.author.profileImage} width="32" height="32" />
                                                                        <div className='ml-2 d-flex flex-column'>
                                                                            <div className='small'>{m.author.name}</div>
                                                                            <div className='small text-muted'>{`${ moment.unix(m.createdOn).format("DD MMMM")}  .  ${m.readTime} min`}</div>
                                                                                
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <i className="fa fa-ellipsis-v" />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <Link to={`/blog/${m.slug}`}><h3 className='mt-4 mb-3'>{m.title}</h3></Link>
                                                                    <div>
                                                                        { this.renderPostCategories(m)}
                                                                    </div>
                                                                    <p className="mb-4">{plainBody.substr(0,200)}...</p>
                                                                </div>
                                                                <hr />

                                                                {/* <div className="d-flex flex-row justify-content-between small text-muted">
                                                                    <div className="d-flex flex-row">
                                                                            <div>{(m.views || 0)} views</div>
                                                                        <div className="ml-3">Write a comment</div>
                                                                    </div>
                                                                    <div className="d-flex flex-row align-items-center">
                                                                        <span className="mr-1">1</span>
                                                                        <i className="fa fa-heart-o text-danger" />
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </InfiniteScroll>    
                            </div>
                        </div>
                    </div>
                </div>
              
                
            </React.Fragment >
        )
    }
}

export default ListPosts