import React, { Component } from "react";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal
} from "reactstrap";

class EditHistoryModal extends Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    const state = !this.state.isOpen;

    this.setState({
      isOpen: state
    });
  };

  render() {
    return (
      <React.Fragment>
        <Button
          color="primary"
          block
          onClick={this.toggle}
          className="mr-1 instant-offer-btn"
          style={{ borderColor: '#ff214f',backgroundColor:'rgb(255, 255, 255);',backgroundSize:'100% 100%',height:'80px', backgroundImage:'url(/images/instantOffer.png)',backgroundRepeat:'no-repeat' }}
          {...this.props}
        />

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className="modal-default"
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>Instant Offer</ModalHeader>
          <ModalBody>
              <p>Instant Offer allows you to close as quickly as you need and often accepts the home as-is. This service is for sellers who are in a situation where they need to sell a house fast or want a simpler, more convenient alternative to a traditional real estate process. Sellers should do their due diligence and be aware of the pros and cons of going with such an option.</p>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EditHistoryModal;
