import React from "react";
import {firebaseConfig} from './../Firebase/firebase';

const styles = {
  a: { textDecoration: "none" },
  img: { borderRadius: "50%" },
  emailContainer: {
    margin: "10px",
    backgroundColor: "#d9d9d9",
    padding: "10px 40px 10px 40px"
  },
  emailContainerP: {
    fontSize: "12px"
  },
  redContainer: {
    backgroundColor: "#FF214F",
    padding: "5px 10px 5px 10px"
  },
  redContainerA: {
    color: "black"
  },
  redContainerP: {
    color: "white",
    fontSize: "17px"
  },
  whiteContainer: {
    backgroundColor: "white",
    padding: "10px 5px 20px 5px"
  },
  whiteContainerList: {
    backgroundColor: "#d9d9d9",
    padding: "10px 5px 10px 5px"
  },
  column: {
    float: "left",
    width: "48%"
  }
};

const renderLink = (link, style = {}) => {
  if (link) {
    const { label, href } = link;
    return (
      <a style={{ ...styles.a, ...style }} href={href}>
        {label}
      </a>
    );
  }
  return null;
};

const renderProfilePicture = profilePicture => {
  if (profilePicture) {
    return (
      <img alt="Profile" style={styles.img} height="100" width="100" src={profilePicture} />
    );
  }
};

const userEmail = props => {
  const {
    heading,
    headingLink,
    profileLink,
    profilePicture,
    profileDescription,
    details,
    detailsLink
  } = props;

  let REACT_APP_BASE_REDIRECTION_PATH = firebaseConfig.host;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <title>Email</title>
      </head>
      <body>
        <div
          style={{
            ...styles.emailContainer,
            ...{ display: "grid", clear: "both" }
          }}
        >
          <img
            alt="Logo"
            style={styles.img}
            src={`${REACT_APP_BASE_REDIRECTION_PATH}/img/logos/black-logo.png`}
            className="logo"
            width="189"
            height="35"
          />

          <div style={styles.redContainer}>
            <p>{heading}</p>
            {renderLink(headingLink, styles.redContainerA)}
          </div>

          <div
            style={{
              ...styles.whiteContainer,
              ...{ display: "flex", clear: "both" }
            }}
          >
            <div style={styles.column}>
              {renderProfilePicture(profilePicture)}
              <p>
                {profileDescription} {renderLink(profileLink)}
              </p>
            </div>

            <div style={{ ...styles.whiteContainerList, ...styles.column }}>
              {details.map((d, i) => (
                <p key={i}>{d}</p>
              ))}
              <p>{renderLink(detailsLink)}</p>
            </div>
          </div>
          <p style={styles.emailContainerP}>
            By receiving this email and/or utilizing the contents. you agree to
            our&nbsp;
            <a
              style={styles.a}
              href={`${REACT_APP_BASE_REDIRECTION_PATH}/terms_of_use`}
            >
              Terms of Services.
            </a>
          </p>
        </div>
      </body>
    </html>
  );
};

export default userEmail;
