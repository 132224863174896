import axios from 'axios';

const fallBackErrorMessage = 'Something went wrong, please try again later!';
const APIKEY = '9zay-zaU9cYVLzydLc1l-w';

const subscribeToSequence = async (options) => {
    const { data, onError, onSuccess } = options;
    data.api_key = APIKEY;
    data.tags = [data.tag];
    try {

        await axios.post(`https://api.convertkit.com/v3/forms/1221080/subscribe`, data, {
            'headers': {
                'Content-Type': 'application/json',
                'charset': 'utf-8'
            }
        });

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        const { data } = error.response;
        const message = data.message || error.message || fallBackErrorMessage;
        if (onError) {
            onError(message);
        }
    }
};

const subscribe = async (options) => {
    const { data, onError, onSuccess } = options;
    
    let sourceTag = localStorage.sourceTag;
    localStorage.removeItem('sourceTag');

    const allTags = {
        realtor: [1304208],
        lender: [1304217],
        builder: [1304218],
        user: [1304216,1304215],
    };

    let tags = allTags[data.role];

    if(sourceTag){
        tags.push(sourceTag);
    }

    data.api_key = APIKEY;
    data.tags = tags;

    try {

        await axios.post(`https://api.convertkit.com/v3/forms/1218991/subscribe`, data, {
            'headers': {
                'Content-Type': 'application/json',
                'charset': 'utf-8'
            }
        });

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        const { data } = error.response;
        const message = data.message || error.message || fallBackErrorMessage;
        if (onError) {
            onError(message);
        }
    }
};

const reportPurchase = async (options) => {
    let { data, onError, onSuccess } = options;

    data = {
        ...data,
        api_key: APIKEY,
        subtotal: data.total,
        tax: 0.00,
        shipping: 0.00,
        discount: 0.00,
        status: 'paid',
        products: [
            {
                name: data.package.name,
                pid: data.package.id,
                lid: `${data.package.id}-${data.transaction_id}`
            }
        ]   
    };

    try {

        await axios.post(`https://api.convertkit.com/v3/purchases`, data, {
            'headers': {
                'Content-Type': 'application/json',
                'charset': 'utf-8'
            }
        });

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        const { data } = error.response;
        const message = data.message || error.message || fallBackErrorMessage;
        if (onError) {
            onError(message);
        }
    }
};

export { subscribe, reportPurchase, subscribeToSequence };
