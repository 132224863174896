import React, {Component} from "react";
import {Map, GoogleApiWrapper} from 'google-maps-react';
import {firebaseConfig} from './../Firebase/firebase';
const {apiKey} = firebaseConfig;

class LocationSelector extends Component {

  state = {
    location: null
  };

  static getDerivedStateFromProps(props, current_state) {
    const {location,radius} = current_state;
    const {location:newLocation, radius:newRadius} = props;
    if(newLocation && location){
      if (location.lat !== newLocation.lat || location.lng !== newLocation.lng || radius !== newRadius) {
        return {
          location:newLocation,
          radius:newRadius
        }
      }
    }

    return null
  }

  componentDidMount() {

    let {centerAroundCurrentLocation,onLocationChange, location} = this.props;

    if(location){
      onLocationChange(location);
      return this.setState({location});
    }

    if (centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          const {latitude,longitude} = pos.coords;
          let location = {
            name:`${latitude} , ${longitude}`,
            lat: latitude,
            lng: longitude,
            radius:1,
          };
          this.setState({
            location
          });
          onLocationChange(location);
        },() => {
          let location = {
            name:`40.6901095,-73.9901471`,
            lat: 40.6901095,
            lng: -73.9901471,
            radius:1
          };
          this.setState({
            location
          });
          onLocationChange(location);
        })
      }
    }
  }

  onDrag = (mapProps, map) => {
    let l = map.getCenter();
    if(l) {
      const {radius} = this.state;
      this.props.onLocationChange({
        name:`${l.lat()} , ${l.lng()}`,
        lat:l.lat(),
        lng:l.lng(),
        radius,
      });
    }
  };

  centerChanged = (mapProps, map) =>{
    const maps = mapProps.google.maps;
    let l = map.getCenter();
    let center = new maps.LatLng(l.lat(), l.lng());
    if(this.marker){
      this.marker.setPosition(center);
    }
    if(this.circle){
      this.circle.setCenter(center);
    }
  };

  onReady = (mapProps, map) => {
    this.map = map;
    const google = mapProps.google;
    this.addMarker(mapProps,map);
    google.maps.event.addListener(map,'center_changed',()=>this.centerChanged(mapProps,map));
  };

  addMarker = (mapProps, map) => {
    const maps = mapProps.google.maps;
    this.maps = maps;
    const {selectRadius} = this.props;
    const {radius,location} = this.state;
    if(!selectRadius){
       return this.marker = new maps.Marker({
        position: location,
        map
      });
    }
    this.circle = new maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map,
      center: location,
      radius: radius*1609
    });

  };

  render(){

    let {location,radius} = this.state;
    let {style} = this.props;

    if(!location) {
      location = {};
      if(!location.lat) location.lat = 40.6987112;
      if(!location.lng) location.lng = -73.9989402;
    }

    if(this.maps){
      let center = new this.maps.LatLng(location.lat, location.lng);
      if(this.marker){
        this.marker.setPosition(center);
      }
      if(this.circle){
        this.circle.setCenter(center);
        this.circle.setRadius(radius * 1609);
      }  
    }

    if(location == null){
      return <p>Loading...</p>;
    }

    return (
      <div style={{ position: 'relative', width: '107%' }}>
        <Map style={style} google={this.props.google} onReady={this.onReady} zoom={12} initialCenter={location} center={location}  onDragend={this.onDrag}/>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey
})(LocationSelector)
