import React, { Component } from 'react'
import Alert from 'components/Alert'

class ComingSoon extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            inputs: {
                password: ''
            },
            formsSubmitted: {}
        }
    }


    handleChange(e, inputId) {
        const {inputs} = this.state
        inputs[inputId] = e.target.value
        this.setState({inputs})
    }

    handleBetaPasswordSubmit(e) {
        e.preventDefault()

        if (this.state.inputs.password === 'homebeta1!') {
            this.props.enableBeta()
            this.props.history.push('/')
        }
        else {
            this.setState({error: 'Wrong password. Please try again.'})
        }
    }

    render() {
        const {error} = this.state

        let alert
        if (error) {
            alert = <Alert>{error}</Alert>
        }
        return (
            <React.Fragment>
                <div
                    style={{ backgroundImage: 'url(/images/mx4mSkK9zeo.jpg)' }}
                    className="coming-soon coming-soon-bg overview-bgi">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="coming-soon-inner">
                                        <img
                                            src="/img/logos/logo.png"
                                            alt="logo"
                                        />
                                    <h1>Beta Access</h1>
                                    <p>
                                        Please enter the password to access our beta
                                    </p>
                                    <div className="countdown styled coming-soon-counter" />
                                    <div className="clearfix" />
                                    {alert}
                                    <div className="coming-form clearfix">
                                        <form
                                            className="form-inline"
                                            onSubmit={e => {
                                                if (
                                                    !this.state.formsSubmitted
                                                        .betaSignup
                                                ) {
                                                    this.handleBetaPasswordSubmit(
                                                        e
                                                    )
                                                }
                                            }}>
                                            {this.state.formsSubmitted
                                                .betaSignup ? (
                                                <div
                                                    style={{
                                                        fontWeight: 600,
                                                        margin: '0 auto'
                                                    }}>
                                                    Thank you! We will be in
                                                    touch shortly.
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Beta password"
                                                        className="form-control"
                                                        onChange={e =>
                                                            this.handleChange(
                                                                e,
                                                                'password'
                                                            )
                                                        }
                                                        value={
                                                            this.state.inputs[
                                                                'password'
                                                            ]
                                                        }
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="btn btn-color">
                                                        Submit
                                                    </button>
                                                </React.Fragment>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ComingSoon
