import { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

class DataList extends Component {
    constructor(props, source) {
        super(props)

        this.source = source

        this.state = {
            error: '',
            docs: []
        }
    }

    componentWillMount() {
        const db = firebase.firestore()

        db.collection(this.source).where("owner", "==", this.props.user.uid).get()
            .then(snapshot => {
                if (this.mounted) {
                    const {docs} = snapshot
                    this.setState({docs})
                }
            }, error => {
                console.error(error)
            })
    }

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }
}

export default DataList
