import React, { Component } from 'react'
import firebase from 'firebase/app'
import axios from 'axios'
import 'firebase/firestore'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import copy from 'copy-to-clipboard';
import { firebaseConfig } from './../components/Firebase/firebase';
import BookingComments from '../components/BookingComments';
import BookingSubmitComment from '../components/BookingSubmitComment';

const OpenTok = require('opentok'),
    opentok = new OpenTok('46494562', '5ff86b7050a2edd2cf6af44454fc32bde05c034f');

class LiveStreamChannel extends Component {
    constructor(props) {
        super(props);

        const { user } = this.props;
        const { full_name, realtor_name, lender_name, builder_name } = user;

        this.state = {
            error: '',
            loading: true,
            isTwoWayVideo: true,
            liveStreamChannel: null,
            apiKey: '46494562',
            cameraPosition: localStorage.cameraPosition || 'environment',
            publisher: null,
            subscriber: null,
            speaker: true,
            mic: true,
            startStream: true,
            bookingId: this.props.user.uid,
            name: full_name || realtor_name || lender_name || builder_name
        }

        this.publisherProperties = {
            showControls: true,
            facingMode: this.state.cameraPosition,
            mirror: false
        };

        this.otPublisher = React.createRef();
        this.otSubscriber = React.createRef();
    }

    componentWillMount() {
        this.starAndStoreSession();
    }

    destroyComments = async () => {

        const { bookingId } = this.state;
        const { user: { uid } } = this.props;

        await firebase.database().ref(`/BookingComments/${bookingId}`).remove();

        const { cloudFunctionHost } = firebaseConfig;

        const { liveStreamChannel: {
            sessionId
        }, createdAt } = this.state;

        const timestamp = new Date().getTime() / 1000;

        console.log(createdAt, timestamp);

        await axios({
            method: 'POST',
            url: `${cloudFunctionHost}/destroySession`,
            data: {
                sessionId,
                event: 'streamDestroyed',
                stream: {
                    createdAt,
                },
                timestamp,
                userId: uid,
            }
        });

        this.setState({ startStream: false })

    }

    starAndStoreSession = async () => {

        const { isTwoWayVideo } = this.state;
        const { user } = this.props;
        const db = firebase.firestore();

        const { liveStreamChannel, uid } = user;
        let baseUnix = new Date().getTime() / 1000;
        let createdAt = baseUnix;

        if (liveStreamChannel) {
            const { expiry: exp } = liveStreamChannel;
            if ((exp || 0) > baseUnix) {
                liveStreamChannel.isTwoWayVideo = isTwoWayVideo;
                await db.collection('users').doc(uid).set({
                    liveStreamChannel,
                    createdAt
                }, { merge: true });
                return this.setState({ liveStreamChannel, createdAt });
            }
        }

        opentok.createSession(async (err, { sessionId }) => {
            if (err) {
                return console.log(err);
            }

            let token = opentok.generateToken(sessionId);

            const expiry = baseUnix + (23 * 60 * 60); // 23 hours

            const liveStreamChannel = {
                sessionId,
                token,
                expiry,
                isTwoWayVideo,
            };

            this.setState({
                liveStreamChannel, createdAt
            });

            await db.collection('users').doc(user.uid).set({
                liveStreamChannel,
            }, { merge: true });
        });
    }

    copyStreamURL = () => {
        const { uid } = this.props.user;
        const url = `${firebaseConfig.host}/LiveStreamChannel/${uid}`;
        copy(url);
        toast.success('URL has been copied to your clipboard successfully.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    toggleMic = () => {
        const { otPublisher } = this;
        if (otPublisher) {
            const { current } = otPublisher;
            if (current) {
                const publusher = current.getPublisher();
                const { mic } = this.state;
                if (publusher) {
                    publusher.publishAudio(!mic);
                    this.setState({
                        mic: !mic
                    });
                }
            }
        }
    }

    toggleSpeaker = () => {
        const { otSubscriber } = this;
        if (otSubscriber) {
            const { current } = otSubscriber;
            if (current) {
                const subscriber = current.getSubscriber();
                if (subscriber) {
                    const { speaker } = this.state;
                    subscriber.subscribeToAudio(!speaker);
                    this.setState({
                        speaker: !speaker
                    });
                }
            }
        }
    }

    switchCamera = () => {
        const { cameraPosition } = this.state;
        const pos = cameraPosition === 'environment' ? 'user' : 'environment'
        this.setState({
            cameraPosition: pos
        });
        localStorage.cameraPosition = pos;
        window.location.reload();
    }

    switchVideoType = () => {
        const { isTwoWayVideo } = this.state;
        this.setState({
            isTwoWayVideo: !isTwoWayVideo,
        }, this.starAndStoreSession);
    }

    render() {
        const { liveStreamChannel, apiKey, speaker, mic, cameraPosition, startStream, isTwoWayVideo } = this.state;
        let sessionId = liveStreamChannel ? liveStreamChannel.sessionId || '' : '';
        let token = liveStreamChannel ? liveStreamChannel.token || '' : '';
        console.log('this.props.match :>> ', this.props.match);
        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div style={{ margin: '0 auto', padding: '20px', textAlign: 'center' }}>
                        <h4>Your Personal Live Stream Channel</h4>

                        <p>Two Way Video</p>

                        <div style={{ marginBottom: '10px' }}>
                            <Switch onChange={this.switchVideoType} checked={isTwoWayVideo} />
                        </div>

                        {(!(liveStreamChannel && startStream)) && <p>Stream ended Successfully</p>}

                        {(liveStreamChannel && startStream) && <>

                            <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>

                                <div className="video-main">

                                    {(isTwoWayVideo) && (
                                        <OTStreams>
                                            <OTSubscriber ref={this.otSubscriber} properties={{
                                                showControls: false
                                            }} />
                                        </OTStreams>
                                    )}

                                    <OTPublisher
                                        properties={{
                                            showControls: false,
                                            facingMode: cameraPosition,
                                            mirror: false
                                        }}
                                        ref={this.otPublisher}
                                        className={isTwoWayVideo ? 'custom-video' : ''}
                                    />

                                    <div className="live-comment-setion">
                                        <BookingComments bookingId={this.state.bookingId} name={this.state.name} match={this.props.match} />
                                        <div className="call-controls d-flex justify-content-between align-items-center">

                                            <div className="d-flex align-items-center flex-grow-1">
                                                <BookingSubmitComment bookingId={this.state.bookingId} name={this.state.name} match={this.props.match} />
                                            </div>

                                            <div className="flex-fill justify-content-end">
                                                <ul className="d-flex justify-content-center align-items-center cntroll-call-btns">
                                                    <li>
                                                        <btn onClick={this.switchCamera}>
                                                            <i className="fa fa-camera"/>
                                                        </btn>
                                                    </li>
                                                    <li className="end-call-btn">
                                                        <btn onClick={this.destroyComments} >
                                                            <i className="fa fa-phone"/>
                                                        </btn>
                                                    </li>
                                                    {(isTwoWayVideo) && (
                                                        <li>
                                                            <btn onClick={this.toggleSpeaker}>
                                                                {speaker && <i className="fa fa-volume-up"/>}
                                                                {!speaker && <i className="fa fa-volume-off"/>}
                                                            </btn>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <btn onClick={this.toggleMic}>
                                                            {mic && <i className="fa fa-microphone"/>}
                                                            {!mic && <i className="fa fa-microphone-slash"/>}
                                                        </btn>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </OTSession>

                            <div className="video-controls">
                                <button className="btn btn-xs btn-success" onClick={this.copyStreamURL}>Copy URL</button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        )
    }
}

export default LiveStreamChannel
