import React, { Component } from 'react'
import PartitionedForm from '../components/PartitionedForm'
import LocationModal from 'components/Location/LocationModal'
import SearchAutoComplete from 'components/Common/SearchAutoComplete';

export const searchFields = {
    purpose: { 
        width: 4,
        multiselect: true,
        options: ['Partner with other buyers', 'Purchase a home', 'Both']
    },
    purchase_type: { 
        width: 4,
        multiselect: true,
        required: true,
        options: ['Primary Residence', 'Vacation', 'Second Home', 'Investment']
    },
    property_type: { 
        width: 4,
        multiselect: true,
        required: true,
        options: ['Condo', 'Townhome', 'Single-family', 'Farm/ranch', 'Half-duplex']
    },
    purchase_month: { 
        width: 6,
        label:'For Sale Month',
        placeholder:'Any',
        options: ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec']
    },
    purchase_year: { 
        width: 6,
        label:'For Sale Year',
        options: [2020, 2021, 2022, 2023]
    },
    min_price: {
        width: 6,
        type: 'number',
        placeholder: '20000',
    },
    max_price: {
        width: 6,
        type: 'number',
        placeholder: '200000',
    },
    subdivision: {},
    zipcodes: {
        creatable: true,
        multiselect: true,
        options: []
    },
    stories: {
        width: 2,
        options: ['1', '1+','2+','3+']
    },
    bedrooms: {
        width: 6,
        options: ['1+','2+','3+','4+','5+']
    },
    bathrooms: {
        width: 6,
        options: ['1+','2+','3+','4+','5+']
    },
    expected_updates: {},
    recent_updates: {},
    year_built_min: { 
        label: 'Built after',
        type: 'number',
        width: 6,
        placeholder: `e.g. 1900`,
        min: 1900,
    },
    year_built_max: { 
        label: 'Built before',
        type: 'number',
        width: 6,
        placeholder: `e.g. 2020`,
        max: new Date().getFullYear() + 5,
    },
    leaseback_allowed: { 
        options: ['Yes', 'No'],
        width: 2,
    },
    leaseback_length: { 
        isVisible: (state) => state.inputs.leaseback_allowed === 'yes',
        options: ['1','2','3','4+']
    },
    foundation_type: {},
    address: {},
    pool: { 
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    fireplace: { 
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    study: { 
        options: ['Not Required', 'Yes', 'No'],
        width: 2,
    },
    patio: { 
        options: ['Not Required', 'Uncovered', 'Covered'],
        width: 2,
    },
    selling_current_home: {
        options: ['Yes', 'No'],
    },
    school_district: { 
        width: 4
    },
    elementary_school: {},
    middle_school: {},
    property_description: {},
    high_school: {},
    division: {},
    buyer_email: { 
        width: 4,
        required: true,
        type: 'email',
    },
    buyer_phone: { 
        width: 4,
        required: true,
        type: 'phone',
    },
    buyer_name: { 
        width: 4,
        required: true
    },
    about_buyer: {
        type: 'textblob',
        label: 'About the buyer'
    },
    sqft_min: {
        type: 'number',
        width: 6,
    },
    sqft_max: {
        type: 'number', 
        width: 6,
    },
    acres: {
        options: ['Less than .5 acres (not zero)', '0.5-0.99 acres', '1-2.99 acres', '3-4.99 acres', '5-9.99 acres', '10-49.99 acres', '50-99.99 acres', 'Over 100 acres', 'Condo/Townhome Lot', 'Zero Lot'],
    }
}



export default class FilterBox extends Component {
    constructor(props) {
        super(props)
        const inputs = {}
        const {filters, title} = props

        const sections = [
            {   
            name: title || 'Search Homes and Buyers', 
            // fields: ['search','location','distance','purchase_month', 'purchase_year', 'min_price', 'max_price', 'bedrooms', 'bathrooms'],
            fields: ['search','location','distance', 'min_price', 'max_price', 'bedrooms', 'bathrooms'],
            additional: ['sqft_min', 'sqft_max', 'year_built_min', 'year_built_max']
            }
        ];
        
        for (const field of sections.map(s => s.fields.concat(s.additional || [])).flat()) {
            inputs[field] = filters ? filters[field] || '' : ''
        }
        // inputs.distance = 5;

        this.state = {
            inputs,
            sections
        }
    }

    setValue(inputId, value) {
        const inputs = Object.assign({}, this.state.inputs)
        inputs[inputId] = value;
        this.setState({inputs});
    }

    componentDidUpdate(prevProps, prevState) {
        const {filters} = this.props

        if (filters !== prevProps.filters) {
            this.setState({inputs: filters})
        }
    }

    buttonText() {
        return 'View ALL Results'
    }

    onLocationChange = (location) => {
        const {inputs} = this.state;
        const newInputs = {
            ...inputs,
            location: {...location}
        };
        this.setState({
            inputs: newInputs
        });
    }

    onSearchChange = (searches) => {
        const {inputs} = this.state;
        
        const newInputs = {
            ...inputs,
            searches
        };
        this.setState({
            inputs: newInputs
        });   
    }
    

    render() {
        const {inputs:{location}, sections} = this.state;
        const formFields = {
            ...searchFields,
            search: {
                type:'custom',
                width: 12,
                jsx: <SearchAutoComplete name="search" onChange={this.onSearchChange} />,
            },
            location: {
                h3:'Map Search',
                type:'custom',
                label:'Location',
                width:8,
                jsx: (
                    <LocationModal
                        addSearch
                        location={location}
                        placeholder="Location"
                        centerAroundCurrentLocation 
                        onLocationChange={this.onLocationChange}
                        style={{ width: '93%', height: '320px', marginTop:'10px' }}
                        modalStyle={{ width: '100%', height: '400px' }}
                    />
                ),
            },
            distance: {
                type: 'number',
                title: 'Search Radius in miles',
                placeholder:'i.e 5',
                label:'Radius',
                width: 4,
            }
        };
        return <form className="back-color" method="GET" onSubmit={e => { e.preventDefault(); this.props.commitSearch(this.state.inputs); }}>
            <PartitionedForm fields={formFields} sections={sections} setValue={this.setValue.bind(this)} values={this.state.inputs} />
            <div className="form-group mb-0" style={{padding: '0 20px 20px 20px'}}>
                <button className="search-button">{this.buttonText()}</button>
            </div>
        </form>
    }
}