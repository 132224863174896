import React from "react";
import {firebaseConfig} from './../Firebase/firebase'

const styles = {
  a: { textDecoration: "none" },
  img: { borderRadius: "50%" },
  emailContainer: {
    margin: "10px",
    backgroundColor: "#d9d9d9",
    padding: "10px 40px 10px 40px"
  },
  emailContainerP: {
    fontSize: "12px"
  },
  redContainer: {
    backgroundColor: "#FF214F",
    padding: "10px 20px 10px 20px"
  },
  redContainerA: {
    color: "black"
  },
  redContainerP: {
    color: "white",
    fontSize: "17px"
  },
  whiteContainer: {
    backgroundColor: "white",
    padding: "20px"
  },
  
  whiteContainerImg: {
    display: 'block',
    margin: 'auto'
  },
  whiteContainerUlLi: {
    'padding': '5px',
    'fontSize': '17px'
  },
  p: {
    'fontSize': '17px',
    'marginBottom': '30px',
  }
};

const renderLink = (link, style = {}) => {
  if (link) {
    const { label, href } = link;
    return (
      <a style={{ ...styles.a, ...style }} href={href}>
        {label}
      </a>
    );
  }
  return null;
};

const renderPicture = picture => {
  if (picture) {
    return (
      <img alt="" style={{...styles.img,...styles.whiteContainerImg}} height="100" width="100" src={picture} />
    );
  }
};

const BuyerInviteEmailFromLender = props => {
  const {
    heading,
    headingLink,
    companyPicture,
    profilePicture,
    profileDescriptionZero,
    profileDescription,
    profileDescriptionTwo,
    list,
    listDetails,
    centerContent,
    button
  } = props;

  let host = firebaseConfig.host;

  let extraStyles = {};

  if(centerContent){
    extraStyles = {
      textAlign: 'center',
      margin: '0 auto',
    }
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <title>Email</title>
      </head>
      <body>
        <div
          style={{
            ...styles.emailContainer,
            ...{ display: "grid", clear: "both" }
          }}
        >
          <img
            alt="Logo"
            style={styles.img}
            src={`${host}/img/logos/black-logo.png`}
            className="logo"
            width="189"
            height="35"
          />

          {(heading) && (
            <div style={styles.redContainer}>
              <p style={styles.redContainerP}>{heading}</p>
              {renderLink(headingLink, styles.redContainerA)}
            </div>
          )}

          <div
            style={{
              ...styles.whiteContainer,
              ...{ display: "flex", clear: "both" }
            }}
          >
            <div style={extraStyles}>
              
              {(profileDescriptionZero) && (
                  <>
                    <p style={styles.p}>{profileDescriptionZero}</p>
                    <hr/>
                  </>
              )}

              {(profilePicture) && <div style={{paddingBottom:'10px'}}>{renderPicture(profilePicture)}</div>}
              
              {(companyPicture) && <div style={{paddingBottom:'10px'}}>{renderPicture(companyPicture)}</div>}
              
              {(profileDescription) && (
                <p style={styles.p}>  
                  {profileDescription}
                </p>
              )}

              {(profileDescriptionTwo) && (
                <p style={styles.p}>{profileDescriptionTwo}</p>
              )}

              {(button) && (
                <a
                  style={{
                    backgroundColor: "#ff214f",
                    color: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    textDecoration: "none",
                    marginBottom: '20px',
                  }}
                    href={button.link}
                  >
                  {button.text}
                </a>
              )}

              {list && (
                <ul>
                  {list.map((d, i) => (
                      <li style={styles.whiteContainerUlLi} key={i}>{d}</li>
                  ))}
                </ul>
              )}

              {(listDetails) && <p style={styles.p}>{listDetails}</p>}

            </div>

          </div>
          <p style={styles.emailContainerP}>
            By receiving this email and/or utilizing the contents. you agree to
            our&nbsp;
            <a
              style={styles.a}
              href={`${host}/terms_of_use`}
            >
              Terms of Services.
            </a>
          </p>
        </div>
      </body>
    </html>
  );
};

export default BuyerInviteEmailFromLender;
