import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import AsyncSelect from 'react-select/lib/Async';
import firebase from "firebase/app";
import "firebase/firestore";

import {
    Button,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal,
} from "reactstrap";

import SubBanner from 'components/SubBanner';
import HelpCenter from 'components/HelpCenter';
import FAQ from '../components/FAQ';

class Help extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            status:'Pending',
            category:'',
            subject: '',
            message: '',
            tutorials: [],
            faqs: [],
            categories: [
                'Seller',
                'Lender',
                'Buyer',
                'Offers',
                'Video Stream',
                'Tours'
            ],
            isOpen: false,
            modalTutorial: undefined,
            selectedTutorial: undefined,
        };

        const db = firebase.firestore();
        db.collection('tutorials').where('showOnPage', '==',true).orderBy('displayOrder').get().then(snap => {
            const tutorials = snap.docs.map(d => {
                return {
                    id: d.id,
                    ...d.data(),
                }
            });
            const notFAQS = tutorials.filter(t => !t.isFAQ);
            const faqs = tutorials.filter(t => t.isFAQ);
            this.setState({tutorials: notFAQS, faqs});
        });
    }

    handleSelectChange = (key, value) => {
        this.setState({ [key]: value });
    };

    onChange = ({target}) => {
        const {value,name} = target;
        this.setState({[name]: value});
    }

    handleTutorialAction = (tutorial) => {
        if(tutorial.isYoutube || tutorial.isFAQ){
            return this.setState({
                modalTutorial: tutorial,
                isOpen: true,
            });
        }
        window.open(tutorial.link, "_blank");
    }
    
    toggle = () => {
        const state = !this.state.isOpen;
        this.setState({
          isOpen: state
        });
    };

    youtube_parser = (url) => {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
          return match[2];
        } else {
          return false;
        }
    }

    onSelectChange = (selectedTutorial) => {
        this.setState({
            selectedTutorial
        }, () => {
            this.handleTutorialAction(selectedTutorial.value);
        });
    }


    get = async (value, callback) => {
        try {
          const db = firebase.firestore();
          const searchValue = value.toLowerCase();
          const snap = await db.collection("tutorials").where("search", "array-contains", searchValue).get();
          const results = snap.docs.map(d => {
            const data = d.data();
            return {
              label: data.title,
              value: data,
            };
          });
          callback(results);
        } catch (error) {
          console.log({ error });
        }
    };

    render() {

        const {tutorials, isOpen, modalTutorial, selectedTutorial, faqs} = this.state;
        
        let singleTutorial;
        
        if(tutorials.length > 0){
            singleTutorial = tutorials[0];
        }

        const allTutorials = tutorials.filter((tut, index) => index !== 0);

        return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    toggle={this.toggle}
                    className="modal-danger modal-lg"
                    >
                    {modalTutorial && (
                        <>
                    <ModalHeader toggle={this.toggle}>{modalTutorial.title}</ModalHeader>
                        <ModalBody>
                            <p>{modalTutorial.description}</p>
                            {(modalTutorial.isYoutube) &&
                            <>
                                <hr/>
                                <div className="embed-container">
                                    <iframe title="Tutorial" src={`https://youtube.com/embed/${this.youtube_parser(modalTutorial.link || '')}?autoplay=1&color=white&egm=1&iv_load_policy=3&rel=0&showinfo=0&showsearch=0`} frameBorder="0" allowFullScreen="1"></iframe>
                                </div>
                            </>
                            }
                        </ModalBody>
                    </>
                    )}
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>
                        Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                            
                <SubBanner
                    {...this.props}
                >
                    <div
                    className="sub-banner overview-bgi help-banner" style={{background:'azur'}}>
                    <div className="container">
                        <div className="breadcrumb-area">
                            <h1>How can we help?</h1>
                            <p style={{color:'white'}}>Search for help from our documentation, community, and marketplace.</p>
                            <div className="banner-dropdown">
                                <div className="form-group">

                                    <AsyncSelect
                                        value={selectedTutorial}
                                        placeholder="What are you looking for?"
                                        cacheOptions
                                        loadOptions={this.get}
                                        defaultOptions
                                        onChange={this.onSelectChange}
                                        isMulti={false}
                                    />
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </SubBanner>
                <div className="container">
                    <div className="getting-start-section">
                        { singleTutorial  && (
                            <div className="row getting-start-header">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <img src="/img/location-icon.png" alt="" />
                                        <div className="start-heading-text">
                                            <h3>{singleTutorial.title}</h3>
                                            <p>{singleTutorial.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-end">
                                        <div className="help-video-div" onClick={() => this.handleTutorialAction(singleTutorial)}>
                                            <img src="/img/mqdefault.jpg" alt="" />
                                            <btn className="play-icon" >
                                                <i className="fa fa-play-circle"></i>
                                            </btn>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )}
                        <div className="help-files-list">
                            <ul className="d-flex row">
                                {allTutorials.map((tut, index) => (
                                    <li className="col-md-6" key={index}>
                                        <btn onClick={() => this.handleTutorialAction(tut)}>
                                            <div className="d-flex align-items-center">
                                                <div className="file-icon d-flex align-items-center">
                                                    <i className="fa fa-file"></i>
                                                </div>
                                                <div className="file-text">
                                                     <h5>{tut.title}</h5>
                                                     <p>{tut.description}</p>
                                                </div>
                                                <div className="right-arrow-icon m-l-auto">
                                                    <i className="fa fa-chevron-right"></i>
                                                </div>
                                            </div>
                                        </btn>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="question-section">
                    <div className="container">
                        <div className="question-section-inner">
                            <Link to="/faqs"><h2>Frequently Asked Questions</h2></Link>
                            {faqs.map((f,index) => {
                                return (
                                    <FAQ key={index} term={f.title} defination={f.description}/>
                                );
                            })}
                            <Link to="/faqs"><p style={{marginTop:'30px'}}>See full list of FAQs here</p></Link>
                        </div>
                    </div>
                </div>
                <div className="question-section">
                    <div className="container">
                        <div className="question-section-inner">
                        <h2>Need More Help?</h2>
                            <HelpCenter {...this.props} />
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

export default Help
