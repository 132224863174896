import React, { Component } from 'react'
import SubBanner from 'components/SubBanner'
import AboutRealEstate from 'components/AboutRealEstate'
import HelpCenter from 'components/HelpCenter'

class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    componentWillMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <React.Fragment>
                <SubBanner
                    title="About Us"
                    image="/images/JIUjvqe2ZHg.jpg"
                    {...this.props}
                />
                <AboutRealEstate {...this.props} />
                <HelpCenter {...this.props} />
            </React.Fragment>
        )
    }
}

export default About
