import React, { Component } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Row, Col } from "reactstrap";
import moment from "moment";
import OfferDefinationCollapse from "./OfferDefinationCollapse";
import { sendAcceptOfferEmail, sendRejectOfferEmail } from "./OfferService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class OfferDetails extends Component {
  state = {
    definations: [
      {
        term: "What is Purchase Price?",
        defination: "This is the price offered for your home by the buyer.",
        open: true
      },
      {
        term: "What is Finance Type?",
        defination:
          "Finance Type is the type of finance the buyer is using to purchase the home. Finance Type can range from Cash, Conventional, FHA, VA, USDA, and sometimes Seller Finance."
      },
      {
        term: "What is Down Payment?",
        defination:
          "Down Payment is the amount the buyer is putting down on the home as their initial up-front partial payment to purchase. This is paid at the time of finalizing the transaction."
      },
      {
        term: "What is a Seller Concession?",
        defination:
          "Seller Concessions, also known as Seller Contributions, is an amount the buyer is requesting the seller contribute towards their transaction. The buyer is required to pay for certain home financing costs but anagreement can be made between the buyer and seller where the seller pays for those e cost on behalf ofthe buyer."
      },
      {
        term: "What is Earnest Money Deposit?",
        defination:
          "Earnest Money Deposit is a good-faith deposit into an escrow account with the title company. It is aspecific form of security deposit made as part of entering into a real estate transaction. This amount implies that the buyer is serious about purchasing and is willing to demonstrate an amount as their goodfaith to complete transaction. This amount can be 1% - 5% of the agreed upon purchase price."
      },
      {
        term: "What is Title Policy?",
        defination:
          "The title policy ensures that the property isn’t encumbered by past liens or other problems. This expenseis customarily paid by the buyer or seller based on the state of where the transaction is taken place. Theamount varies widely from state to state and depends on the price of the home."
      },
      {
        term: "What is a Survey?",
        defination:
          "The survey expense is the cost to hire a land surveyor. The survey effectively gives you a legal description of the exact boundaries of where your property line begins and end. This cost can range from $350 to $700."
      },
      {
        term: "What is a Residential Service Contract?",
        defination:
          "A Residential service contract, also known as Home Warranty, covers the repair or replace of major mechanical systems and appliances in the home. These systems include heating, electrical, and plumbing.The cost varies based on the type of service coverage plan."
      },
      {
        term: "What is Closing Date?",
        defination:
          "When it comes to selecting a closing date, you want to be as comfortable as possible. Closing date canvary based on your situation or need. If you are purchasing a new construction your closing date may be extended to accommodate the transaction the closing. Choosing you own closing just might be the control you need. You can even stay in your home after closing with extended stay option. Be sure to ask all the questions to make your process as comfortable as possible."
      },
      {
        term: "What is Leaseback Days?",
        defination:
          "Leaseback allows a seller to stay in the house after closing for an agreed upon period of time. The seller becomes the tenant and the buyers becomes the landlord. A leaseback can be negotiated at no cost to thetenant up to the daily amount of the buyer’s new monthly mortgage payment. There is a temporary residential lease agreement that is signed, legally binding, and enforced."
      },
      {
        term: "About Possession",
        defination:
          "Possession can be taken by the buyer upon closing and funding or after the temporary lease agreement."
      },
      {
        term: "About Option Fee and Option Days",
        defination:
          "The Option Fee (paid to seller) and Option Day, also known as Option Period, gives the buyer a time period to do their inspections on the home. During this process repairs will typically be asked for items that may materially affect the purchase or sell of the home. Items such as the roof, HVAC, foundation,flooring, electrical, plumbing and appliance could be requested for repair. If any repairs are required youwill receive a copy of the inspection report and a list of repairs. This option period gives the buyer the unrestricted right to terminate the contract if both parties cannot agree on repair terms. Having this right allows the buyer to retain their Earnest Money Deposit if the contract is terminated."
      },
      {
        term: "What is Credit Approval?",
        defination:
          "If the Buyer cannot obtain Buyer Approval, Buyer may give written notice to Seller within a specific number of day after the effective date of the contract and this contract will terminate and the earnest money will be refunded to the Buyer. If the Buyer does not terminate the contract under this provision,the contract shall no longer be subject to the Buyer obtaining Buyer Approval."
      },
      {
        term: "What is Non-Realty Item?",
        defination:
          "Non-Realty Item is used if the parties need to add additional items of personal property that are to staywith the property. Items such refrigerator, washer and dryer are transferred using a Non-Realty Item addendum."
      },
      {
        term: "What is Sale of Other Property?",
        defination:
          "A Sale of Other Property addendum is used if the Buyer will be unable to buy the new property unless their existing property is sold and closed."
      },
      {
        term: "What are Commission?",
        defination:
          'Real estate commission are negotiated and variable amount based on sales price. The commission is made directly to the real estate brokers for services rendered in the sale or purchase of real property.'
      },
      {
        term: "Estimated Net Offer",
        defination:
          "*Other Fees to the transaction may include title policy, escrow, estimated taxes, etc. Have questions, need clarification, or would like a seller net sheet for an itemized breakdown of fees click here."
      }
    ]
  };

  renderPropertyField = (name, value, hideLineBreak) => {
    return (
      <Row>
        {!hideLineBreak && <div className="line-break" />}
        <Col>
          <h3>{name}</h3>
        </Col>
        <Col>
          <h3 style={{ textAlign: "end" }}>{value}</h3>
        </Col>
      </Row>
    );
  };

  back = () => {
    this.props.history.push("/offers");
  };

  acceptOffer = async () => {
    const { offer } = this.props;
    const message = await sendAcceptOfferEmail({ offer });
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  counterOffer = () => {
    const { offer, history } = this.props;
    history.push(`/offers/update/${offer.id}`);
  };

  declineOffer = async () => {
    const { offer } = this.props;
    const message = await sendRejectOfferEmail({ offer });
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {
    const { definations } = this.state;
    const { offer, user } = this.props;
    const { uid } = user;
    const {
      address,
      offerExpires,
      price,
      buyerName,
      financeType,
      downPayment,
      financeAmount,
      finance,
      earnestMoney,
      titlePolicyExpense,
      surveyExpense,
      serviceContract,
      possession,
      closingDate,
      possessionDays,
      optionFee,
      optionDays,
      nonRealtyItems,
      saleOfOtherProperty,
      creditApprovalDays,
      comments,
      image,
      sender
    } = offer;
    const commission = 0.06 * price;
    const expires = moment(offerExpires);
    const tz = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    return (
      <Card style={{ margin: "10px" }}>
        <CardBody>
          <div style={{ marginLeft: "25px" }}>
            <CardTitle style={{ maxWidth: "200px", fontWeight: "500" }}>
              {address}
            </CardTitle>
            <p style={{ color: "grey" }}>
              This offer expires on{" "}
              {expires.format("dddd MMMM Do, YYYY [at] h:mm")} {tz} ({" "}
              {expires.fromNow()} )
            </p>
          </div>
          {this.renderPropertyField("Buyer Name", buyerName, true)}
          {this.renderPropertyField("Purchase Price", `$${price}`, true)}
          {this.renderPropertyField("Finance Type", financeType)}
          {financeType !== "Cash" &&
            this.renderPropertyField("Down Payment", `$${downPayment}`)}
          {financeType !== "Cash" &&
            this.renderPropertyField("Finance Amount", `$${financeAmount}`)}
          {this.renderPropertyField("Seller Concession", `$${finance}`)}
          {this.renderPropertyField(
            "Earnest Money Deposit",
            `$${earnestMoney}`
          )}
          {this.renderPropertyField("Title Policy Expense", titlePolicyExpense)}
          {this.renderPropertyField("Survey Expense", surveyExpense)}
          {this.renderPropertyField("Service Constract", `$${serviceContract}`)}
          {this.renderPropertyField("Closing Date", closingDate)}
          {this.renderPropertyField("Leaseback Days", `${possessionDays} days`)}
          {this.renderPropertyField("Possession", possession)}
          {this.renderPropertyField(
            "Option Fee and Option Days",
            `$${optionFee}, ${optionDays} days`
          )}
          {financeType !== "Cash" &&
            this.renderPropertyField(
              "Number of Days for Credit Approval",
              `${creditApprovalDays} days`
            )}
          {this.renderPropertyField("Non Realty Item", nonRealtyItems)}
          {this.renderPropertyField(
            "Sale of Other Property",
            saleOfOtherProperty
          )}
          <h3>Other Comments Relevant to the Offer</h3>
          <p
            style={{
              marginLeft: "25px",
              backgroundColor: "#EFF0F1",
              padding: "15px",
              marginRight: "25px"
            }}
          >
            {comments}
          </p>
          {this.renderPropertyField(
            "Commission (Example 6 %)",
            `$${parseInt(commission)}`
          )}

          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px"
            }}
          >
            <p
              style={{
                backgroundColor: "#ff214f",
                color: "white",
                padding: '10px'
              }}
            >Real estate commission are negotiated and variable amount based on sales price. The commission is made directly to the real estate brokers for services rendered in the sale or purchase of real property.</p>
            
          </div>


          <Row>
            <Col>
              <h3>Estimated Net Offer</h3>
            </Col>
            <div className="line-break" />
            <Col>
              <h3 style={{ textAlign: "end", color: "#ff214f" }}>
                ${parseInt(price - commission - finance)}
              </h3>
            </Col>
          </Row>
          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px"
            }}
          >
            <h3 style={{ paddingLeft: "0px" }}>Pre Approval Letter </h3>

            <a
              className="btn-theme"
              href={image}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>

            <h3
              style={{
                marginTop: "20px",
                backgroundColor: "#ff214f",
                color: "white"
              }}
            >
              Important!
            </h3>
            <p style={{ backgroundColor: "#EFF0F1", padding: "20px" }}>
              The Buyer will not be responsible for closing cost that are
              typically asessed by the seller in the state where theproperty is
              located. The Seller is responsibile for their own fees and should
              do their research before accepting. <br /><br />
              You have the right to consult with an attorney to review transaction documents. Please contact an attorney for legal advise.
            </p>

            <p>Please review all information carefully before consideration. If you have any questions, please contact your agent or Homematchx Offer Advisor.</p>
            <Row>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    backgroundColor: "#EFF0F1",
                    borderColor: "#EFF0F1",
                    color: "black"
                  }}
                  onClick={this.back}
                >
                  Back
                </Button>
              </Col>
              {(sender !== uid) && (
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button className="btn-theme" onClick={this.acceptOffer}>
                    Accept Offer
                  </Button>
                </Col>
              )}
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button className="btn-theme" onClick={this.counterOffer}>
                  {sender !== uid ? 'Counter Offer' : 'Resubmit Offer'}
                </Button>
              </Col>
              {(sender !== uid) && (
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button className="btn-theme" onClick={this.declineOffer}>
                    Decline Offer
                  </Button>
                </Col>
              )}
            </Row>

            <h2
              style={{
                paddingLeft: "0px",
                marginTop: "60px",
                color: "#FF214F",
                fontSize: "30px"
              }}
            >
              OFFER SUMMARY DEFINITIONS
            </h2>
            {definations.map(({ term, defination, open }, index) => (
              <OfferDefinationCollapse
                key={index}
                term={term}
                defination={defination}
                open={open}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default OfferDetails;
