import React, { Component } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Row, Col } from "reactstrap";
import moment from "moment";
import OfferDefinationCollapse from "./OfferDefinationCollapse";
import { sendAcceptOfferEmail, sendRejectOfferEmail } from "./OfferService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class InstantOfferDetails extends Component {
  renderPropertyField = (name, value, hideLineBreak) => {
    return (
      <Row>
        {!hideLineBreak && <div className="line-break" />}
        <Col>
          <h3>{name}</h3>
        </Col>
        <Col>
          <h3 style={{ textAlign: "end" }}>{value}</h3>
        </Col>
      </Row>
    );
  };

  back = () => {
    this.props.history.push("/offers");
  };

  acceptOffer = async () => {
    const { offer } = this.props;
    const message = await sendAcceptOfferEmail({ offer });
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  counterOffer = () => {
    const { offer, history } = this.props;
    history.push(`/offers/update/${offer.id}`);
  };

  declineOffer = async () => {
    const { offer } = this.props;
    const message = await sendRejectOfferEmail({ offer });
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  render() {
    const { offer, user } = this.props;
    const { uid } = user;
    const {
      address,
      offerExpires,
      price,
      comments,
      closingDate,
      isSubjectToInspection,
      specialServices,
      serviceChargePercent,
      sender
    } = offer;
    const date = moment(closingDate).fromNow();
    const repairs =
      isSubjectToInspection === "Yes"
        ? "Subject to property inspection"
        : "Not Subject to property inspection";
    const serviceCharge = (serviceChargePercent/100) * price;
    const expires = moment(offerExpires);
    const tz = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    return (
      <Card style={{ margin: "10px" }}>
        <CardBody>
          <div style={{ marginLeft: "25px" }}>
            <CardTitle style={{ maxWidth: "200px", fontWeight: "500" }}>
              {address}
            </CardTitle>
            <p style={{ color: "grey" }}>
              This offer expires on{" "}
              {expires.format("dddd MMMM Do, YYYY [at] h:mm")} {tz} ({" "}
              {expires.fromNow()} )
            </p>
          </div>
          {this.renderPropertyField("Purchase Price", `$${price}`)}
          {this.renderPropertyField("Estimated Closing Date", date)}

          <div style={{ marginLeft: "25px", marginRight: "25px" }}>
            <OfferDefinationCollapse
              term="What determines closing date?"
              defination="When it comes to selecting a closing date you want to be as comfortable as possible. Closing date can vary based on your situation or need. If you are purchasing a new construction your closing date may beextended to accommodate the transaction the closing. Choosing you own closing just might be the control you need. You can even stay in your home after closing with extended stay option. Be sure to ask all the questions to make your process as comfortable as possible."
            />
          </div>

          <div style={{ marginTop: "10px" }} />

          {this.renderPropertyField(
            `Service Charge ${serviceChargePercent}%`,
            `-$${parseInt(serviceCharge)}`
          )}

          <div style={{ marginLeft: "25px", marginRight: "25px" }}>
            <OfferDefinationCollapse
              term="What are services charges?"
              defination="Instant Offer companies provides the convenience of purchasing and selling your home for you. Instant Offer services charge a fee to the seller for fees typically paid at closing including commission and any risk associated with the resell. They  may inspect the home, assess a general home repair allowance, andnegotiate any additional credits. Other coast includes holding over, maintenance fees, taxes and other costs to prepare the home for sale can be included."
            />
          </div>

          <div style={{ marginTop: "10px" }} />

          {this.renderPropertyField("Repairs needed to seller", repairs)}

          <div style={{ marginLeft: "25px", marginRight: "25px" }}>
            <OfferDefinationCollapse
              term="About Repairs"
              defination="Repairs will typically be ask for items that may materially affect the purchase or sell of the home. Itemssuch as the roof, HVAC, foundation, flooring, electrical, plumbing and appliance could be requested forrepair. If any repairs are required you will receive a copy of the inspection report and a list of repairs."
            />
          </div>

          <h3>Special Services Offered</h3>
          <p
            style={{
              marginLeft: "25px",
              backgroundColor: "#EFF0F1",
              padding: "15px",
              marginRight: "25px"
            }}
          >
            {specialServices}
          </p>
          <h3>
            Other Comments relevant to the Offer or steps required before
            entering into anagreement:
          </h3>
          <p
            style={{
              marginLeft: "25px",
              backgroundColor: "#EFF0F1",
              padding: "15px",
              marginRight: "25px"
            }}
          >
            {comments}
          </p>

          <Row>
            <Col>
              <h3>Estimated Net Offer</h3>
            </Col>
            <div className="line-break" />
            <Col>
              <h3 style={{ textAlign: "end", color: "#ff214f" }}>
                ${parseInt(price - serviceCharge)}
              </h3>
            </Col>
          </Row>

          <div style={{ marginLeft: "25px", marginRight: "25px" }}>
            <OfferDefinationCollapse
              term="Estimated Net Offer"
              defination="*Other Fees to the transaction may include title policy, escrow, estimated taxes, etc. Have questions, need clarification, or would like a seller net sheet for an itemized breakdown of fees click here."
            />
          </div>

          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px"
            }}
          >
            <h3
              style={{
                marginTop: "20px",
                backgroundColor: "#ff214f",
                color: "white"
              }}
            >
              Important!
            </h3>

            <p style={{ backgroundColor: "#EFF0F1", padding: "20px" }}>
              The Buyer will not be responsible for closing cost that are
              typically asessed by the seller in the state where theproperty is
              located. The Seller is responsibile for their own fees and should
              do their research before accepting.<br/><br/>
              You have the right to consult with an attorney to review transaction documents. Please contact an attorney for legal advise.
            </p>
            <p>Please review all information carefully before consideration. If you have any questions, please contact your agent or Homematchx Offer Advisor.</p>
          </div>

          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px"
            }}
          >
            <Row>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    backgroundColor: "#EFF0F1",
                    borderColor: "#EFF0F1",
                    color: "black"
                  }}
                  onClick={this.back}
                >
                  Back
                </Button>
              </Col>
              {(sender === uid) && (
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button className="btn-theme" onClick={this.counterOffer}>
                    Resubmit Offer
                  </Button>
                </Col>
              )}
              {(sender !== uid) && (
                <>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button className="btn-theme" onClick={this.acceptOffer}>
                      Accept Offer
                  </Button>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button className="btn-theme" onClick={this.declineOffer}>
                      Reject Offer
                </Button>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default InstantOfferDetails;
