import React, { Component } from 'react'

class Breadcrumb extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="breadcrumb-nav">
                    <ul>
                        <li className="active">{this.props.active}</li>
                        <li list="items">
                            {/* <Link to={this.props.link}>{this.props.title}</Link> */}
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default Breadcrumb
