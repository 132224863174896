import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableDef from "components/TableDef";
import stringcase from "stringcase";
import { buyerFields } from "pages/BuyerProfile";
import RealtorSelect from "./Common/RealorSelect";
import moment from "moment";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import BuyerSellerNotesModal from "./Buyer/BuyerSellerNotesModal";
import RequestReview from './Review/RequestReview';
import CreateBlogPost from "./Blog/CreateBlogPost";
import BlogPosts from "./Blog/BlogPosts";
import VerifyDocumentsModal from './Buyer/VerifyYourDocuments';



import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
const { titlecase } = stringcase;

class BuyerListItem extends Component {
  state = {
    error: "",
    modalVisible: false,
    focused: false,
    reason: "",
    otherReason: "",
    purchaseDate: moment(),
    agentEmail: "",
    agentLicence: "",
    agentName: "",
    agentPhone: "",
    builderName: "",
    otherBuilderName: "",
    noAgent: false
  };

  componentDidMount(){
    const {user} = this.props;
    if(user) this.setState({
      builderName: user.builder_name || '',
    });
    else this.setState({
      builderName: '',
    });
  }

  onChange = async ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value
    });
  };

  onAgentSelect = data => {
    const {
      realtor_license,
      realtor_email,
      realtor_name,
      realtor_phone
    } = data.value;

    this.setState({
      agentLicence: realtor_license || "",
      agentEmail: realtor_email || "",
      agentName: realtor_name || "",
      agentPhone: realtor_phone || "",
    });
  };

  toggleCheckbox = name => {
    const { onStateChange } = this.props;
    this.setState(
      {
        [name]: !this.state[name]
      },
      () => {
        if (onStateChange) {
          onStateChange(this.state);
        }
      }
    );
  };

  remove = () => {
    const { buyer, remove } = this.props;
    let {
      reason,
      otherReason,
      purchaseDate,
      agentEmail,
      agentLicence,
      agentName,
      agentPhone,
      noAgent,
      builderName,
      otherBuilderName
    } = this.state;

    if (reason === "") {
      return alert("Please select reason to remove");
    }

    if (!noAgent && agentLicence === "" && reason === "Purchased a Home") {
      return alert("Please Select Agent");
    }

    const data = {
      reason,
      details: otherReason,
      purchaseDate: purchaseDate.format("DD/MM/YYYY"),
      agentName,
      agentPhone,
      agentEmail,
      agentLicence,
      noAgent,
      builderName,
      otherBuilderName
    };

    remove(buyer.id, data);
    this.setState({ modalVisible: false });
  };

  toggle = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible
    }));
  };

  render() {
    const {
      buyer,
      data,
      user,
      showDetails
    } = this.props;


   const { isVerified  } = data || {}; 

    let role = '';
    if(!user) role = '';
    let photo = null;
    if (buyer.photo) {
      photo = <img src={buyer.photo} alt="buyer" className="img-fluid" />;
    } else {
      photo = (
        <img
          src="/images/face-placeholder.gif"
          alt="buyer"
          className="img-fluid"
        />
      );
    }

    return (
      <div key={buyer.id} className="row" style={{ padding: 20 }}>
        <div className="col-sm-3" style={{ marginBottom: 10 }}>
          {photo}
        </div>
        <div className="col-sm-9 title-container" style={{ marginBottom: 10 }}>
          <h2>
            {data.buyer_name || "(Buyer name)"}
            { (isVerified === "true") && <span className="verified-img" title = "Verified Funds" >
              <img
                src="/images/verified.png"
                alt="verified"
                className="img-fluid"
              />
              </span>}
          </h2>
          {data.draft ? (
            <p>
              <span className="badge badge-info">Draft</span>
            </p>
          ) : (
            <p>
              <span className="badge badge-success">Active</span>
            </p>
          )}
          {
            user && showDetails ? 
            <>
              <h5 className="d-none d-xl-block d-lg-block d-md-block">
                <i className="flaticon-phone" />
                &nbsp;
                {data.dontDisplayContactInfo
                  ? "Number Hidden"
                  : <a href={`tel:${data.buyer_phone}`}>{data.buyer_phone}</a> ||
                    "Not specified"}
              </h5>
              <h5 className="d-none d-xl-block d-lg-block d-md-block">
                <i className="flaticon-mail" />
                &nbsp;
                {data.dontDisplayContactInfo
                  ? "Email Hidden"
                  : <a href={`mailto:${data.buyer_email}`}>{data.buyer_email}</a> ||
                    "Not specified"}
              </h5>
              <br />
            </> : <></>
          }
          <h5>PREFERENCES</h5>
          <TableDef items={this.profileDetails(data)} />
          <div style={{ marginTop: 10 }} className="action">
            { showDetails && <Fragment>
              {(() => {
                const markup = [];
                if((data.purpose || []).includes('Partner with other buyers')){
                  markup.push((
                    <Link style={{marginRight:'5px'}} to={`/matches?buyerId=${buyer.id}&collection=buyerMatches`}>
                      <i className="fa fa-list" />
                      View buyer matches
                    </Link>
                  ));
                }

                if((data.purpose || []).includes('Purchase a home')){
                  markup.push((
                    <Link style={{marginRight:'5px'}} to={`/matches?buyerId=${buyer.id}`}>
                      <i className="fa fa-list" />
                      View Property matches
                    </Link>
                  ));
                }
                return markup;
              })()} 
            </Fragment> }
            &nbsp;&nbsp;
            <Link to={`/buyers/${buyer.id}`} target="_blank">
              <i className="fa fa-eye" />
              View on site
            </Link>
           { showDetails && <Fragment>
              {
                user ? <>
                  {role !== "builder" && (
                    <React.Fragment>
                      &nbsp;&nbsp;
                      <Link to={`/profiles/buyers/${buyer.id}`}>
                        <i className="fa fa-pencil" />
                        Edit
                      </Link>
                    </React.Fragment>
                  )}
                &nbsp;&nbsp;
                <BuyerSellerNotesModal {...this.props} resourceId={buyer.id} />
                </> : <></>
              }
              
              <VerifyDocumentsModal resourceId={buyer.id} name={data.buyer_name}/>
              
              <RequestReview resourceId={buyer.id} name={data.buyer_name} type="Buyer" color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Request Review
              </RequestReview>
              <CreateBlogPost {...this.props} resourceId={buyer.id} name={data.buyer_name} color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Create Message Board
              </CreateBlogPost>
              <BlogPosts {...this.props} resourceId={buyer.id} name={data.buyer_name} color='link' style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}>
                <i className="fa fa-star" /> Message Boards
              </BlogPosts>

              
              {this.renderRemoveBuyerModal(data)}
            </Fragment> }
          </div>
        </div>
      </div>
    );
  }

  renderPurchasedFields = () => {
    const {
      reason,
      purchaseDate,
      focused,
      agentEmail,
      agentLicence,
      agentName,
      agentPhone,
      builderName,
      otherBuilderName,
      noAgent
    } = this.state;

    if (
      reason === "Purchased a Home From" ||
      reason === "Purchased a Home" ||
      reason === "Purchased a Home From Another Seller"
    ) {
      return (
        <React.Fragment>
          {reason === "Purchased a Home From" && (
            <FormGroup>
              <Label for="builderName">Seller Name</Label>
              <Input
                type="text"
                name="builderName"
                id="builderName"
                value={builderName}
                onChange={this.onChange}
              />
            </FormGroup>
          )}

          {reason === "Purchased a Home From Another Seller" && (
            <FormGroup>
              <Label for="otherBuilderName">Other Seller Name</Label>
              <Input
                type="text"
                name="otherBuilderName"
                id="otherBuilderName"
                value={otherBuilderName}
                onChange={this.onChange}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label for="purchaseDate">Purchase Date</Label>
            <br />

            <SingleDatePicker
              date={purchaseDate}
              focused={focused}
              onDateChange={purchaseDate => this.setState({ purchaseDate })}
              onFocusChange={({ focused }) => this.setState({ focused })}
              id="purchaseDate"
              numberOfMonths={1}
              displayFormat="DD/MM/YYYY"
              isOutsideRange={() => false}
            />
          </FormGroup>
          {(reason !== "Purchased a Home From Another Seller" && (
            <React.Fragment>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={noAgent}
                    onChange={() => this.toggleCheckbox("noAgent")}
                  />{" "}
                  No Agent
                </Label>
              </FormGroup>

              {!noAgent && (
                <React.Fragment>
                  <FormGroup>
                    <Label for="purchaseDate">Select Agent</Label>
                    <RealtorSelect onChange={this.onAgentSelect} />
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col xs="12" sm="3">
                        <Label for="agentName">Agent Name</Label>
                        <Input
                          type="text"
                          name="agentName"
                          id="agentName"
                          value={agentName}
                          onChange={this.onChange}
                        ></Input>
                      </Col>

                      <Col xs="12" sm="3">
                        <Label for="agentEmail">Agent Licence</Label>
                        <Input
                          type="text"
                          name="agentLicence"
                          id="agentLicence"
                          value={agentLicence}
                          onChange={this.onChange}
                        ></Input>
                      </Col>
                      
                      <Col xs="12" sm="3">
                        <Label for="agentEmail">Agent Email</Label>
                        <Input
                          type="text"
                          name="agentEmail"
                          id="agentEmail"
                          value={agentEmail}
                          onChange={this.onChange}
                        ></Input>
                      </Col>

                      <Col xs="12" sm="3">
                        <Label for="agentPhone">Agent Phone</Label>
                        <Input
                          type="text"
                          name="agentPhone"
                          id="agentPhone"
                          value={agentPhone}
                          onChange={this.onChange}
                        ></Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }
  };

  renderOtherReasonTextBox = () => {
    const { reason, otherReason } = this.state;
    if (reason === "Other") {
      return (
        <FormGroup>
          <Label for="otherReason">Other Reason</Label>
          <Input
            type="textarea"
            name="otherReason"
            id="otherReason"
            value={otherReason}
            onChange={this.onChange}
          ></Input>
        </FormGroup>
      );
    }
  };

  updateShareOnPage = show_on_share => {
    let { showOnSharePage, buyer } = this.props;
    showOnSharePage(buyer.id, show_on_share);
  }

  renderRemoveBuyerModal = data => {
    const { user } = this.props;
    if(!user) return;
    const {role} = user;
    if (role === "lender") {
      return;
    }

    const { modalVisible, reason } = this.state;
    const { buyer_name, show_on_share } = data;

    return (
      <React.Fragment>
        <Button
          color="link"
          className="delete"
          style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
          onClick={this.toggle}
        >
          <i style={{ marginRight: 5 }} className='fa fa-remove' />
          Remove
        </Button>

        <Button
          color="link"
          title="Profile will show when tab is shared."
          style={{ textDecoration: "none", color: "#676767", fontSize: "13px" }}
          onClick={() => { this.updateShareOnPage(show_on_share); }}
        >
          <i style={{ marginRight: 5, color: `${(show_on_share) ? 'green' : 'red'}` }} className={`fa fa-${(show_on_share) ? 'check' : 'remove'}`} />
          Show Profile
        </Button>
        <br />
        <p><small><b>* Profile will show when tab is shared.</b></small></p>

        <Modal
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            Purchase a Home From {buyer_name || "(Buyer name)"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="reasonSelect">Reason</Label>
                <Input
                  type="select"
                  name="reason"
                  id="reasonSelect"
                  onChange={this.onChange}
                  value={reason}
                >
                  <option>Select Reason</option>
                  <option>Not Ready to Purchase</option>
                  {(role !== 'builder') && (
                    <>
                      <option>Purchased a Home From</option>
                      <option>Purchased a Home From Another Seller</option>
                    </>
                  )}
                  {(role === 'builder') && (
                    <>
                      <option>Purchased a Home</option>
                    </>
                  )}
                  <option>Other</option>
                </Input>
              </FormGroup>
              {this.renderOtherReasonTextBox()}
              {this.renderPurchasedFields()}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="danger" onClick={this.remove}>
              Remove
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  profileDetails(data) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    });

    let fields = [
      "cities",
      "purpose",
      "purchase_type",
      "financing_type",
      "property_type",
      "purchase_month",
      "purchase_year",
      "year_built",
      "subdivision",
      "zipcodes",
      "bedrooms",
      "bathrooms",
      "study",
      "school_district",
      "elementary_school",
      "middle_school",
      "high_school",
      "special_characteristics",
      "game_room",
      "media_room",
      "story",
      "pool",
      "fireplace",
      "patio",
      "selling_current_home",
      "leaseback_allowed",
      "leaseback_length",
      "interested_in_flip",
      "interested_in_buy_and_whole"
    ];
    
    const attrs = fields.map(f => {
      const field = buyerFields[f];

      let value = data[f];
      let foundValue;
      if (field && field.options && value) {
        for (const option of field.options) {
          if (typeof option === "object") {
            if (option.key === value) {
              foundValue = option.value;
              break;
            }
          }
        }
      }

      if (!foundValue) {
        foundValue = value;
      }

      if (foundValue && foundValue.constructor === Array) {
        foundValue = foundValue.join(", ");
      }

      return { key: titlecase(f), value: foundValue || "-" };
    });

    return [
      {
        key: "Price range",
        value:
          currencyFormatter.format(data.min_price) +
          "-" +
          currencyFormatter.format(data.max_price)
      }
    ].concat(attrs);
  }
}

export default BuyerListItem;
