import React, { useState } from "react";
import { Collapse, Button } from "reactstrap";

const OfferDefinationCollapse = ({ term, defination, open }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{marginTop:'10px'}}>
      <Button
        onClick={toggle}
        style={{
          backgroundColor: "#ff214f",
          color: "white",
          borderColor: "#FF214F",
          width: "100%",
          height: "50px",
          display: "flex"
        }}
      >
        {term}
      </Button>
      <Collapse isOpen={isOpen}>
        <p
          style={{
            backgroundColor: "#EFF0F1",
            padding: "20px"
          }}
        >
          {defination}
        </p>
      </Collapse>
    </div>
  );
};

export default OfferDefinationCollapse;
