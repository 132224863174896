import firebase from "firebase/app";
import 'firebase/analytics';
import "firebase/firestore";
import OfferEmailTemplate from "../../components/emailTemplates/OfferEmailTemplate";
import ReactDOMServer from "react-dom/server";
import {firebaseConfig} from './../Firebase/firebase';

let {logEvent} = firebase.analytics();

export const sendAcceptOfferEmail = options => {
  const { offer } = options;
  const { address, buyerName, id, buyerEmail,offerExpires } = offer;
  return new Promise((resolve, reject) => {
    try {
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

      logEvent('accept_offer_email', {
        offerId:id,
        buyerName,
        address,
        buyerEmail,
        offerExpires
      });

      const body = ReactDOMServer.renderToStaticMarkup(
        OfferEmailTemplate({
          heading: buyerName,
          elements: [
            {
              type: "p",
              text: `Congratulations, the seller has ACCEPTED the terms of your offer submission.`
            },
            {
              type: "buttons",
              buttons: [
                {
                  text: `Review Terms`,
                  link: `${firebaseConfig.host}/offers/update/${id}`
                }
              ]
            },
            {
              type: "p",
              text:
                "Offer acceptance is contingent upon receiving a fully written offer within 24 hours. The offer must be written on a TREC contract form and include all applicable addendum."
            },
            {
              type: "p",
              text:
                `The request expires on ${offerExpires}. If this request is not acknowledged within the specified time your offer is considered withdrawn and will have to be resubmitted.`
            },
            {
              type: "p",
              text:
                "Should you have any questions or would like to speak to a Homematchx Offer Team member about completing an offer, please contact me below. We're here to help!"
            }
          ]
        })
      );

      sendEmail({ subject: address, email: buyerEmail, body });
      resolve('Offer Accepted Succesfully.');
    } catch (error) {
        reject(error);
    }
  });
};


export const sendRejectOfferEmail = options => {
  const { offer } = options;
  const { address, buyerName, id, buyerEmail } = offer;

  logEvent('reject_offer_email', {
    offerId:id,
    buyerName,
    address,
    buyerEmail,
  });

  return new Promise((resolve, reject) => {
    try {
      const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

      const body = ReactDOMServer.renderToStaticMarkup(
        OfferEmailTemplate({
          heading: buyerName,
          elements: [
            {
              type: "p",
              text: `Thank you for submitting your offer. We regret to inform you that your offer was rejected.You are very welcomed to resubmit a stronger offer for seller consideration.`
            },
            {
              type: "buttons",
              buttons: [
                {
                  text: `Resubmit Offer`,
                  link: `${firebaseConfig.host}/offers/update/${id}`
                }
              ]
            },
            {
              type: "p",
              text:
                "Should you have any quesitons or would like to speak to a Homematchx Offer Team member, please contact me below. We're here to help!"
            }
          ]
        })
      );

      sendEmail({ subject: address, email: buyerEmail, body });
      resolve('Offer Rejected Succesfully.');
    } catch (error) {
        reject(error);
    }
  });
};
