exports.html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />

    <style>

    .match-details {
      border-spacing: 0.2em;
      border-collapse: separate;
    }
    .match-details th {
      border: 0;
      padding: 5px 10px 5px 0;
      text-align: center;
    }
    .match-details th {
      text-align: left;
    }
    .match-details td.def {
      font-weight: 600;
    }
    .match-details td {
      border: 0;
      padding-right: 30px;
    }
    
      a {
        text-decoration: none;
      }

      img {
        border-radius: 50%;
      }

      .email-container {
        margin: 10px;
        background-color:#ffffff;
        padding: 10px 40px 10px 40px;
      }

      .email-container p {
        font-size: 12px;
      }

      .devider {
        padding: 0px 10px;
      }
      
      .devider i {
        font-size: 26px;
        color: #535353;
      }

      .rect-auto, .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
        clip: rect(auto, auto, auto, auto);
      }

      .pie-fill, .c100.p51 .bar:after, .c100.p51 .fill, .c100.p52 .bar:after, .c100.p52 .fill, .c100.p53 .bar:after, .c100.p53 .fill, .c100.p54 .bar:after, .c100.p54 .fill, .c100.p55 .bar:after, .c100.p55 .fill, .c100.p56 .bar:after, .c100.p56 .fill, .c100.p57 .bar:after, .c100.p57 .fill, .c100.p58 .bar:after, .c100.p58 .fill, .c100.p59 .bar:after, .c100.p59 .fill, .c100.p60 .bar:after, .c100.p60 .fill, .c100.p61 .bar:after, .c100.p61 .fill, .c100.p62 .bar:after, .c100.p62 .fill, .c100.p63 .bar:after, .c100.p63 .fill, .c100.p64 .bar:after, .c100.p64 .fill, .c100.p65 .bar:after, .c100.p65 .fill, .c100.p66 .bar:after, .c100.p66 .fill, .c100.p67 .bar:after, .c100.p67 .fill, .c100.p68 .bar:after, .c100.p68 .fill, .c100.p69 .bar:after, .c100.p69 .fill, .c100.p70 .bar:after, .c100.p70 .fill, .c100.p71 .bar:after, .c100.p71 .fill, .c100.p72 .bar:after, .c100.p72 .fill, .c100.p73 .bar:after, .c100.p73 .fill, .c100.p74 .bar:after, .c100.p74 .fill, .c100.p75 .bar:after, .c100.p75 .fill, .c100.p76 .bar:after, .c100.p76 .fill, .c100.p77 .bar:after, .c100.p77 .fill, .c100.p78 .bar:after, .c100.p78 .fill, .c100.p79 .bar:after, .c100.p79 .fill, .c100.p80 .bar:after, .c100.p80 .fill, .c100.p81 .bar:after, .c100.p81 .fill, .c100.p82 .bar:after, .c100.p82 .fill, .c100.p83 .bar:after, .c100.p83 .fill, .c100.p84 .bar:after, .c100.p84 .fill, .c100.p85 .bar:after, .c100.p85 .fill, .c100.p86 .bar:after, .c100.p86 .fill, .c100.p87 .bar:after, .c100.p87 .fill, .c100.p88 .bar:after, .c100.p88 .fill, .c100.p89 .bar:after, .c100.p89 .fill, .c100.p90 .bar:after, .c100.p90 .fill, .c100.p91 .bar:after, .c100.p91 .fill, .c100.p92 .bar:after, .c100.p92 .fill, .c100.p93 .bar:after, .c100.p93 .fill, .c100.p94 .bar:after, .c100.p94 .fill, .c100.p95 .bar:after, .c100.p95 .fill, .c100.p96 .bar:after, .c100.p96 .fill, .c100.p97 .bar:after, .c100.p97 .fill, .c100.p98 .bar:after, .c100.p98 .fill, .c100.p99 .bar:after, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .c100 {
        position: relative;
        font-size: 120px;
        width: 1em;
        height: 1em;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        float: left;
        margin: 0 0.1em 0.1em 0;
        /* background-color: #cccccc; */
      }

      .c100 *, .c100 *:before, .c100 *:after {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
      }
      
      .c100.small {
        font-size: 80px;
      }

      .c100 > span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        color: #cccccc;
        display: block;
        text-align: center;
        white-space: nowrap;
        -webkit-transition-property: all;
        -moz-transition-property: all;
        -o-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: 0.2s;
        -moz-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        -moz-transition-timing-function: ease-out;
        -o-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      .c100:after {
        position: absolute;
        top: 0.08em;
        left: 0.08em;
        display: block;
        content: " ";
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        /* background-color: whitesmoke; */
        width: 0.84em;
        height: 0.84em;
        -webkit-transition-property: all;
        -moz-transition-property: all;
        -o-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: 0.2s;
        -moz-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-in;
        -moz-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }

      {{#classesArray}}

        .c100.p{{scoreInt}} .bar {
          -webkit-transform: rotate({{scorePercentage}}deg);
          -moz-transform: rotate({{scorePercentage}}deg);
          -ms-transform: rotate({{scorePercentage}}deg);
          -o-transform: rotate({{scorePercentage}}deg);
          transform: rotate({{scorePercentage}}deg);
        }

        .text-color{{scoreInt}} {
          color: {{color}} !important;
          font-weight: bold;
        }


        .c100.p{{scoreInt}} .fill {
          position: absolute;
          border: 0.08em solid {{color}};
          width: 0.84em;
          height: 0.84em;
          clip: rect(0em, 0.5em, 1em, 0em);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }

        .pie, .c100 .bar{{scoreInt}} {
          position: absolute;
          border: 0.08em solid {{color}};
          width: 0.84em;
          height: 0.84em;
          clip: rect(0em, 0.5em, 1em, 0em);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          -webkit-transform: rotate({{scorePercentage}}deg);
          -moz-transform: rotate({{scorePercentage}}deg);
          -ms-transform: rotate({{scorePercentage}}deg);
          -o-transform: rotate({{scorePercentage}}deg);
          transform: rotate({{scorePercentage}}deg);
        }


      {{/classesArray}}

      .c100 .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
      }

      .match-score span {
        display: block;
      }       

    </style>
    <title>Matches</title>

  </head>
  <body>
    <div class="email-container">
      <img src="{{url}}/img/logos/logo.png" class="logo" style="height: 40px;" />
      <h4>{{name}} here are your most recent matches on Homematchx: </h4>

      <div class="container">
        <div class="submit-address dashboard-list">
          <div class="pad-20">

          {{#matches}}

            <div class="comment">
              <div class="row">
                <div class="col-sm-12">
                  <table style="width:100%">
                    <tr>
                      <td style="width:50%">
                        
                        {{#buyerImage}}
                          <div style="margin: 0 auto;max-width: 300px;margin-bottom:0px;height:200px;background-size:cover;background-position:center center;background-image:url('{{buyerImage}}')"/>
                        {{/buyerImage}}

                        {{^buyerImage}}
                          <div style="margin: 0 auto;max-width: 300px;height:200px;background-color:#eee"></div>
                        {{/buyerImage}}

                      </td>

                      <td class="devider">
                        <img style="max-width: 50px;" src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/cdn%2Ficons%2Fcomp-cion.png?alt=media&token=b3ef0f4e-a084-485d-abe5-c7db6fadec12"></img>
                      </td>

                      <td style="width:50%">
                        {{#sellerImage}}
                          <div style="margin: 0 auto;max-width: 300px;margin-bottom:0px;height:200px;background-size:cover;background-position:center center;background-image:url('{{sellerImage}}')"/>                          
                        {{/sellerImage}}

                        {{^sellerImage}}
                          <div style="margin: 0 auto;max-width: 300px;height:200px;background-color:#eee"></div>
                        {{/sellerImage}}
                      </td>
                    </tr>
                  </table>
                  <div class="comment-meta">
                    <h5 style="font-size:15px">
                      Buyer
                      <a href="{{url}}/buyers/{{buyerId}}"
                        ><span style="color:red">{{buyerName}}</span></a
                      >
                      matched with
                      <a href="{{url}}/sellers/{{sellerId}}"
                        ><span style="color:red">{{sellerAddress}}</span></a
                      >
                    </h5>
                  </div>
                

                <table style="width:100%">
                  <tr>
                    <td style="width:100%">
                      <div style="padding-bottom:10px; width: 100%;" class="match-score">
                        <span style="font-size:13px; margin-bottom: 10px;">MATCH SCORE </span>
                        <div style="display: block; margin-bottom: 15px;">
                          <div class="c100 p{{scoreInt}} small">
                              <span class="text-color{{scoreInt}}">{{score}}</span>
                              <!-- <div class="slice">
                                  <div class="bar{{scoreInt}}"></div>
                                  <div class="fill"></div>
                              </div> -->
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                  

                <!-- <div style="padding-bottom:10px" class="match-score">
                    <span style="font-size:13px">MATCH SCORE </span>
                    <p>{{score}}</p>
                 </div> -->


                  <span style="font-size:13px; margin-top: 15px; display: block;">MATCH DETAILS</span>
                  <table style="font-size:13px; width: 50%;" class="match-details">
                    <thead>
                      <tr>
                        <th>Parameter</th>
                        <th>Requested</th>
                        <th>Actual</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                    {{#parameters}}
                        <tr>
                            <td class="def capitalize">{{name}}</td>
                            
                            {{#buyerValue}}
                                <td>{{buyerValue}}</td>
                            {{/buyerValue}}

                            {{^buyerValue}}
                                <td> - </td>
                            {{/buyerValue}}

                            {{#sellerValue}}
                                <td>{{sellerValue}}</td>
                            {{/sellerValue}}

                            {{^sellerValue}}
                                <td> - </td>
                            {{/sellerValue}}
                            
                            {{#isMatch}}
                                <td><img height="13" width="13" src="https://img.icons8.com/metro/26/000000/checkmark.png"></td>
                            {{/isMatch}}
                            {{^isMatch}}
                                <td><img height="13" width="13" src="https://img.icons8.com/ios-glyphs/26/000000/delete-sign.png"></td>
                            {{/isMatch}}
                        </tr>
                    {{/parameters}}

                    <a href="{{url}}/matches">See more</a>

                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
            </div>
            
            {{/matches}}

            {{^matches}}No matches :({{/matches}}
            
          </div>
        </div>
      </div>
      <p>
        By receiving this email and/or utilizing the contents. you agree to our
        <a href="{{url}}/terms_of_use"> Terms of Services.</a>
      </p>
    </div>
  </body>
</html>
`;