import React, { Component } from 'react'
import ListPosts from 'components/Blog/ListPosts'

class Blog extends Component {
    render() {
        return (
            <React.Fragment>
                <ListPosts {...this.props} />
            </React.Fragment>
        )
    }
}

export default Blog