import React, { Component } from "react";
import SoldHomesListItem from "./SoldHomesListItem";

class SoldHomesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ""
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="manage-table">{this.rows()}</div>
      </React.Fragment>
    );
  }

  rows() {
    const { sellers,user,remove } = this.props;

    if (sellers.length === 0) {
      return <div className="responsive-table">No records found</div>;
    }
    return sellers.map((seller,i) => (
      <SoldHomesListItem
        key={i}
        user={user}
        remove={remove}
        seller={seller}
        data={seller.data()}
      />
    ));
  }
}

export default SoldHomesList;
