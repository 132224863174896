import React from "react";
import { Route } from "react-router-dom";

export default function BetaRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Component {...props} user={rest.user} functions={rest.functions} />
        );
        // return (
        //   <Redirect
        //     to={{
        //       pathname: "/beta-authentication",
        //       state: { from: props.location }
        //     }}
        //   />
        // );
      }}
    />
  );
}
