import React, { Component } from "react";
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import firebase from "firebase/app";
import "firebase/firestore";

class SearchAutoComplete extends Component {
  state = {
    value: [],
  };

  onChange = (value) => {
    const { onChange } = this.props;
		this.setState({ value });
		const searches = (value || []).map((search) => search.value);
    onChange(searches);
  }

  onCreateOption = async (text) => {
		const {value} = this.state;
    this.onChange([...value,{ label: text, value: text }]);
  }

  get = async (value, callback) => {
    try {
      const db = firebase.firestore();
      const snap = await db.collection("tags").where("search", "array-contains", value.toLowerCase().replace(' ', '')).get();
      const results = snap.docs.map(d => {
        const data = d.data();
        return {
          label: data.text,
          value: data.text,
        };
      });
      callback(results);
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    const { value } = this.state;
    return (
      <AsyncCreatableSelect
				isMulti
        value={value}
        placeholder="Subdivision, HMXID, MLSID, Search"
        cacheOptions
        loadOptions={this.get}
        defaultOptions
        onChange={this.onChange}
        onCreateOption={this.onCreateOption}
        formatCreateLabel={value => value}
      />
    );
  }
}

export default SearchAutoComplete;
