import React, { Component } from "react";

export default class extends Component {
  render() {
    const { user } = this.props;
    const { full_name, profilePhoto, realtor_name,lender_name,builder_name } = user;
    const profileImage =
      profilePhoto["64"] ||
      profilePhoto["128"] ||
      profilePhoto["256"] ||
      profilePhoto["placeholder"];
    return (
      <div id="profile">
        <div className="wrap">
          <img id="profile-img" src={profileImage} className="online" alt="" />
          <p>{full_name || realtor_name || lender_name || builder_name}</p>
        </div>
      </div>
    );    
  }
}