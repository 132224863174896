import firebase from "firebase/app";
import "firebase/firestore";

export const getChatUsers = user => {
  const { role } = user;
  let promiss = null;
  switch (role) {
    case "realtor": {
      promiss = getChatUsersForAgent(user);
      break;
    }
    case "builder": {
      promiss = getChatUsersForBuilder(user);
      break;
    }
    case "lender": {
      promiss = getChatUsersForLender(user);
      break;
    }
    default:
      promiss = getChatUsersForBuyerSellers(user);
  }

  return new Promise(async (resolve) => {
    let users = await promiss;
    const {uid} = user;
    const db = firebase.firestore();
    const doc = await db.collection('whitelistConversations').doc(uid).get();
    if(doc.exists){
      const data = doc.data();
      const moreUsers = data.users.map(u => (
        {
          ...u,
          senderID: u.id,
          receiverID: uid,
          profile: "/images/face-placeholder.png",
          messages: []
        }
      ));
      users = [...users,...moreUsers]
    }

    resolve(users);
  })

};

const getChatUsersForBuilder = user  => {
  return new Promise(async (resolve, reject) => {
    try {
      
      let promises = [
        getSelfRepresentingBuyersSellers()
      ];

      const snapshots = await Promise.all(promises);
      let docs = {};
      snapshots.forEach(s => {
        s.forEach(d => {
          docs[d.id] = { ...d };
        });
      });
      docs = processUsersForChat(docs, user);
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
}

const getChatUsersForLender = user => {
  const db = firebase.firestore();

  return new Promise(async (resolve, reject) => {
    try {
      const { nmls_id } = user;

      let promises = [
        db
          .collection("buyers")
          .where("lender_nmls_id", "==", nmls_id || '0')
          .get(),
        db.collection("users").get(),
        db.collection("realtors").get()
      ];

      const snapshots = await Promise.all(promises);
      const emails = [];
      const representedAgents = [];
      snapshots[0].docs.forEach(d => {
        emails.push(d.data().buyer_email);
        representedAgents.push(d.data().represendtedBy);
      });

      let docs = snapshots[1].docs.map(d => {
        return {
          id: d.id,
          name: d.data().full_name,
          ...d.data(),
          role: "User"
        };
      });

      docs = docs.filter(d => {
        return emails.indexOf(d.user_email) !== -1;
      });

      docs = processUsersForChat(docs, user);
      let agents = snapshots[2].docs
        .map(d => {
          const data = d.data();
          return {
            id: d.id,
            name: data.realtor_name,
            email: data.realtor_email,
            role: "Realtor"
          };
        })
        .filter(d => representedAgents.indexOf(d.email) !== -1);

      agents = processUsersForChat(agents, user);

      resolve([...docs, ...agents]);
    } catch (error) {
      reject(error);
    }
  });
};

const getChatUsersForAgent = user => {
  
  return new Promise(async (resolve, reject) => {
    try {
      
      let promises = [
        getAgentRepresentedBuyersSellers(
          user.realtor_email,
          user
        ),
        getSelfRepresentingBuyersSellers(),
        getAgentsRepresentingBuyersSellers()
      ];

      const snapshots = await Promise.all(promises);
      let docs = {};
      snapshots.forEach(s => {
        s.forEach(d => {
          docs[d.id] = { ...d };
        });
      });
      docs = processUsersForChat(docs, user);
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

const getChatUsersForBuyerSellers = user => {
  return new Promise(async (resolve, reject) => {
    try {
      let promises = [getLenders(user)];
      const { agent_needed, agent_available, agent_emails } = user;
      const isSelfRepresenting =
        agent_needed === "No" && agent_available === "No";

      if (isSelfRepresenting) {
        promises.push(getSelfRepresentingBuyersSellers());
        promises.push(getAgentsRepresentingBuyersSellers());
        promises.push(getBuilders());
      } else {
        promises.push(getAgentsRepresentingBuyersSellers(agent_emails || []));
      }

      const snapshots = await Promise.all(promises);

      let docs = {};
      snapshots.forEach(s => {
        s.forEach(d => {
          docs[d.id] = { ...d };
        });
      });

      docs = processUsersForChat(docs, user);

      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

const processUserDocs = docs => {
  return docs.map(d => {
    const data = d.data();
    return {
      id: d.id,
      name: data.full_name,
      role: "User",
      email: data.user_email
    };
  });
};

const processUsersForChat = (docs, user) => {
  let data = Object.keys(docs).map(k => {
    const email = (docs[k].email || docs[k].lender_email || docs[k].user_email || docs[k].realtor_email || docs[k].builder_email || '');
    return {
      id: docs[k].id,
      senderID: docs[k].id,
      receiverID: user.uid,
      name: docs[k].name,
      role: docs[k].role,
      profile: "/images/face-placeholder.png",
      messages: [],
      email
    };
  });

  data = data.filter(d => {
    return d.senderID !== user.uid;
  });

  return data;
};

const getAgentsRepresentingBuyersSellers = agent_emails => {
  const db = firebase.firestore();

  return new Promise(async (resolve, reject) => {
    try {
      let promises = [db.collection("realtors").get()];

      if (!agent_emails) {
        promises = [
          db
            .collection("users")
            .where("role", "==", "user")
            .where("agent_needed", "==", "Yes")
            .get(),
          db
            .collection("users")
            .where("role", "==", "user")
            .where("agent_available", "==", "Yes")
            .get(),
          ...promises
        ];
      }

      const snapshots = await Promise.all(promises);
      let emails = [];

      let agentsSnapshotIndex = 2;

      if (!agent_emails) {
        [snapshots[0], snapshots[1]].forEach(s => {
          s.docs.forEach(d => {
            emails = [...emails, ...(d.data().agent_emails || [])];
          });
        });
        // remove duplicates
        emails = [...new Set(emails)];
      } else {
        emails = agent_emails;
        agentsSnapshotIndex = 0;
      }

      let docs = snapshots[agentsSnapshotIndex].docs;

      docs = docs
        .map(d => {
          const data = d.data();
          return {
            id: d.id,
            name: data.realtor_name,
            email: data.realtor_email,
            role: "Realtor"
          };
        })
        .filter(d => emails.indexOf(d.email) !== -1);

      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

const getLenders = user => {
  const db = firebase.firestore();
  return new Promise(async (resolve, reject) => {
    try {
      const { email } = user;

      const promises = [
        db
          .collection("buyers")
          .where("buyer_email", "==", email)
          .get(),
        db.collection("lenders").get()
      ];
      const snapshots = await Promise.all(promises);
      const ids = [];
      snapshots[0].docs.forEach(d => {
        ids.push(d.data().lender_nmls_id);
      });

      const docs = snapshots[1].docs
        .map(d => {
          return {
            id: d.id,
            name: d.data().lender_name,
            ...d.data(),
            role: "Lender"
          };
        })
        .filter(d => ids.indexOf(d.nmls_id) !== -1);

      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

const getAgentRepresentedBuyersSellers = (agent_email, user) => {
  const db = firebase.firestore();
  return new Promise(async (resolve, reject) => {
    try {
      const promises = [
        db
          .collection("users")
          .where("role", "==", "user")
          .where("agent_needed", "==", "Yes")
          .get(),
        db
          .collection("users")
          .where("role", "==", "user")
          .where("agent_available", "==", "Yes")
          .get(),
          db.collection('buyers').where('represendtedBy','==',agent_email || '').get(),
          db.collection('lenders').get(),
      ];


      const snapshots = await Promise.all(promises);
      let docs = {};

      [snapshots[0],snapshots[1]].forEach(s => {
        s.docs.forEach(d => {
          const u = {
            id: d.id,
            ...d.data(),
            role: "User"
          };

          const emails = u.agent_emails || [];
          if (emails.indexOf(agent_email) !== -1) {
            docs[u.id] = { ...u, name: u.full_name };
          }
        });
      });

      const nmls_ids = [];

      snapshots[2].docs.forEach(b => {
        const d = b.data();
        if(d.lender_nmls_id){
          nmls_ids.push(d.lender_nmls_id);
        }
      });

      let lenders = snapshots[3].docs.map(l => {
        return {
          id:l.id,
          name:l.data().lender_name,
          ...l.data(),
          role:'Lender',
        };
      }).filter(l => {
        return nmls_ids.indexOf(l.nmls_id) !== -1;
      });

      docs = processUsersForChat(docs, user);
      lenders = processUsersForChat(lenders,user);

      resolve([...docs,...lenders]);
    } catch (error) {
      reject(error);
    }
  });
};

const getBuilders = () => {
  const db = firebase.firestore();
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await db.collection("builders").get();
      const docs = snapshot.docs.map(d => {
        const data = d.data();
        return {
          id:d.id,
          name:data.builder_name,
          ...data,
          role:'Builder'
        }
      });
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

const getSelfRepresentingBuyersSellers = () => {
  const db = firebase.firestore();
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await db
        .collection("users")
        .where("role", "==", "user")
        .where("agent_needed", "==", "No")
        .where("agent_available", "==", "No")
        .get();

      const docs = processUserDocs(snapshot.docs);
      resolve(docs);
    } catch (error) {
      reject(error);
    }
  });
};

export default {
  getChatUsers
};
