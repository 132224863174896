import React, { Component } from "react";
import AsyncSelect from 'react-select/lib/Async';
import data from './cities.json';

class CitySelect extends Component {

    onChange = (m) => {
        let models = m;
        if (!Array.isArray(m)) {
            models = [m];
        }
        let { onChange } = this.props;
        models = models.map(d => d.value);
        onChange(models);
    };

    get = async (value, callback) => {
        let cities = [];

        // Promiss to make UI Responsive because large cities collection
        await new Promise((resolve,reject) => {
            data.body.cities.forEach(s => {
                if(s.name.toLowerCase().includes(value.toLowerCase())){
                    cities.push({ value: s.name, label: s.name });
                }
            });
            resolve(null);
        });

        callback(cities);
    };

    render() {
        const { cities } = this.props;
        let values = data.body.cities.filter(s => (cities || []).indexOf(s.name) !== -1);
        values = values.map(s => ({ value: s.name, label: s.name }));
        return (
            <AsyncSelect
                cacheOptions
                defaultOptions
                defaultValue={values}
                loadOptions={this.get}
                {...this.props}
                onChange={this.onChange}
            />
        );
    }
}

export default CitySelect;
