import React, { Component } from "react";
import { connect } from "react-redux";
import UserProfile from "./UserProfile";
import UserContact from "./UserContact";
import * as actions from "../../Store/Actions/type";

class ContactsSidePanel extends Component {
  state = {
    search: ""
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onConversationSelect = () => {
    this.setState({ search: "" });
  };

  toggleContactsViewVisibility = () => {
    const { dispatch } = this.props;
    dispatch({
      type: actions.TOGGLE_CONTACTS_VIEW_VISIBILITY
    });
  }

  render() {
    let { conversations, selectedConverstation, contactsViewVisibility } = this.props;
    const menuVisibility = (contactsViewVisibility) ? 'menuVisible':'menuHidden';
    let { search } = this.state;
    if (search) {
      search = search.toLowerCase();
      conversations = conversations.filter(c => {
        const name = c.name.toLowerCase();
        return search === name || name.includes(search);
      });
    }
    return (
      <div id="sidepanel" className={`col-md-3 col-xs-12 ${menuVisibility}`} >
        <UserProfile {...this.props} />
        <div id="search">
          <label htmlFor="">
            <i className="fa fa-search" aria-hidden="true"></i>
          </label>                                                
          <input
            name="search"
            type="text"
            placeholder="Search contacts..."
            value={search}
            onChange={this.onChange}
            autoComplete="off"
          />
        </div>
        <div id="contacts">
          <ul>
              {conversations.map((c, i) => {
                return (
                  <UserContact
                    user={this.props.user}
                    key={i}
                    conversation={c}
                    selectedConverstation={selectedConverstation}
                    onConversationSelect={this.onConversationSelect}
                  />
                );
              })}
          </ul>

          <button onClick={this.toggleContactsViewVisibility}><i className="fa fa-arrow-left"></i></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ MessengerReducer }) => {
  return {
    ...MessengerReducer
  };
};


const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ContactsSidePanel);
