
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';
const fallBackErrorMessage = 'Something went wrong, please try again later!';

const db = firebase.firestore();

const getBlogPostsById = options => async () => {
  const { resourceId, onSuccess, onError } = options;
  try {
    const snap = await db.collection('blogMessage').where("resourceId", "==", resourceId).get();
    const docs = (snap.docs || []).map(doc => {
      return {
        id: doc.id,
        ...(doc.data())
      }
    });
    if (onSuccess) {
      onSuccess(docs);
    }
  } catch (error) {
    const message = error.message || fallBackErrorMessage;
    if (onError) {
      onError(message);
    }
  }
};

const create = options => async () => {
  const { blogPost, onSuccess, onError } = options;
  try {
    await db.collection('blogMessage').add(blogPost)
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    console.log(error);
    const message = error.message || fallBackErrorMessage;
    if (onError) {
      onError(message);
    }
  }
};

const edit = options => async () => {
  const { blogPost, onSuccess, onError } = options;
  const { id} = blogPost;
  delete blogPost.id;
  try {
    await db.collection('blogMessage').doc(id).set(blogPost,{
      merge:true
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const message = error.message || fallBackErrorMessage;
    if (onError) {
      onError(message);
    }
  }
};

const deleteBlogPost = options => async () => {
  const { id, onSuccess, onError } = options;
  try {
    await db.collection('blogMessage').doc(id).delete();
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const message = error.message || fallBackErrorMessage;
    if (onError) {
      onError(message);
    }
  }
};

export { getBlogPostsById, create, edit, deleteBlogPost };