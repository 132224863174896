import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import ReactDOMServer from "react-dom/server";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { ModalBody, ModalHeader, Modal, Row, Col } from "reactstrap";

import FormField from "components/Common/FormField";
import PartnerAndSellerRequestEmail from "components/emailTemplates/PartnerAndSellerRequestEmail";

let { logEvent } = firebase.analytics();

class BePartnerModal extends Component {
  constructor(props) {
    super(props);

    this.initialValues = {
      name: "",
      agentName: "",
      license: "",
      stateOfLicense: "",
      brokerageName: "",
      brokerageAddress: "",
      phoneNumber: "",
      email: "",
      preferedContactMethod: "",
    };

    this.validation = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      agentName: Yup.string().required("Agent Name is required"),
      license: Yup.string().required("License is required"),
      stateOfLicense: Yup.string().required(
        "State Of license address is required"
      ),
      brokerageName: Yup.string().required("Brokerage Name is required"),
      brokerageAddress: Yup.string().required("Brokerage Address is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      preferedContactMethod: Yup.string().required(
        "Prefered Method is required"
      ),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
    });

    this.state = {
      isOpen: false,
      processing: false,
      successMessage: "",
    };
  }

  componentWillUpdate(newProps,newState) {
    const { isOpen } = newState;
    const { user } = this.props;
    console.log({isOpen});
    if (isOpen) {
      if (!user) {
        localStorage.setItem("redirect", "/realEstateServices");
        window.location.href = "/login";
      }
    }
  }

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state,
    });
  };

  sendEmailToAdmin = (data) => {
    logEvent("send_admin_partner_request_email");

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    const body = ReactDOMServer.renderToStaticMarkup(
      PartnerAndSellerRequestEmail({
        heading: `You got new partner request.`,
        request: data,
      })
    );
    sendEmail({
      subject: "Got Partner Request",
      email: "info@homematchx.com",
      body,
    });
  };

  render() {
    const { isOpen, processing } = this.state;
    return (
      <React.Fragment>
        <button onClick={this.toggle} className="search-button" type="button">
          Be a Realtor Partner
        </button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className="modal-danger modal-lg realtor-partner-modal"
        >
          <ModalHeader toggle={this.toggle}>
            <h2>Limited Real Estate Services</h2>
          </ModalHeader>
          <ModalBody>
            <div className="realtor-partner-heading">
              <h2>Real Estate your Way</h2>
              <p style={{ marginTop: "10px" }}>
                Never let your efforts go to waste again. Discover easy earning
                opportunities as a real estate professional.
              </p>
            </div>
            <div className="realtor-partner-form">
              <h3>Learn more about our Limited real estate Services</h3>

              <Formik
                initialValues={this.initialValues}
                validationSchema={this.validation}
                onSubmit={async (data) => {
                  this.setState({ processing: true });
                  try {
                    const db = firebase.firestore();
                    await db.collection("partnerRequests").add(data);
                    this.setState({ processing: false });
                    toast.success(
                      "Your Request has heen received. A Representative will contact you shortly."
                    );
                    this.toggle();
                    this.sendEmailToAdmin(data);
                  } catch (error) {
                    toast.error(error.message);
                    this.setState({ processing: false });
                  }
                }}
                render={({ errors, touched }) => {
                  console.log(errors);
                  return (
                    <Form className="w-100 mt-3">
                      <Row>
                        <Col md="6">
                          <FormField
                            showLabel
                            type="text"
                            name="name"
                            label="Name (First and Last)"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                        <Col md="6">
                          <FormField
                            showLabel
                            type="text"
                            name="agentName"
                            label="Agent or Broker"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="license"
                            label="License #"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="stateOfLicense"
                            label="State of License #"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="brokerageName"
                            label="Brokerage Name"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="brokerageAddress"
                            label="Brokerage Address"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="phoneNumber"
                            label="Phone Number"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="6">
                          <FormField
                            style={{ marginTop: "20px" }}
                            showLabel
                            type="text"
                            name="email"
                            label="Email Address"
                            errors={errors}
                            touched={touched}
                          />
                        </Col>

                        <Col md="12">
                          <label
                            style={{ marginTop: "20px" }}
                            htmlFor="preferedContactMethod"
                          >
                            Prefered Method of Contact
                          </label>

                          <Field
                            className={`form-control ${
                              errors.preferedContactMethod &&
                              touched.preferedContactMethod &&
                              "is-invalid"
                            }`}
                            as="select"
                            name="preferedContactMethod"
                          >
                            <option value="">Select</option>
                            <option value="Email">Email</option>
                            <option value="Phone">Phone</option>
                          </Field>

                          {errors.preferedContactMethod &&
                            touched.preferedContactMethod && (
                              <FormFeedback>
                                {errors.preferedContactMethod}
                              </FormFeedback>
                            )}
                        </Col>

                        <Col md={{ size: 4, offset: 3 }} className="text-right">
                          <input
                            style={{ marginTop: "20px" }}
                            className="search-button"
                            type="submit"
                            disabled={processing}
                          />
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BePartnerModal;
