import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
    Button,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal,
    FormGroup,
    Label,
    Alert,
} from "reactstrap";
import Select from "react-select";

import firebase from "firebase/app";
import "firebase/functions";
import CreateCardModal from "./../Payment/CreateCardModal";
import * as actions from "../../Store/Actions/type";

class AddLoveStreamAddon extends Component {
    initState = {
        processing: false,
        card: "",
        type: "addon",
        isOpen: false,
        error: ""
    };

    state = {
        ...this.initState
    };

    componentDidMount() {
        const { uid } = this.props.user;
        firebase
            .firestore()
            .collection("stripe_customers")
            .doc(`${uid}`)
            .collection("sources")
            .onSnapshot(
                ({ docs }) => {
                    const sources = docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        };
                    });
                    this.dispatchSources(sources);
                },
                () => {
                    this.dispatchSources([]);
                }
            );
    }

    dispatchSources = sources => {
        const { dispatch } = this.props;
        dispatch({
            type: actions.SET_SOURCES,
            payload: sources
        });
    };

    handleCardChange = card => {
        this.setState({ card });
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    subscribe = async () => {

        const { card, type } = this.state;

        if (!card) {
            return alert("Please Select Card");
        }

        const { user, payload } = this.props;
        const { uid, role } = user;

        const charge = {
            source: card.value,
            type,
            packageID: '',
            role,
            ...(payload || {})
        };

        this.setState({
            processing: true,
            error: '',
        });

        try {
            await firebase
                .firestore()
                .collection("stripe_customers")
                .doc(uid)
                .collection("charges")
                .add(charge);

            this.setState({
                ...this.initState
            });

            toast.success("Credit Added Successfully.", {
                position: toast.POSITION.TOP_RIGHT
            });

        } catch (error) {
            this.setState({
                processing: false,
                error: error.message
            });
        }
    };

    renderCards = () => {
        const { card } = this.state;
        const { sources, user } = this.props;
        if (sources.length === 0) {
            return (
                <React.Fragment>
                    <p>No Card found</p>
                    <CreateCardModal user={user} />
                </React.Fragment>
            );
        }
        const cards = sources.map(({ brand, last4, id }) => {
            return { value: id, label: `${brand}*************${last4}` };
        });

        return (
            <React.Fragment>
                <Label htmlFor="card">Select Card</Label>
                <Select
                    name="card"
                    value={card}
                    onChange={this.handleCardChange}
                    options={cards}
                />
            </React.Fragment>
        );
    };

    render() {
        const { isOpen, processing, error } = this.state;
        const { user } = this.props;

        if (!user.liveStreamPackageName || user.liveStreamPackageName === 'Basic') {
            return <></>;
        }

        return (
            <React.Fragment>
                <Button
                    block
                    color=""
                    onClick={this.toggle}
                    className="btn btn-block btn-success text-uppercase"
                >
                    Add Credits
        </Button>

                <Modal
                    size="lg"
                    isOpen={isOpen}
                    toggle={this.toggle}
                    className="modal-default"
                >
                    <ModalHeader toggle={this.toggle}>
                        {" "}
            Add Credits
          </ModalHeader>
                    <ModalBody>

                        <p>Need more credits to live stream? Add an additional 10 credit hours pack anytime for just $27!</p>
                        <FormGroup>{this.renderCards()}</FormGroup>
                        {error && <Alert color="danger">{error}</Alert>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.subscribe}>
                            {processing ? "Adding Credits..." : "Add Credits"}
                        </Button>{" "}
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
            </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ PaymentReducer }) => {
    return {
        ...PaymentReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddLoveStreamAddon);
