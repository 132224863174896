import React, { Component } from 'react'
import { Row, Col, Container } from "reactstrap";
import FAQ from '../components/FAQ';
import {Link} from 'react-router-dom';
import LocationModal from 'components/Location/LocationModal'

class Buyer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            location: null,
            bedrooms:'',
            bathrooms:'',
        }
    }

    componentWillMount() {
        window.scrollTo(0, 0)
    }

    onSearch = () => {
        const {location,bedrooms,bathrooms} = this.state;
        const {history } = this.props;
        localStorage.setItem('BuyerFilters',JSON.stringify({
            location,bedrooms,bathrooms
        }));
        history.push('/search/buyers');
    }

    onLocationChange = (location) => {
        this.setState({
            location,
        });
    }

    onChange = ({target:{name,value}}) => {
        this.setState({
            [name]:value,
        })
    }

    render() {
        const {location} = this.state;
        return (
            <React.Fragment>
                <div className="find-buyer-banner">
                    <div className="find-buyer-banner-img">
                        <img src="/images/inner-banner-bg.png" alt="" />
                    </div>
                    <div className="main-banner-text d-flex align-items-center">
                        <Container className="justify-content-end d-flex">
                            <div className="buyer-search-section">
                                <h2 className="heading">Find a buyer just like you</h2>
                                <p>Tells us a little about yourself</p>
                                <LocationModal
                                    addSearch
                                    location={location}
                                    placeholder="Location"
                                    centerAroundCurrentLocation 
                                    onLocationChange={this.onLocationChange}
                                    style={{ width: '93%', height: '320px', marginTop:'10px' }}
                                    modalStyle={{ width: '100%', height: '400px' }}
                                    className="form-control"
                                />
                                <Row>
                                    <Col lg="6" md="6">
                                        <select
                                            className="form-control custom-select"
                                            name="bedrooms"
                                            onChange={this.onChange}
                                        >
                                            <option value="">Bedrooms</option>
                                            <option value="1+">1+</option>
                                            <option value="2+">2+</option>
                                            <option value="3+">3+</option>
                                            <option value="4+">4+</option>
                                            <option value="5+">5+</option>
                                        </select>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <select
                                            className="form-control custom-select"
                                            onChange={this.onChange}
                                            name="bathrooms"
                                        >
                                            <option value="">Bathrooms</option>
                                            <option value="1+">1+</option>
                                            <option value="2+">2+</option>
                                            <option value="3+">3+</option>
                                            <option value="4+">4+</option>
                                            <option value="5+">5+</option>  
                                        </select>
                                    </Col>
                                </Row>
                                <button type="button" className="search-button" onClick={this.onSearch} >Get Started</button>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="find-buyer-text">
                    <Container>
                        <div className="find-buyer-text-inner">
                            <Row>
                                <Col lg="6" md="6">
                                    <div className="find-buyer-text-card">
                                        <h3>Why create a Buyer profile?</h3>
                                        <p>Your intent to purchase a home from the seller is more than texts on a contract, but an expression about your desire to own it. We’ve given you full control to be transparent by sharing exactly why it’s the perfect match through purpose videos, images, and a detailed criteria list.</p>
                                    </div>
                                </Col>
                                <Col lg="6" md="6">
                                    <div className="find-buyer-text-card">
                                        <h3>You can do more</h3>
                                        <p>To give you the best chance to find your one of a kind, we created a perfect match score category. Become a searchable buyer for active sellers and those preparing to sell. Find your compatible match to avoid any regrets on missing out.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="standout-section">
                            <h2 className="medium-heading text-center">Standout in 3 steps</h2>
                            <Row>
                                <Col lg="4">
                                    <div className="find-buyer-text-card">
                                        <h3>List your profile for free</h3>
                                        <p>Become a stronger buyer and take advantage of creating a visible presence online for current and future sellers with no cost to set it up.</p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="find-buyer-text-card">
                                        <h3>Decide how you want to be searched</h3>
                                        <p>Create your own search tags, price, location, and detailed specs and must haves. Point your primary and secondary location to any home, neighborhood, or community to be found.</p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="find-buyer-text-card">
                                        <h3>Welcome your first match</h3>
                                        <p>Once your profile is live, qualified sellers and agent listings rep can reach out. You can message them with any questions before purchasing.</p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="more-experience-section">
                                <div className="wrap-900">
                                    <Row className="align-items-center">
                                        <Col lg="6" md="6">
                                            <div className="more-experience-text">
                                                <i class="fa fa-quote-left"></i>
                                                <p>Homematchx is a great tool for helping me on a personal level with planning to purchase a new home. Such an innovative & creative tool that meets today's demand for convenience & simplicity of finding my dream home without the stress!</p>
                                                <span>Agnes is a buyer in Dallas, Texas with flexibility to plan a move</span>
                                                <Link to="/search/buyers" className="search-button button-white">Learn more ways to experience</Link>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6">
                                            <div className="more-experience-img">
                                                <img src="/images/more-experience-img.png" alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="strong-together-section">
                                <div className="wrap-900">
                                    <h2 className="medium-heading text-center">We’re strong together</h2>
                                    <Row>
                                        <Col lg="6" md="6">
                                            <div className="strong-together-text">
                                                <p>We know how challenging it can be to purchase a home. Homematchx allows you to understand what you are buying and provides an opportunity to speak with the seller before the sale. This part of the process helps up decrease any buyer’s remorse you could have.</p>
                                                <p>If questions do come up, we have a direct path of communication. Whether the agent representing the sale of the home or the seller making themselves available to chat, you’re supported as a buyer throughout.</p>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6">
                                            <div className="strong-together-list">
                                                <ul>
                                                    <li><i className="fa fa-check"/> Live stream link to chat face-to-face</li>
                                                    <li><i className="fa fa-check"/> Instant Message for a quick response</li>
                                                    <li><i className="fa fa-check"/> Chance to ask questions about the home to homeowners</li>
                                                    <li><i className="fa fa-check"/> Link your lender to strengthen buyer profile</li>
                                                    <li><i className="fa fa-check"/> Buyers Agent can represent buyer for comfort</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="strong-together-img">
                                        <img src="/images/strong-together-img.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="what-homematex-section">
                                <h2 className="medium-heading text-center">What is Homematchx?</h2>
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className="find-buyer-text-card">
                                            <h3>What is Homematchx?</h3>
                                            <p>Homematchx connects buyers and sellers together for a real estate transaction. We’re bringing relevance to the unknown individuals trying to sell or buy. The community is powered by those who are participating in a real estate sale.</p>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className="find-buyer-text-card">
                                            <h3>What is the community?</h3>
                                            <p>The community consists buyers, sellers, real estate agents, lenders, and home builders. You can build interest by sharing your profile with anyone in the world. When you present an offer make it standout by including your direct profile page. Go all-in on your next offer.</p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="more-experience-section">
                                    <div className="wrap-900">
                                        <Row className="align-items-center">
                                            <Col lg="6" md="6">
                                                <div className="more-experience-text">
                                                    <i className="fa fa-quote-left"/>
                                                    <p>Homematchx is a game changer for the real estate industry. For lenders, it provides an opportunity to be a resource to buyers in helping them prepare for their future home purchase.</p>
                                                    <span>Rod is a lender for buyers purchasing a home</span>
                                                    <Link to="/search/buyers" className="search-button button-white">Learn more ways to experience</Link>
                                                </div>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <div className="more-experience-img">
                                                    <img src="/images/more-experience-img2.png" alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ask-question-section">
                            <div className="wrap-900">
                                <h2 className="medium-heading text-center">Frequently ask questions</h2>
                                <Row>
                                    <Col lg="12" md="12">
                                        <FAQ term="Who can be a Homematchx buyer?" defination="It’s easy to become a Homematchx buyer in most areas, and it’s always free to create a profile. Whether you are considering purchasing now or in the future, Homematchx is the perfect place to add your buying details and share it with anyone."/>
                                        <FAQ term="What is required before making a profile active?">
                                            <p>We ask every buyer who creates a profile with Homematchx for a little bit of information about their home search before making their profile active. Buyers need to have this information completely filled out before they can start seeing match list.</p>
                                            <p>Homematchx requirements for buyers include:</p>
                                            <p style={{marginBottom: '0px'}}>Full Name</p>
                                            <p style={{marginBottom: '0px'}}>Email</p>
                                            <p style={{marginBottom: '0px'}}>If you have a real estate agent</p>
                                            <p>Minimal search criteria</p>
                                            <p>Buyers are expected, but not required, to have a profile picture. You can also hide your contact information.</p>
                                        </FAQ>
                                        <FAQ term="How much does it cost to add my profile?" list={[
                                            'Signing up for Homematchx and adding your buyer profile is completely free.',
                                            'Once you use live stream to chat in the community, we charge a fee for buyers to cover the cost of running the business.                                                           '
                                        ]}/>
                                    </Col>
                                    {/* <Col lg="6" md="6">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                    </Col> */}
                                </Row>                      
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Buyer;
