import React, { Component } from 'react';
import Select from 'react-select';
// import firebase from 'firebase/app'
// import 'firebase/functions'
// import 'firebase/analytics';

class MatchesFilterSelect extends Component {

    state = {
        options: [
            {label: 'Select filter', value: ''},
            {label: 'Price', value: 'Price'},
            {label: 'Purchase Date', value: 'Purchase Date'},
            {label: 'Style Of House', value: 'Style Of House'},
            {label: 'City', value: 'City'},
            {label: 'State', value: 'State'},
            {label: 'Property Type', value: 'Property Type'},
            {label: 'Zip Code', value: 'Zip Code'},
            {label: 'Bathrooms', value: 'Bathrooms'},
            {label: 'Bedrooms', value: 'Bedrooms'},
            {label: 'Year Built', value: 'Year Built'},
            {label: 'Stories', value: 'Stories'},
            {label: 'Sq Ft', value: 'Sq Ft'},
            {label: 'Acres', value: 'Acres'},
            {label: 'School District', value: 'School District'},
            {label: 'Elementary School', value: 'Elementary School'},
            {label: 'Middle School', value: 'Middle School'},
            {label: 'High School', value: 'High School'},
            {label: 'Game Room', value: 'Game Room'},
            {label: 'Media Room', value: 'Media Room'},
            {label: 'Pool', value: 'Pool'},
            {label: 'Leaseback', value: 'Leaseback'},
            {label: 'Front Door Faces', value: 'Front Door Faces'},
            {label: 'Garage Location', value: 'Garage Location'},
            {label: 'Date Flexible', value: 'Date Flexible'},
            {label: 'Price Flexible', value: 'Price Flexible'},
            {label: 'Subdivision', value: 'Subdivision'},
            {label: 'Tags', value: 'Tags'},
        ]
    }

    async componentDidMount(){
        // const db = firebase.firestore();
        // const snap = await db.collection('tags').get();
        // let tags = snap.docs.map((doc) => {
        //     const data = doc.data();
        //     return {
        //         label: data.text,
        //         value: 'tag',
        //     };
        // });
        const {options} = this.state;

        this.setState({
            options: [
                ...options,
                // ...tags,
            ]
        });
    }

    render() {
        const {options} = this.state;
        const {value, onFilterChange} = this.props;
        return (
            <Select
                name="filters"
                required
                value={value}
                isMulti={true}
                id="filters"
                onChange={onFilterChange}
                options={options}
            />
        )
    }
}

export default MatchesFilterSelect;