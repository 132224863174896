import React, { Component } from 'react';
import Select from "react-select";
import firebase from "firebase/app";
import "firebase/firestore";
import {toast} from 'react-toastify';

class HelpCenter extends Component {
    constructor(props) {
        super(props);

        const { full_name, realtor_name, lender_name, builder_name ,email, user_email, realtor_email, lender_email, builder_email} = this.props.user || {};
        const name = full_name || realtor_name || lender_name || builder_name || '';
        const userEmail =  email || user_email || realtor_email || lender_email || builder_email || '';


        this.state = {
            error: '',
            name: name,
            email: userEmail,
            status:{
                label: 'Pending',
                value: 'Pending'
            },
            category:'',
            subject: '',
            message: '',
            categories: [
                'Seller',
                'Lender',
                'Buyer',
                'Offers',
                'Video Stream',
                'Tours'
            ]
        }
    }

    handleSelectChange = (key, value) => {
        this.setState({ [key]: value });
    };

    onChange = ({target}) => {
        const {value,name} = target;
        this.setState({[name]: value});
    }

    submitTicket = async () => {
        
        const {name, email, category, status, subject, message} = this.state;

        const db = firebase.firestore();
        await db.collection("supportTickets").add({
            name,email,category,status,subject,message
        });

        this.setState({
            name: '',
            email: '',
            category:'',
            subject: '',
            message: '',
        });

        toast.success('Your Ticket submited Successfully.');

    }

    render() {
        const {name, email, categories, category, subject, message} = this.state;       
        return (
            <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 align-self-center">
                                <div className="contact-2 pb-hediin-60">
                                    <h5 className="clearfix">
                                        Always Support You
                                    </h5>
                                    <h3 className="heading">How can we help</h3>
                                    <div className="row" style={{marginBottom:'20px'}}>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group name">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Name"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <div className="form-group email">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={email}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                <Select
                                                    name="category"
                                                    value={category}
                                                    placeholder="Select Category"
                                                    onChange={(value) => this.handleSelectChange('category',value)}
                                                    options={categories.map(c => ({ value: c, label:c }))}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group subject">
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={subject}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group message">
                                                    <textarea
                                                        name="message"
                                                        placeholder="Write message"
                                                        className="form-control"
                                                        onChange={this.onChange}
                                                        value={message}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <div className="send-btn">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-md button-theme"
                                                        onClick={this.submitTicket}>
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default HelpCenter
