import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server';
import ToggleButton from 'react-toggle-button';
import InfiniteScroll from "react-infinite-scroller";
import { BrowserRouter as Router } from "react-router-dom";
import MatchUpdateSeller from 'components/MatchUpdateSeller';
import Mustache from 'mustache';
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/analytics';
import qs from 'query-string';

import { Pagination, PaginationItem, PaginationLink, Label, Input } from 'reactstrap';

import {html} from '../components/emailTemplates/MustacheTemplates/MatchesEmail';
import {firebaseConfig} from '../components/Firebase/firebase';
import SendMatchesToEmailModal from '../components/Matches/SendMatchesToEmailModal';
import MatchesFilterSelect from 'components/Matches/MatchesFilterSelect';


let {logEvent} = firebase.analytics();

class Matches extends Component {
    
    lastVisible = null;

    constructor(props) {
        super(props);
        
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const buyerId = query.buyerId;
        const sellerId = query.sellerId;
        const filter = query.filter;
        const collection = query.collection;

        const matchesTitle = collection  === 'buyerMatches' ? 'Buyer matches':'Property matches';

        this.state = {
            name:'',
            docs: [],
            loading: true,
            showLoading: true,
            view: '',
            emailState: 'default', 
            emailAddress: '',
            page:0,
            pageStarts:[],
            secondLastVisible:null,
            filter: filter || '',
            filters: filter ? [{ value:filter}] : [],
            matches: [],
            limit: 51,
            hasMore: true,
            selectedMatchesIds: [],
            actionValue:'',
            sendModalState: false,
            tags: [],
            includeExclude: true,
            collection: collection || 'matches',
            matchesTitle,
        }

        this.fetchData();

        const db = firebase.firestore();

        if(buyerId){
            db.collection('buyers').doc(buyerId).get().then(doc => {
                const buyer = doc.data();
                if (this.mounted) {
                    this.setState({title: 'Matches for buyer ' + buyer.buyer_name, view: 'buyer', name: buyer.buyer_name})
                }
            })
        }

        if(sellerId) {
            db.collection('sellers').doc(sellerId).get().then(doc => {
                const seller = doc.data()
                
                if (this.mounted) {
                    this.setState({title: 'Matches for seller ' + seller.seller_name, view: 'seller', name: seller.seller_name})
                }

            })
        }
    }

    onBuyerImageChange = (id, url, collection) => {
        const {matches} = this.state;
        this.setState({
            matches: (matches || []).map(match => {
                if(match.buyerId === id){
                    return {
                        ...match,
                        buyerImage:url,
                    }
                }
                
                if(match.buyerIdTwo === id){
                    return {
                        ...match,
                        buyerTwoImage:url,
                    }
                }

                return {
                    ...match,
                }
            })
        })
    }

    onChange = (event) => {
        const {name,value} = event.target;
        this.lastVisible = null;
        this.setState({
            [name]:value,
            docs:[],
            matches:[],
        },() => {
            this.fetchData();
        });
    }

    toggleModelState = () => {
        const {sendModalState} = this.state;
        this.setState({
            sendModalState: !sendModalState,
        });
    }

    onFilterChange = (filters) => {
        this.lastVisible = null;
        this.setState({
            filters,
            docs:[],
            matches:[],
        }, () => {
            this.fetchData();
        })
    }

    onActionChange = (event) => {
        const {value} = event.target;
        this.setState({
            actionValue: value,
        });

        if(value === 'send_matches_to_email'){
            this.setState({
                sendModalState: true,
            });
        }   

        if( value === 'not_interested'){
            this.notInterestedMatch();
        }
    }

    fetchData = () => {

        const {limit, collection} = this.state;

        const db = firebase.firestore();

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const buyerId = query.buyerId
        const sellerId = query.sellerId
        let keyValuePare = null;
        const {matches, filters, includeExclude} = this.state;

        const oldSize = matches.length;

        if (buyerId) {
            keyValuePare = {
                key:'buyerId',
                value:buyerId
            };
        }

        if (sellerId) {
            keyValuePare = {
                key:'sellerId',
                value:sellerId
            };
        }

        const realtorId = this.props.user.uid;
        let searchQuery = db.collection(collection).where("search", "array-contains", realtorId);

        if(filters && filters.length > 0){
            filters.forEach(filter => {
                const key = filter.value.toLowerCase().replace(' ', '');
                searchQuery = searchQuery.where(key,'==',includeExclude);
            });
        }

        if(keyValuePare) {
            searchQuery = searchQuery.where(keyValuePare.key,'==',keyValuePare.value);
        }
        
        searchQuery = searchQuery.orderBy('score', 'desc');

        if(this.lastVisible){
            searchQuery = searchQuery.startAfter(this.lastVisible);
        }

        searchQuery.limit(limit).get().then(snap => {

            let { pageStarts} = this.state;

            if(snap.docs.length > 0){
                pageStarts = [...pageStarts,snap.docs[0]];
                this.lastVisible = snap.docs[snap.docs.length - 1];
            }

            let {docs}  = snap;
            if (this.includeMatch) {
                docs = docs.filter(doc => this.includeMatch(this.props.user, doc))
            }

            let matchesData = docs.map(d => { 
                const match = {...d.data()}
                match.id = d.id;
                return match;
            }).sort((a, b) => b.score - a.score);

            const finalMatches = [...matches,...matchesData];

            const hasMore = finalMatches.length !== oldSize;

            if (this.mounted) {
                this.setState({
                    matches: finalMatches,
                    pageStarts,
                    loading: false,
                    hasMore
                });
            }

        }).catch(e => {
            console.log(e);
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    emailControl() {
        const {emailState, emailAddress} = this.state
        const emailButton = <button onClick={e => this.setState({emailState: 'opened'})} className="btn btn-danger btn-pink-color" title="Email these matches"><i className="fa fa-envelope"></i></button>

        if (emailState === 'default') {
            return emailButton
        }
        else if (emailState === 'opened') {
            return <React.Fragment>
                <input type="email" style={{padding: '4px 10px', borderRadius: 2, border: '1px solid #ccc', marginRight: 4, fontSize: 13}} placeholder="Email address" value={emailAddress} onChange={e => this.setState({emailAddress: e.target.value})} />
                <button style={{marginTop: -1}} className="btn btn-danger btn-pink-color" title="Send email" onClick={e => {
                    this.setState({emailState: 'sending'})

                    const sendMatchEmail = firebase.functions().httpsCallable('sendMatchEmail');
                    
                    logEvent('match_email', {
                        email:emailAddress
                    });

                    const body = ReactDOMServer.renderToStaticMarkup(this.matchEmail())
                    sendMatchEmail({email: emailAddress, body}).then(function(result) {
                        // Read result of the Cloud Function.
                    });

                    setTimeout(() => {
                        this.setState({emailState: 'sent'})
                    }, 500)
                }}><i className="fa fa-send"></i></button>
                <button style={{marginTop: -1}} className="btn btn-default" title="Cancel" onClick={e => this.setState({emailState: 'default'})}><i className="fa fa-remove"></i></button>
            </React.Fragment>
        }
        else if (emailState === 'sending') {
            return <React.Fragment>
                Sending email&nbsp;
                {emailButton}
            </React.Fragment>
        }
        else if (emailState === 'sent') {
            return <React.Fragment>
                    Email sent!&nbsp;
                    {emailButton}
                </React.Fragment>
        }
    }

    renderPaginationLinks = () => {
        return  (
            <Pagination style={{marginBottom:'50px'}} size="sm" aria-label="Page navigation example">
                <PaginationItem>
                    <PaginationLink onClick={this.previous}>Previous Page</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink onClick={this.next} title='Go to next page'>Next Page</PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    }

    addID = (id) => {
        const {selectedMatchesIds} = this.state;
        this.setState({
            selectedMatchesIds: [...selectedMatchesIds,id],
        });
    }

    removeID = (id) => {
        const {selectedMatchesIds} = this.state;
        this.setState({
            selectedMatchesIds: selectedMatchesIds.filter(d => id !== d),
        });
    }

    notInterestedMatch = async () => {
        const { user } = this.props;

        const {selectedMatchesIds, matches, collection} = this.state;
    
        const db = firebase.firestore();

        const promises = selectedMatchesIds.map(id => {
            return new Promise((resolve) => {
                const matchRef = db.collection(collection).doc(id);
                matchRef.get().then(snap => {
                    let {search, notIntrested} = snap.data();
                    search = search.filter((s) => s !== user.uid);
                    notIntrested = notIntrested || [];
                    notIntrested = notIntrested.filter((n) => n !== user.uid);
                    notIntrested = [...notIntrested, user.uid];
                    matchRef.set(
                        {
                          search,
                          notIntrested,
                        },
                        {
                          merge: true,
                        }
                    ).then(() => {
                        resolve({
                            id,
                            notIntrested
                        });
                    }).catch((error) => {
                        console.log(error);
                        resolve([]);
                    });
                }).catch((error) => {
                    console.log(error);
                    resolve([]);
                })
            });
        });

        const notIntresteds = await Promise.all(promises);

        const newMatches = matches.map((match) => {
            let notIntrested = match.notIntrested;
            notIntresteds.forEach(notInt => {
                if(notInt.id === match.id){
                    notIntrested = notInt.notIntrested;
                }
            });
            return {
                ...match,
                notIntrested,
            }
        });

        this.setState({
            matches: newMatches,
            actionValue: '',
        });

        // window.location = '/matches';
    }


    getColorValue = (score) => {
        if(score >= 7.5){
            return '#00B400';
        }

        if(score >= 5){
            return '#DCDC00';
        }

        return 'red';
    }

    sendMatchesEmailToUser = (email) => {
       const {matches, selectedMatchesIds, name} = this.state;

       const classData = {};
       
       let selectedMatches = matches
       .filter((match) => selectedMatchesIds.includes(match.id))
       .map((match) => {

        const scorePercentage = match.score * 36;
        const scoreInt = parseInt(match.score * 10);
        const color = this.getColorValue(parseFloat(match.score));

            classData[scoreInt] = {
                scoreInt,
                scorePercentage,
                color,
            }

           return {
            ...match,
            scoreInt,
            scorePercentage,
            color,
            score: parseFloat(match.score).toFixed(1),
            parameters: (match.parameters || []).map(param => {
                return {
                    ...param,
                    buyerValue: (param.buyerValue || '').toString(),
                }
            })
        };
       });

       if(!selectedMatches.length){
        alert('Please Select at least one match to send.');
        return this.setState({
            sendModalState: false,
            actionValue: '',
        });
       }

       const array = Object.keys(classData).map(key => classData[key]);
       
       const body = Mustache.render(html, {
        classesArray:array,
        name,
        url: firebaseConfig.host,
        matches: selectedMatches,
       });

        // return console.log(body);
                                                
        const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
        sendEmail({subject :`Your Homematchx matches for ${name}`, email ,body});
        console.log('Email Sent Successfully.');
        this.setState({
            sendModalState: false,
            actionValue: '',
        });
    }


    render() {
        const {matches, loading, showLoading, view, hasMore, allChecked, selectedMatchesIds, actionValue, sendModalState, collection, matchesTitle} = this.state;
        let matchesMarkup = null
         if(matches && matches.length){
            matchesMarkup = (
            <InfiniteScroll
                className="pad-20"
                pageStart={0}
                loadMore={this.fetchData}
                hasMore={hasMore}
                loader={<React.Fragment key={0} />}
                >
               {matches.map(d => <MatchUpdateSeller collection={collection} onBuyerImageChange={this.onBuyerImageChange} addID={this.addID} removeID={this.removeID} isChecked={selectedMatchesIds.includes(d.id)} view={view} functions={this.props.functions} user={this.props.user} match={d} key={d.id+1} />)}
            </InfiniteScroll>) 
        }
        else if (!loading) {
            matchesMarkup = <div className="pad-20"><p>No matches to show</p></div>;
        }
        else if(showLoading) {
            matchesMarkup = <div className="pad-20"><p>Loading...</p></div>;
        }

        return (
            <div className="content-area5 dashboard-content">
                 
                <div className="dashboard-header clearfix">
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h4>{this.state.title || this.title || matchesTitle || 'Matches'}</h4>
                        </div>
                        {/* <div className="col-sm-12 col-md-6" style={{textAlign: 'right'}}>
                            {this.emailControl()}
                        </div> */}
                        <div className="col-sm-12 col-md-4">
                            <div style={{float:'right', width:300}}>
                            <span style={{color:'#ff214f'}}>Filter By</span>
                                <MatchesFilterSelect
                                    value={this.state.filters}
                                    onFilterChange={this.onFilterChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2">
                            <span style={{color:'#ff214f'}}>Exclude/Include filter</span>
                            <div style={{marginTop:'10px'}}>
                                <ToggleButton
                                    
                                    inactiveLabel={<i className="fa fa-times"/>}
                                    activeLabel={<i className="fa fa-check"/>}
                                    value={this.state.includeExclude}
                                    onToggle={(includeExclude) => {
                                        this.lastVisible = null;
                                        this.setState({
                                            docs:[],
                                            matches:[],
                                            includeExclude:!includeExclude
                                        }, () => {
                                            this.fetchData();
                                        });
                                    }}
                                />
                            </div>
                            
                        </div>
                    </div>

                </div>
                
                <div className="row">
                 <div className="col-md-6 offset-md-3">
                    <div className="ui-item bg-active">
                                <div className="left">
                                    <h4>{matches.length > 100 ? '100+': matches.length }</h4>
                                <p>{matchesTitle}</p>
                                </div>
                                <div className="right">
                                    <i className="fa fa-comments-o" />
                                </div>
                    </div>
                    </div>
                 </div>

                <div className="row" style={{marginLeft: "15px",marginBottom: "20px"}}>
                        <div className="col-sm-12 col-md-6">
                        <Label check >
                                <Input checked={allChecked} style={{transform: 'scale(1.5)'}} type="checkbox" id="checkbox2" onChange={({target:{checked}})  => {
                                    if(checked){
                                        const selectedMatchesIds = (matches || []).map(match => match.id);
                                        return this.setState({
                                            selectedMatchesIds,
                                        }, this.printSelectedMatchesId);
                                    }
                                    this.setState({
                                        selectedMatchesIds:[],
                                    }, this.printSelectedMatchesId)
                                }} /> Select
                        </Label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                        <div style={{float:'right'}}>

                            <span style={{color:'#ff214f'}}>Actions</span>

                            <select
                                name="filter"
                                className="form-control custom-select"
                                required
                                onChange={this.onActionChange}
                                value={actionValue}
                            >
                                <option value="">Select Action</option>
                                <option value="not_interested">Mark as not interested</option>
                                <option value="send_matches_to_email">Send Matches to Email</option>
                            </select>

                        </div>
                    </div>
                </div>

                <div className="submit-address dashboard-list">
                    {matchesMarkup}
                </div>

                <SendMatchesToEmailModal sendModalState={sendModalState} toggleModelState={this.toggleModelState} sendEmailFunction={this.sendMatchesEmailToUser}   />
            </div>
        )
    }

    matchEmail() {
        const {matches} = this.state
        
        let matchesMarkup = null
        if (matches && matches.length) {
            matchesMarkup = matches.map(d => {
                return <MatchUpdateSeller view="email" functions={this.props.functions} user={this.props.user} match={d} key={d.id} />
            })
        }

        return <html>
            <head>
                <style>
                    {`
                    body {
                        font-family: Helvetica, Arial;
                    }`}
                </style>
            </head>
            <body>
                <div className="container">
                    <div className="submit-address dashboard-list">
                        <div className="pad-20">
                            <Router>
                                <React.Fragment>{matchesMarkup}</React.Fragment>
                            </Router>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    }
}

export default Matches
